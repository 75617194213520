<template>
  <div>
    <div class="email_certifi_modal alert">
      <div class="email_certifi_box alert_box">
        <h3>이메일로 전송된 인증번호 4자리를 입력해주세요.</h3>
        <div class="card_popup_btn alert_close_btn">
          <p id="color_txt" @click="$emit('closeFindPassModal')">확인</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * * 비밀번호 찾기 팝업
 * * 인증 요청 클릭 시 노출되는 팝업
 */

export default {
  namd: 'findPassModal',
}
</script>