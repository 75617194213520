<template>
  <div class="card_change_popup card_popup1 alert">
    <div class="add_space alert_box">
      <h3 style="margin-bottom: 0px; text-align: center; padding-bottom: 5px">
        로그인이 필요한 서비스입니다.
      </h3>
      <h3 style="margin-bottom: 0px; text-align: center; padding-bottom: 32px">
        로그인 페이지로 이동하시겠습니까?
      </h3>
      <div class="card_popup_btn">
        <p style="margin-bottom: 0px" @click="$emit('closeModal')">아니오</p>
        <p id="color_txt" style="margin-bottom: 0px" @click="linkLogin()">네</p>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * * 랜딩 페이지 1:1문의 팝업
 */

import { defineComponent } from "@vue/composition-api";
import router from "@/router";

export default defineComponent({
  name: "LinkLogin",

  methods: {
    /**
     * * 로그인 페이지로 이동
     */
    linkLogin () {
      router.push({ name: "brand/login" });
    }
  }
});
</script>
<style scoped>
.add_space { box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.8); }
.add_space h3 { color: rgba(255, 255, 255, 0.87); font-size: 18px; font-weight: 300; }
.card_popup_btn { width: 100%; height: 60px; border-top: 1px solid rgba(255, 255, 255, 0.08); display: flex; justify-content: center; align-items: center; }
.card_popup_btn p { color: #fff; font-size: 18px; line-height: 60px; font-weight: 500; text-align: center; cursor: pointer; }
.card_popup_btn p#color_txt { color: #ff2d55; }
.card_popup1 .card_popup_btn p { width: 50%; }
.card_popup2 .card_popup_btn p { width: 100%; }
</style>
