<template>
    <div class="wrap">
        <Header />
        <router-view></router-view>
        <noticemodal-component />
    </div>
</template>
<script>
import Header from '@/layout/header/Header.vue'
import NoticeModalComponent from '@/components/notice/NoticeModalComponent.vue';
export default {
    name: 'layout',
    components: {
        Header,
        'noticemodal-component': NoticeModalComponent
    }
}
</script>

<style scoped></style>