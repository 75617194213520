<template>
  <label class="container">
    <input
      v-bind="$attrs"
      class="input"
      type="checkbox"
      :checked="checked"
      @change="$emit('update:checked', $event.target.checked)"
    />
    <span class="switch"></span>
  </label>
</template>
<script>
  export default {
    name: "switch",
    inheritAttrs: false,
    props: {
      checked: {
        type: Boolean,
        required: true,
      }
    }
  }
</script>

<style scoped>
    .container {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    /* Visually hide the checkbox input */
    .input {
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border-width: 0;
    }

    .switch {
        /* 스위치의 총 길이 */
        --switch-container-width: 50px;
        /* 스위치 버튼 가로 사이즈 */
        --switch-size: calc(var(--switch-container-width) / 2);
        /* Vertically center the inner circle */
        display: flex;
        align-items: center;
        position: relative;
        /* 스위치 버튼 세로 사이즈 */
        height: var(--switch-size);
        flex-basis: var(--switch-container-width);
        /* Make the container element rounded */
        border-radius: var(--switch-size);
        background-color: #e2e8f0;
    }
    .switch::before {
        content: "";
        position: absolute;
        /* Move a little bit the inner circle to the right */
        /* left: 1px; */
        height: calc(var(--switch-size) - 4px);
        width: calc(var(--switch-size) - 4px);
        /* Make the inner circle fully rounded */
        border-radius: 9999px;
        background-color: white;
    }

    .input:checked + .switch {
        /* Teal background */
        background-color: #ff2d55;
    }
    .input:checked + .switch::before {
        border-color: #4fd1c5;
        /* Move the inner circle to the right */
        transform: translateX(
            calc(var(--switch-container-width) - var(--switch-size))
        );
    }
    .switch {
        /* ... */
        transition: background-color 0.25s ease-in-out;
        }
    .switch::before {
        /* ... */
        transition: transform 0.375s ease-in-out;
    }

    .switch {
        /* ... */
        --light-gray: #3b3b3b;
        --gray: #cbd5e0;
        --dark-gray: #606060;
        --teal: #ff2d55;
        --dark-teal: rgba(255, 45, 85, 0.6);
        /* ... */
        background-color: var(--light-gray);
    }
    .input:checked + .switch {
        background-color: var(--dark-teal);
    }
    .input:checked + .switch::before {
        border-color: var(--teal);
        background-color: var(--teal);
    /* ... */
    }

    .switch::before {
        /* ... */
        border: 2px solid var(--dark-gray);
        background-color: var(--dark-gray);
    }
    .input:focus + .switch::before {
        border-color: var(--dark-gray);
    }
    .input:focus:checked + .switch::before {
        border-color: var(--dark-teal);
    }
    .input:disabled + .switch {
        background-color: var(--gray);
    }
    .input:disabled + .switch::before {
        background-color: var(--dark-gray);
        border-color: var(--dark-gray);
    }
    .switch {
        /* ... */
        /* In case the label gets long, the toggle shouldn't shrink. */
        flex-shrink: 0;
    }
</style>