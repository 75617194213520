<template>
  <div>
    <section class="main cs">
      <div class="main_box">
        <h1>고객센터</h1>
        <div class="cs_main">
          <div class="side_depth_box">
            <div class="one_depth">
              <router-link class="one_depth_btn" :class="{ on : this.$route.path === '/brand/cs/faq'}" :to="{ name: 'brand/FAQ' }">FAQ</router-link>
              <router-link class="one_depth_btn" :class="{ on : this.$route.path === '/brand/cs/question'}" :to="{ name: 'brand/Question' }">1:1문의</router-link>
            </div>
          </div>
          <router-view></router-view>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'brand_cs'
}
</script>

<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/content.css"></style>