<template>
  <div>
    <div class="group_store_alert alert">
      <div class="group_store_box alert_box">
        <h3>삭제하시겠습니까?</h3>
        <div class="group_store_btn">
          <p @click="$emit('closeDeleteQUestion')">아니오</p>
          <p id="color_txt" @click="$emit('delteQuestion')">네</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'delteQuestion'
}
</script>

<style>

</style>