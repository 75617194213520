<template>
  <div>
    <RequestInfo v-if="requestStatus" @clostInfoModal="requestStatus=false" :spaceInfo="spaceInfo"/>
    <AcceptRequest v-if="acceptStatus" @closeAcceptModal="acceptStatus=false" :spaceInfo="spaceInfo" @getList="getRequestList"/>
    <RefuseRequest v-if="refuseStatus" @closeRefuseModal="refuseStatus=false" :spaceInfo="spaceInfo" @getList="getRequestList" />
    <section class="main">
      <div class="group_store">
        <div class="group_store_title">
          <h2>연결 요청 매장</h2>
          <div class="select-box-area" v-if="brandList.length > 1">
            <div class="label select-box" @mouseover.once="clickSelectBox">
              {{ brandName }}
            </div>
            <div class="select-box-dropDown">
              <ul>
                <li class="optionItem" v-for="item, i in brandList" :key="i" @click="getRequestList(item.brandId)">
                  <p v-html="item.brandNm" />
                </li>
              </ul>
            </div>
          </div>
          <div class="brand_list_box" v-else>
            {{ brandName }}
          </div>
        </div>
        <div class="group_tab">
          <div>
            <p>현재 <span style="cursor: default;">{{ itemList.length }}개</span> 매장이 입점 승인을 기다리고 있습니다.</p>
          </div>
          <div>
            <input type="text" placeholder="매장명을 입력하세요." v-model="search" @keyup.enter="requestFilter">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z" fill="white"/>
            </svg>
          </div>
        </div>
        <div class="request_store" v-if="listData.length > 0">
          <div v-for="(item, i) in listData" :key="i">
            <img :src="baseUrl + item.brandImg" alt="" @click="infoModal(item.spaceId)">
            <p style="cursor:pointer;" @click="infoModal(item.spaceId)">{{ item.spaceNm }}</p>
            <div>
              <button @click="refuseModal(item.spaceId, item.spaceNm)">거절</button>
              <button @click="acceptModal(item.spaceId, item.spaceNm)">수락</button>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="empty_area">
            <div>
              <img src="/media/img/empty_brand_icon.svg" alt="" />
              <p>관리중인 매장이 존재하지 않습니다.</p>
            </div>
          </div>
        </div>
        <Pagination v-if="listData.length > 0"
          :pageSetting="pageDataSetting(total, limit, block, this.page)"
          @paging="pagingMethod"
        />
      </div>
    </section>
  </div>
</template>

<script>
import AcceptRequest from "@/components/modal/RequestManagement/AcceptRequest.vue";
import RefuseRequest from "@/components/modal/RequestManagement/RefuseRequest.vue";
import RequestInfo from "@/components/modal/RequestManagement/RequestInfo.vue";
import Pagination from "@/components/modal/pagination/Pagination.vue";
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  components: {
    RequestInfo,
    AcceptRequest,
    RefuseRequest,
    Pagination
  },

  data(){
    return{
      requestStatus: false,
      acceptStatus: false,
      refuseStatus: false,
      // spaceId: '',
      itemList: [],
      itemListData: [],
      spaceInfo: {
        spaceId: '',
        spaceNm: '',
      },
      search: '',
      brandName: '',
      brandList: [],

      //pagination
      listData: [],
      total: 1,
      page: 1,
      limit: 10,
      block: 10
    }
  },

  mounted () {
    this.getRequestList();
    this.getBrandName();
    this.getBrandList();
  },

  methods:{
    /**
     * * 연결 요청 매장 목록 불러오기
     */
    getRequestList (id) {
      if (id != null) {
        // * 브랜드를 변경할 경우
        VueCookies.set('brandId', id);
        // * 슬라이드 번호 넣기
        const index = this.brandList.findIndex((el) => el.brandId === id);
        let slideNum = 0;
        index === this.brandList.length - 1 ? slideNum = 0 : slideNum = index + 1;
        VueCookies.set('slideNum', slideNum);
      }

      const brandId = VueCookies.get('brandId');
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/space/selectRequestList', { brandId }, { headers })
        .then(res => {
          const list = res.data.result;
          const url = document.location.href;
          const imgPath = url.indexOf('local') !== -1 || url.indexOf('dev') !== -1 ? 'https://dev.www.aplayz.co.kr/media/upload' : 'https://www.aplayz.co.kr/media/upload';

          for (var i in this.list) {
            if (this.list[i].brandImg != null) {
              this.list[i].brandImg = imgPath + this.list[i].brandImg.substring(12);
            }
          }

          this.itemList = list;
          this.itemListData = list;
          this.listData = this.itemList;
        })
        .catch(err => {
          console.log(err);
        });

      this.pagingMethod(this.page);
    },

    /**
     * * 매장명 검색
     */
    requestFilter () {
      this.itemList = this.itemListData.filter((space) => {
        return space.spaceNm.includes(this.search)
      });
      this.pagingMethod(this.page);
    },

    /**
     * * 브랜드 목록 불러오기
     */
    getBrandList () {
      const corpId = VueCookies.get("corpId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/brand/getBrandList', { corpId }, { headers })
        .then(res => {
          this.brandList = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * * 현재 브랜드명 불러오기
     */
    getBrandName () {
      const brandId = VueCookies.get('brandId');
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      //매장 카운트 / 음악 리스트 selelct
      axios
        .post('/api/brand/getBrandInfo', { brandId }, { headers })
        .then(res => {
          this.brandName = res.data.result.brandNm;
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * * 스페이스 상세 팝업 열기
     */
    infoModal (id) {
      this.spaceInfo.spaceId = id;
      this.requestStatus = true;
    },

    /**
     * * 요청 수락 팝업 열기
     */
    acceptModal (id, name) {
      this.spaceInfo.spaceId = id;
      this.spaceInfo.spaceNm = name;
      this.acceptStatus = true;
    },

    /**
     * * 요청 거절 팝업 열기
     */
    refuseModal (id, name) {
      this.spaceInfo.spaceId = id;
      this.spaceInfo.spaceNm = name;
      this.refuseStatus = true;
    },

    /**
     * * 페이징
     */
    pagingMethod (page) {
      this.listData = this.itemList.slice(
        (page - 1) * this.limit,
        page * this.limit
      )
      this.page = page;
      this.pageDataSetting(this.total, this.limit, this.block, page);
    },

    pageDataSetting(total, limit, block, page) {
      var totalPage = Math.ceil(total / limit);
      var currentPage = page;
      var first = currentPage > 1 ? parseInt(currentPage, 10) - parseInt(1, 10) : null;
      var end = totalPage !== currentPage ? parseInt(currentPage, 10) + parseInt(1, 10) : null
      var startIndex = (Math.ceil(currentPage / block) - 1) * block + 1
      var endIndex = startIndex + block > totalPage ? totalPage : startIndex + block - 1
      var list = []

      for (var index = startIndex; index <= endIndex; index++) {
        list.push(index)
      }

      return {
        first, end, list, currentPage
      }
    },

    /**
     * * 셀렉트 박스 클릭 이벤트
     */
    clickSelectBox () {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.brandName = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
}
</script>

<style scoped>
.group_store { overflow: hidden; }
.request_store { flex-wrap : wrap; justify-content:flex-start; }
.request_store > div { margin-right: 1.1em; margin-bottom: 1em; }
.label.select-box { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.empty_area { display: flex; flex-direction: column; align-items: center; float: inherit !important; padding: 120px 0; border-bottom: none !important; }
</style>
<style src="@/assets/css/requestManagement.css"></style>
<style src="@/assets/css/content.css"></style>
<style src="@/assets/css/font.css"></style>