<template>
  <div class="time_picker_setting">
    <div class="time_setting_box">
      <div class="label3 setting_box_header" @click="openTimeBox">{{ selectedTime }}</div>
      <div class="setting_box_drop_down">
        <div class="optionList">
          <TimePicker v-model="selectedTime" type="time" minute-interval="5" />
        </div>
        <div class="accept_btn" @click="setTime">
          <p>확인</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'timePicker',

  props: {
    selectTime: String,
  },

  data() {
    return {
      selectedTime: '09:00',
    };
  },

  created() {
    this.selectedTime = this.selectTime;
  },

  methods: {
    openTimeBox() {
      const $this = this;
      const parentClass = document.getElementsByClassName('label3')[0].parentNode.classList;
      const list = Array.from(parentClass);
      if (list.indexOf('active') === -1) {
        parentClass.add('active');
      } else {
        parentClass.remove('active');
      }
      /*select_box_custom*/
      var label = document.querySelectorAll('.label3');
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (
          !e.target.classList.contains('label3') &&
          !e.target.classList.contains('top') &&
          !e.target.classList.contains('middle') &&
          !e.target.classList.contains('bottom') &&
          !e.target.classList.contains('v-drumroll-picker__divider') &&
          !e.target.classList.contains('vue-scroll-picker-group') &&
          !e.target.classList.contains('v-drumroll-picker__container')
        ) {
          label.forEach(function (lb) {
            $this.setTime('setTime', $this.selectedTime);
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => {
        handleClose(e);
      });
    },

    setTime() {
      this.$emit('setTime', this.selectedTime);
    },
  },
};
</script>

<style scoped>
/* select_box */
.time_picker_setting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #383838;
  border-radius: 8px;
}
.time_picker_setting .time_setting_box {
  position: relative;
  width: 100%;
  border-radius: 8px;
}
.time_picker_setting .time_setting_box .setting_box_header {
  position: relative;
  width: 100%;
  border-radius: 8px;
  padding: 18px 16px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
.time_picker_setting .time_setting_box .setting_box_header::after {
  content: '';
  width: 12px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  background: url(/media/img/drop_down.svg) no-repeat center 0;
  transition: all 0.3s;
}
.time_picker_setting .time_setting_box .setting_box_drop_down {
  position: absolute;
  width: 100%;
  height: 230px;
  max-height: 0;
  top: 110%;
  padding: 0 4px;
  background: #3b3b3b;
  border-radius: 8px;
  overflow: auto;
  transition: all 0.3s;
  z-index: 10;
}

.time_picker_setting .time_setting_box .setting_box_drop_down .optionList {
  width: 100%;
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: auto;
  transition: all 0.3s;
}
.time_picker_setting .time_setting_box .setting_box_drop_down .optionList .optionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 16px;
  cursor: pointer;
}
.time_picker_setting .time_setting_box .setting_box_drop_down .optionList .optionItem:hover {
  background: hsla(0, 0%, 100%, 0.06);
}
.time_picker_setting .time_setting_box .setting_box_drop_down .optionList .optionItem p {
  color: hsla(0, 0%, 100%, 0.87);
  font-size: 16px;
  margin: 0;
}

/* active */
.time_picker_setting .time_setting_box.active .setting_box_header {
  box-shadow: 0 0 0 1px #ff2d55;
}
.time_picker_setting .time_setting_box.active .setting_box_header::after {
  transform: rotate(180deg) translateY(3px);
}

.time_picker_setting .time_setting_box.active .setting_box_drop_down {
  box-shadow: 0 0 0 1px #ff2d55;
  max-height: 200px;
}
.time_picker_setting .time_setting_box.active .setting_box_drop_down .optionList {
  max-height: 230px;
}

.time_picker_setting .time_setting_box .setting_box_drop_down .accept_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  color: #ff2d55;
  cursor: pointer;
}
</style>
