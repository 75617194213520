<template>
  <div class="profile_area">
    <div class="profile_item">
      <p>이메일</p>
      <div>
        <input type="text" v-model="corpInfo.email" disabled />
      </div>
    </div>

    <div class="profile_item">
      <p>휴대폰 번호</p>
      <div class="change_tel_btn_area">
        <input type="text" v-model="corpInfo.corpTel" disabled />
        <div class="change_tel_btn">
          <button @click="changeTel">변경하기</button>
          <div class="dp_none" id="refresh" @click="$emit('openChangeTelComplete')"></div>
        </div>
      </div>
    </div>

    <div class="withdrawal_btn">
      <p @click="openWithdrawal">회원 탈퇴</p>
    </div>
  </div>
</template>

<script>
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default {
  name: 'settingProfile',

  props: {
    corpInfo: Object
  },

  methods: {
    makeFormData(tokenVersionId, encData, integrityValue) {
      const form = document.createElement('form');
      form.target = 'popup';
      form.method = 'Post';
      form.setAttribute('action', process.env.VUE_APP_NICE_URL);

      const inputService = document.createElement('input');
      inputService.setAttribute('type', 'hidden');
      inputService.setAttribute('value', 'service');
      inputService.setAttribute('id', 'm');
      inputService.setAttribute('name', 'm');
      form.appendChild(inputService);

      const inputToken = document.createElement('input');
      inputToken.setAttribute('type', 'hidden');
      inputToken.setAttribute('value', tokenVersionId);
      inputToken.setAttribute('id', 'token_version_id');
      inputToken.setAttribute('name', 'token_version_id');
      form.appendChild(inputToken);

      const inputEncData = document.createElement('input');
      inputEncData.setAttribute('type', 'hidden');
      inputEncData.setAttribute('value', encData);
      inputEncData.setAttribute('id', 'enc_data');
      inputEncData.setAttribute('name', 'enc_data');
      form.appendChild(inputEncData);

      const inputIv = document.createElement('input');
      inputIv.setAttribute('type', 'hidden');
      inputIv.setAttribute('value', integrityValue);
      inputIv.setAttribute('id', 'integrity_value');
      inputIv.setAttribute('name', 'integrity_value');
      form.appendChild(inputIv);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
    async changeTel() {
      VueCookies.set('checkChangeTel', 'Y');
      window.open('', 'popup', 'width=545, height=875'); //eslint-disable-line no-unused-vars
      await axios
        .post('/api/nice/v2/userCheck')
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const { tokenVersionId, encData, integrityValue } = result;
            this.makeFormData(tokenVersionId, encData, integrityValue);
          }
        })
        .catch(e => {
          console.log(e);
        });
    },

    openWithdrawal() {
      this.$emit('openWithdrawal');
    }
  }
};
</script>

<style scoped>
.profile_area {
  position: relative;
  width: 100%;
  height: 100%;
}
.profile_area .profile_item {
  margin-bottom: 16px;
}
.profile_area .profile_item p {
  margin-bottom: 8px;
  color: hsla(0, 0%, 88.2%, 0.8);
  font-size: 16px;
}
.profile_area .profile_item input {
  width: 100%;
}
.profile_area .profile_item .change_tel_btn_area {
  position: relative;
}
.profile_area .profile_item .change_tel_btn_area .change_tel_btn {
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translate(0, -50%);
}
.profile_area .profile_item .change_tel_btn_area .change_tel_btn button {
  width: 80px;
  height: 40px;
  color: black;
}

.profile_area .withdrawal_btn {
  position: absolute;
  bottom: 0;
  left: 0;
}
.profile_area .withdrawal_btn p {
  color: hsla(0, 0%, 88.2%, 0.8);
  font-size: 16px;
  cursor: pointer;
}
</style>
