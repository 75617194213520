<template>
  <div class="change_pass_area">
    <div class="change_pass_input">
      <input type="password" v-model="currentPassword" placeholder="현재 비밀번호" ref="current">
      <input type="password" v-model="newPassword" placeholder="새 비밀번호(영문, 숫자, 특수문자 포함 8~20자리)" @keyup="checkPassword" ref="new">
      <input type="password" v-model="reEnterPassword" placeholder="새 비밀번호 재입력" @keyup="checkSamePassword" ref="reEnter">
    </div>
    <div class="alert_txt">
      <p v-if="checkStatus">비밀번호를 규칙에 맞게 입력해주세요.</p>
      <p v-if="samePassword">비밀번호를 동일하게 입력해주세요.</p>
      <p v-if="emptyAlert">비밀번호를 입력해주세요.</p>
    </div>
    <div class="change_pass_btn">
      <button @click="changePassword">확인</button>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  name: 'settingChangePassword',

  data () {
    return {
      //비밀번호
      currentPassword: '',
      newPassword: '',
      reEnterPassword: '',

      //새 비밀번호 유효성
      checkStatus: false,

      //새 비밀번호 동일 여부
      samePassword: false,

      //비밀번호 미입력
      emptyAlert: false
    }
  },

  methods: {
    checkPassword () {
      /^.*(?=.{8,20})(?=.*[!@#$%^*+=-])(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(this.newPassword) ? this.checkStatus = false : this.checkStatus = true;
    },

    checkSamePassword () {
      this.newPassword === this.reEnterPassword ? this.samePassword = false : this.samePassword = true;

      //새 비밀번호, 재입력 비밀번호가 공백일 시 얼럿 숨기기
      if (this.newPassword === '' || this.reEnterPassword === '') this.samePassword = false;
    },

    changePassword () {
      // 비밀번호 공백 체크
      if (this.currentPassword !== '' && this.newPassword !== '' && this.reEnterPassword !== '') {
        //공백 체크 얼럿 제거
        this.emptyAlert = false;

        // 비밀번호 유효성 검사 / 동일한지 체크
        if (!this.checkStatus && !this.samePassword) {
          //비밀번호 변경
          const corpId = VueCookies.get('corpId');
          const password = btoa(this.currentPassword);
          const newPass = btoa(this.newPassword);
          const aToken = VueCookies.get("aToken");
          const headers = { "X-AUTH-TOKEN": aToken };

          //토큰 확인 절차 추가
          axios
            .post('/api/corp/updateCorpPass', { corpId, password, newPass }, { headers })
            .then(res => {
              if (res.data.resultCd === '0000') {
                this.$emit('openChangeComplete');
              }
            })
            .catch(e => { console.log(e); })
        }
      } else {
        if (this.reEnterPassword === '') this.$refs.reEnter.focus();
        if (this.newPassword === '') this.$refs.new.focus();
        if (this.currentPassword === '') this.$refs.current.focus();
        this.emptyAlert = true;
      }
    }
  }
}
</script>

<style scoped>
.change_pass_area { position: relative; width: 100%; height: 100%; }
.change_pass_area .change_pass_input input { width: 100%; margin-bottom: 8px; }

.change_pass_area .alert_txt { margin: 0; color: #ff4949!important; font-size: 14px; }

.change_pass_area .change_pass_btn { position: absolute; bottom: 0; right: 0; }
.change_pass_area .change_pass_btn button {
  width: 90px;
  height: 48px;
  line-height: 48px;
  background: #ff2d55;
  color: #fff;
  font-weight: 500;
  text-align: center;
  transition: all .3s;
}
.change_pass_area .change_pass_btn button:hover { opacity: 0.7; }
</style>
