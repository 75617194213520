import axios from "axios";
import VueCookies from 'vue-cookies';
/**
 * 토큰 재발급 후 axios 헤더 값 수정해줌
 * response안에서 처리하게 했을 때 무한 로딩이 자꾸 걸려서 소스를 분리해서 async/await로 처리함
 */

//request 설정
axios.interceptors.request.use(async function (config) {
  //헤더 셋팅
  config.headers["X-AUTH-TOKEN"] = VueCookies.get('aToken');

  //console.log(config);
  return config;
}, function (error) {
  //console.log('axios request error : ', error);
  return Promise.reject(error);
});
//response 설정
axios.interceptors.response.use(
  function (response) {
    try {
      return response;
    } catch (err) {
      console.error('[axios.interceptors.response] response : ', err.message);
    }
  },
  async function (error) {
    try {
      //에러에 대한 response 정보
      const errorAPI = error.response.config; //요청했던 request 정보가 담겨있음
      //인증에러 및 재요청이 아닐 경우... (+재요청인데 refreshToken이 있을 경우)
      if (error.response.status === 403 && errorAPI.retry === undefined) {
        errorAPI.retry = true; //재요청이라고 추가 정보를 담음

        var accessToken = VueCookies.get('aToken');
        var refreshToken = VueCookies.get('rToken');
        var headers = { "X-AUTH-TOKEN": accessToken };

        await axios.post("/api/auth/reissue", { accessToken, refreshToken }, { headers })
          .then(res => {
            var aToken = res.data.result.accessToken;
            var rToken = res.data.result.refreshToken;
            //쿠키 token셋팅
            VueCookies.set("aToken", aToken);
            VueCookies.set("rToken", rToken);
          });
        return await axios(errorAPI); //다시 axios 요청
      }
    } catch (err) {
      console.error('[axios.interceptors.response] error : ', err.message);
    }
    return Promise.reject(error);
  });

export default axios;
