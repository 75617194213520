<template>
  <section class="main cs policy">
    <div class="main_box">
      <h1>이용약관</h1>
      <div class="cs_main">
        <div class="side_depth_box">
          <div class="one_depth">
            <router-link class="policy" :class="{ on : this.$route.path === '/brand/policy/service' }" to="/brand/policy/service" data-tab="tab1" @click.native="addClass">이용약관</router-link>
            <router-link class="personal" :class="{ on : this.$route.path === '/brand/policy/privacy' }"  to="/brand/policy/privacy" data-tab="tab2" @click.native="addClass">개인정보 처리방침</router-link>
            <!-- <router-link class="marketing"  to="/brand/policy/marketing" data-tab="tab3" @click.native="addClass">마케팅 정보 수신</router-link> -->
          </div>
        </div>
        <div class="cs_content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import $ from 'jquery';

export default {
  methods: {
    /**
     * * 하위 페이지 변경
     */
    addClass(){
      $(document).on('click', ".policy .one_depth > a", function(){
        var tab_active = $(this).attr('data-tab');

        $(".policy .one_depth > a").removeClass('on');
        $(".policy .cs_content > div").removeClass('on');

        $(this).addClass('on');

        $("#"+tab_active).addClass('on');
      });
    }
  }
}
</script>
<style scoped>
  .cs_content { width: calc(100% - 260px); }
</style>