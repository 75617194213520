<template>
  <div>
    <div class="email_certifi_modal alert">
      <div class="email_certifi_box alert_box">
        <h3>입력하신 정보로 기입된 정보가 없습니다.</h3>
        <div class="card_popup_btn alert_close_btn">
          <p id="color_txt" @click="$emit('closeFindIdModal')">확인</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * * 아이디 찾기 팝업
 * * 아이디 또는 휴대폰 번호가 일치하지 않았을 경우에 노출되는 팝업
 */

export default {
  name: 'findIdModal',
}
</script>