<template>
  <div class="wrap" style="overflow: auto;">
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'LayoutHome'
}
</script>

<style scoped>

</style>