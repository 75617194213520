<template>
  <div>
    <section class="login_main find_pw">
      <div class="login_main_box">
        <h1>아이디 찾기</h1>
        <p>회원님의 이름/전화번호로 가입된 아이디는 다음과 같습니다.</p>
        <div>
          <ul>
            <li v-for="(item, index) in idList" :key="index">
              <div :id="index">{{ item.email }}</div>
            </li>
          </ul>
        </div>
        <button @click="loginBtn">로그인</button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    idList: Array
  },

  data () {
    return{
      email: ''
    }
  },

  methods: {
    /**
     * * 로그인 페이지로 이동
     */
    loginBtn () {
      this.$router.push({ name: 'brand/login' });
    }
  }
}
</script>

<style scoped>
.login_main_box p { padding: 0; margin-bottom: 8px; text-align: left; text-decoration: none; }
.login_main_box ul { display: flex; flex-direction: column; }
.login_main_box ul li { margin: 8px 0; }
.login_main_box ul li div { height: 50px; color: white; text-align: center; line-height: 50px; background-color: rgba(255, 255, 255, 0.08); border-radius: 8px; }
.login_main_box button { margin-top: 32px; }
</style>