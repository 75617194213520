<template>
    <div class="inquiry" style="width: 100%">
        <QuestionModal v-if="modalStatus" @closeModal="modalStatus = false" @getList="getList" />
        <DeleteQuestion v-if="questionStatus" @closeDeleteQUestion="questionStatus = false"
            @delteQuestion="deleteQuestion()" />
        <div class="cs_content">
            <div class="inquiry_btn_box">
                <span class="open_popup" @click="modalStatus = true" style="cursor: pointer;">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.00016 2.66634C6.58567 2.66634 5.22912 3.22824 4.22893 4.22844C3.22873 5.22863 2.66683 6.58519 2.66683 7.99967H4.66683C5.02045 7.99967 5.35959 8.14015 5.60964 8.3902C5.85969 8.64025 6.00016 8.97939 6.00016 9.33301V12.6663C6.00016 13.02 5.85969 13.3591 5.60964 13.6091C5.35959 13.8592 5.02045 13.9997 4.66683 13.9997H2.66683C2.31321 13.9997 1.97407 13.8592 1.72402 13.6091C1.47397 13.3591 1.3335 13.02 1.3335 12.6663V7.99967C1.3335 4.31767 4.31816 1.33301 8.00016 1.33301C11.6822 1.33301 14.6668 4.31767 14.6668 7.99967V12.6663C14.6668 13.02 14.5264 13.3591 14.2763 13.6091C14.0263 13.8592 13.6871 13.9997 13.3335 13.9997H11.3335C10.9799 13.9997 10.6407 13.8592 10.3907 13.6091C10.1406 13.3591 10.0002 13.02 10.0002 12.6663V9.33301C10.0002 8.97939 10.1406 8.64025 10.3907 8.3902C10.6407 8.14015 10.9799 7.99967 11.3335 7.99967H13.3335C13.3335 6.58519 12.7716 5.22863 11.7714 4.22844C10.7712 3.22824 9.41465 2.66634 8.00016 2.66634ZM2.66683 9.33301V12.6663H4.66683V9.33301H2.66683ZM11.3335 9.33301V12.6663H13.3335V9.33301H11.3335Z"
                            fill="white" />
                    </svg>
                    1:1 문의
                </span>
            </div>
            <div class="cs_list_box">
                <ul class="cs_list" v-for="(item, i) in questionList" :key="i">
                    <li @click="toggleOnOff">
                        <p v-html="item.questionTitle" />
                        <span class="complet" v-if="item.answerAt === 'Y'">답변완료</span>
                        <span v-else>답변전</span>
                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 7.99935L0 0.666016H12L6 7.99935Z" fill="white" fill-opacity="0.87" />
                        </svg>
                    </li>
                    <li class="sub_cs_list">
                        <ul>
                            <li>
                                <p v-html="replaceEnter(item.questionContent)"></p>
                                <div class="complet_time">
                                    <p>
                                        <span>{{ dateTime(item.registDate) }}</span>
                                    </p>
                                    <div class="delet_btn">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.66683 2.66536V1.33203H11.3335V2.66536H14.6668V3.9987H13.3335V13.9987C13.3335 14.1755 13.2633 14.3451 13.1382 14.4701C13.0132 14.5951 12.8436 14.6654 12.6668 14.6654H3.3335C3.15669 14.6654 2.98712 14.5951 2.86209 14.4701C2.73707 14.3451 2.66683 14.1755 2.66683 13.9987V3.9987H1.3335V2.66536H4.66683ZM4.00016 3.9987V13.332H12.0002V3.9987H4.00016ZM6.00016 5.9987H7.3335V11.332H6.00016V5.9987ZM8.66683 5.9987H10.0002V11.332H8.66683V5.9987Z"
                                                fill="white" />
                                        </svg>
                                        <p style="cursor: pointer;" @click="deleteBtn(item.questionId)">삭제하기</p>
                                    </div>
                                </div>
                            </li>
                            <li class="inquiry_complet" v-for="(answer, index) in item.questionAnswerList" :key="index">
                                <span class="complet_0">답변</span>
                                <p style="margin-bottom:0px;" v-html="replaceEnter(answer.answerContent)" />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <infinite-loading v-if="status" @infinite="infiniteHandler" ref="infiniteLoading" spinner="waveDots">
                <div slot="no-more"></div>
                <div slot="no-results"></div>
                <div slot="error"></div>
            </infinite-loading>
        </div>
    </div>
</template>

<script>
import DeleteQuestion from "@/components/modal/cs/DeleteQuestion.vue";
import QuestionModal from '@/components/modal/cs/QuestionModal.vue';
import InfiniteLoading from 'vue-infinite-loading';
import VueCookies from "vue-cookies";
import axios from "@/service/axios";
import dayjs from "dayjs";
import $ from "jquery";

export default {
    components: {
        InfiniteLoading,
        DeleteQuestion,
        QuestionModal
    },

    data() {
        return {
            questionId: '',

            //목록
            itemList: [],
            questionList: [],

            //팝업
            modalStatus: false,
            questionStatus: false,

            //무한 스크롤
            limit: 0,
            status: false
        };
    },

    mounted() {
        this.getQuestionList();
    },

    updated() {
        this.set();
    },

    methods: {
        /**
         * * 1:1문의 목록 불러오기
         */
        getQuestionList() {
            const questionWriter = VueCookies.get("corpId");
            const aToken = VueCookies.get("aToken");
            const headers = { "X-AUTH-TOKEN": aToken };

            axios
                .post('/api/menu/getMyQuestionList', { questionWriter }, { headers })
                .then(res => {
                    const data = res.data.result;
                    for (let i in data) {
                        if (data[i].questionAnswerList.length > 0) {
                            const { questionAnswerList } = data[i];
                            questionAnswerList.forEach(item => {
                                item.answerContent = item.answerContent.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/").replace(/(?:\r\n|\r|\n)/g, "</br>");
                            });
                            //data[i].questionAnswerList[0].answerContent = data[i].questionAnswerList[0].answerContent.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/").replace(/(?:\r\n|\r|\n)/g, "</br>");
                        }
                    }
                    this.itemList = data;
                    this.status = true;
                })
                .catch(err => {
                    console.log(err);
                });
        },

        /**
         * * 문의 목록 닫기
         */
        set() {
            $(".sub_cs_list").hide();
        },

        /**
         * * 무한 스크롤 이벤트
         */
        infiniteHandler($state) {
            if (this.limit >= this.itemList.length) {
                $state.complete();
            } else {
                setTimeout(() => {
                    this.questionList = this.questionList.concat(this.itemList.slice(this.limit, (this.limit + 10)));
                    this.limit += 10;

                    if (this.limit >= this.itemList.length) {
                        $state.complete();
                    } else {
                        $state.loaded();
                    };
                }, 1000);
            }
        },

        /**
         * * 특수문자 줄바꿈 변환
         */
        replaceEnter(index) {
            return String(index).replace(/(?:\r\n|\r|\n)/g, "</br>");
        },

        /**
         * * 날짜 포맷
         */
        dateTime(value) {
            return dayjs(value).format("YYYY.MM.DD \u00a0 | \u00a0 HH:mm");
        },

        /**
         * * 1:1문의 열기/닫기
         */
        toggleOnOff() {
            $(document).off("click").on("click", ".cs_list > li:first-child", function () {
                $(this).siblings().slideToggle(300);
                $(this).children("img").toggleClass("rotate");
            });
        },

        /**
         * * 1:1문의 삭제 팝업 열기
         */
        deleteBtn(id) {
            this.questionId = id;
            this.questionStatus = true;
        },

        /**
         * * 1:1문의 삭제
         */
        deleteQuestion() {
            const questionId = this.questionId;
            const aToken = VueCookies.get("aToken");
            const headers = { "X-AUTH-TOKEN": aToken };

            axios
                .post('/api/menu/deleteQuestion', { questionId }, { headers })
                .then(res => {
                    if (res.data.resultCd === '0000') {
                        this.questionStatus = false;
                        this.getList();
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },

        /**
         * * 신규 1:1문의 등록 후 이벤트
         * * 무한 스크롤 이벤트 초기화
         * * 1:1문의 목록 재호출
         */
        getList() {
            this.status = false;
            this.limit = 0;
            this.questionList = [];
            this.getQuestionList();
            this.$refs.infiniteLoading.stateChanger.reset();
        }
    }
};
</script>
<style scoped>
.complet_time span {
    color: #fff;
}

.inquiry .inquiry_btn_box span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    padding: 12px 38px;
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 8px;
}</style>
<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/content.css"></style>
