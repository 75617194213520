<template>
  <div>
    <Aside v-if="toggleStatus" @closeSideMenu="toggleStatus = false" v-click-outside="onClickOutside"/>
    <header class="header">
      <div class="header_box">
        <div class="logo">
          <router-link to="/brand/main">
            <img src="/media/img/logo.svg" alt="">
          </router-link>
        </div>
        <div class="right_menu">
          <div class="shop_info">
            <p>{{ mail }}</p>
          </div>
          <div class="alarm_box" @click="noticeLink">
            <!-- <img src="/media/img/alarm_active.png" alt=""> -->
            <img src="/media/img/alarm.png" alt="">
          </div>
          <div class="toggle" @click="toggleStatus = true">
            <img src="/media/img/toggle_btn.png" alt="">
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import Aside from "@/layout/aside/Aside.vue";
import VueCookies from "vue-cookies";
import vClickOutside from 'v-click-outside';

export default {
  name: 'header',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return{
      toggleStatus: false,
      mail: VueCookies.get("corpEmail"),
    }
  },
  components: {
    Aside,
  },
  methods: {
    onClickOutside () {
      this.toggleStatus = false
    },
    noticeLink(){
      this.$router.push('/brand/notice');
    }
  }
};
</script>

