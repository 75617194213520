<template>
    <div>
        <div id="wrap" class="main02">
            <div id="header">
                <div class="hd_inr cf">
                    <div class="logo">
                        <router-link :to="{ name: 'brand/home' }">
                            <img class="pc_logo" src="/media/img/logo_white.svg" alt="" />
                            <img class="mobile_logo" src="/media/img/logo_m.png" alt="" />
                        </router-link>
                    </div>
                    <div id="nav">
                        <div class="gnb">
                            <ul class="cf">
                                <li class="gnb_login" @click="login"><span>로그인</span></li>
                                <li class="gnb_join" @click="signUp"><span>회원가입</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div id="container">
                <Modal v-if="isModal" @closeModal="isModal = false" />
                <CheckMobile v-if="isCheckMo" @closeMobileCheck="isCheckMo = false" />
                <Inquiry v-if="isInquiry" @closeInquiry="isInquiry = false" />
                <ResendEmail2 v-if="modalStatus" @closeModal="modalStatus = false" :toMail="email" />
                <div id="content">
                    <div class="swiper-container full_wrap pg_frac">
                        <swiper class="swiper-wrapper full_box swiper" :options="swiperOption">
                            <swiper-slide class="swiper-slide full_con full_con01">
                                <div class="mask"></div>
                                <div class="substance_box wow fadeInUp"
                                    style="height: 264px; margin-top: -132px; visibility: visible; animation-name: fadeInUp">
                                    <strong>
                                        브랜드별<br />
                                        특화된 선곡으로<br />
                                        브랜드만의<br />
                                        차별화된 공간 경험 제공<br /><br />
                                    </strong>
                                </div>
                                <div class="images_box">
                                    <ul class="auto_up_img auto_up_box01">
                                        <li><img class="circle" src="/media/images/newbrand/newbrand1.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand2.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand3.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand4.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand5.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand6.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand7.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand8.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand9.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand10.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand11.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand12.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand13.png" alt="" /></li>
                                    </ul>
                                    /media/
                                    <ul class="auto_up_img auto_up_box02">
                                        <li><img class="circle" src="/media/images/newbrand/newbrand14.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand15.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand16.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand17.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand18.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand19.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand20.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand21.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand22.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand23.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand24.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand25.png" alt="" /></li>
                                        <li><img class="circle" src="/media/images/newbrand/newbrand26.png" alt="" /></li>
                                    </ul>
                                </div>
                            </swiper-slide>
                            <swiper-slide class="swiper-slide full_con full_con02">
                                <div class="substance_box wow fadeInUp"
                                    style="height: 106px; margin-top: -132px; visibility: visible; animation-name: fadeInUp">
                                    <strong>
                                        효과적인 브랜드 <br class="m_br" />상품/서비스<br />
                                        홍보 및 안내 가능
                                    </strong>
                                </div>
                            </swiper-slide>
                            <swiper-slide class="swiper-slide full_con full_con03">
                                <div class="substance_box wow fadeInUp"
                                    style="height: 159px; margin-top: -132px; visibility: visible; animation-name: fadeInUp">
                                    <strong>
                                        브랜드 소속 매장별<br />
                                        실시간 재생 음악<br />
                                        모니터링 가능
                                    </strong>
                                </div>
                                <div class="music_wrap">
                                    <ul class="music_box">
                                        <li class="music_list muli01">
                                            <p>
                                                <span class="m_ty01"><img src="/media/images/music/cp_con03_music01_ty1.png"
                                                        alt="" /></span>
                                                <span class="m_ty02"><img src="/media/images/music/cp_con03_music01_ty2.png"
                                                        alt="" /></span>
                                            </p>
                                            <span class="m_ty03"><img src="/media/images/music/cp_con03_music01_ty3.png"
                                                    alt="" /></span>
                                        </li>
                                        <li class="music_list muli02">
                                            <p>
                                                <span class="m_ty01"><img src="/media/images/music/cp_con03_music02_ty1.png"
                                                        alt="" /></span>
                                                <span class="m_ty02"><img src="/media/images/music/cp_con03_music02_ty2.png"
                                                        alt="" /></span>
                                            </p>
                                            <span class="m_ty03"><img src="/media/images/music/cp_con03_music02_ty3.png"
                                                    alt="" /></span>
                                        </li>
                                        <li class="music_list muli03">
                                            <p>
                                                <span class="m_ty01"><img src="/media/images/music/cp_con03_music03_ty1.png"
                                                        alt="" /></span>
                                                <span class="m_ty02"><img src="/media/images/music/cp_con03_music03_ty2.png"
                                                        alt="" /></span>
                                            </p>
                                            <span class="m_ty03"><img src="/media/images/music/cp_con03_music03_ty3.png"
                                                    alt="" /></span>
                                        </li>
                                    </ul>
                                </div>
                            </swiper-slide>
                            <swiper-slide class="swiper-slide full_con full_con04">
                                <div class="substance_box wow fadeInUp"
                                    style="height: 106px; margin-top: -132px; visibility: visible; animation-name: fadeInUp">
                                    <strong>
                                        개별 결제 / 본사 통합 <br class="m_br" />결제 선택 가능으로<br />
                                        납부 편의성 제공
                                    </strong>
                                    <span>*별도 문의</span>
                                </div>
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-pagination pg01"></div>
                        <div class="swiper-button-prev cp_sl_prev"></div>
                        <div class="swiper-button-next cp_sl_next"></div>
                    </div>
                </div>
            </div>
            <footer-component @openinqury="inquiry" @openonetoone="question" @gohomeservice="service"
                @goprivacy="privacy" />
            <noticemodal-component />
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import Inquiry from '@/components/modal/main/PartnershipInquiry.vue';
import ResendEmail2 from '@/components/modal/signUp/ResendEmail2';
import Modal from '@/components/modal/main/LinkLogin.vue';
import CheckMobile from '@/components/modal/main/CheckMobile.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import router from '@/router';
import FooterComponent from '@/components/home/FooterComponent.vue';
import NoticeModalComponent from '@/components/notice/NoticeModalComponent.vue';
export default {
    name: 'CorpMain',

    data() {
        return {
            modalStatus: false,
            interval: null,
            slideIndex: 0,
            isModal: false,
            isInquiry: false,
            swiperOption: {
                brandid: '',
                initialSlide: 0,
                slidesPerView: 1,
                spaceBetween: 30,
                allowTouchMove: false,
                loop: false,
                speed: 1000,
                grabCursor: false,
                pagination: {
                    el: '.pg01',
                    type: 'bullets',
                    clickable: true,
                },
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: '.cp_sl_next',
                    prevEl: '.cp_sl_prev',
                },
            },
            checkMobile: false,
            isCheckMo: false,
        };
    },

    components: {
        Swiper,
        SwiperSlide,
        Inquiry,
        Modal,
        ResendEmail2,
        CheckMobile,
        'footer-component': FooterComponent,
        'noticemodal-component': NoticeModalComponent
    },

    mounted() {
        this.checkDevice();
        if (this.checkMobile === false) {
            this.loginAuto();
        }
        const mainInterval = setInterval(this.showSlide, 2000);
        this.interval = mainInterval;

    },

    beforeDestroy() {
        clearInterval(this.interval);
    },

    methods: {
        /**
         * * 디바이스 체크
         * * 법인 페이지는 랜딩 페이지를 제외하면 PC에서만 이용이 가능
         */
        checkDevice() {
            var pcDevice = 'win16|win32|win64|mac|macintel';
            if (navigator.platform) {
                if (pcDevice.indexOf(navigator.platform.toLowerCase()) < 0) {
                    this.checkMobile = true;
                } else {
                    this.checkMobile = false;
                }
            }
        },

        /**
         * * 서비스 이용약관 클릭 이벤트
         * * 비로그인 서비스 이용약관 페이지로 이동
         */
        service() {
            this.checkMobile ? (this.isCheckMo = true) : this.$router.push('/brand/stplat/service');
        },

        /**
         * * 개인정보 처리방침 클릭 이벤트
         * * 비로그인 개인정보 처리방침 페이지로 이동
         */
        privacy() {
            this.checkMobile ? (this.isCheckMo = true) : this.$router.push('/brand/stplat/privacy');
        },

        /**
         * * 제휴문의 클릭 이벤트
         * * 제휴문의 팝업 열기
         */
        inquiry() {
            this.checkMobile ? (this.isCheckMo = true) : (this.isInquiry = true);
        },

        /**
         * * 1:1문의 클릭 이벤트
         * * PC일 경우에만 팝업 열기
         */
        question() {
            this.checkMobile ? (this.isCheckMo = true) : (this.isModal = true);
        },

        /**
         * * 회원가입 페이지로 이동
         */
        signUp() {
            this.checkMobile ? (this.isCheckMo = true) : this.$router.push('/brand/signUp');
        },

        /**
         * * 로그인 페이지로 이동
         */
        login() {
            this.checkMobile ? (this.isCheckMo = true) : this.$router.push('/brand/login');
        },

        /**
         * * 자동 로그인
         */
        loginAuto() {
            if (VueCookies.get('autoLogin') === 'true') {
                const email = VueCookies.get('corpEmail');
                const aToken = VueCookies.get('aToken');
                const headers = { 'X-AUTH-TOKEN': aToken };

                axios
                    .post('/api/corp/mailAcceptCheck', { email }, { headers }) //이메일 인증
                    .then(res3 => {
                        if (res3.data.result) {
                            //이메일 인증 완료
                            axios
                                .post('/api/corp/getLoginCorp', { email }, { headers })
                                .then(response => {
                                    const corpId = response.data.result.corpId;
                                    const corpEmail = response.data.result.email;
                                    const userType = response.data.result.userType;

                                    //쿠키 셋팅
                                    VueCookies.set('corpId', corpId);
                                    VueCookies.set('corpEmail', corpEmail);
                                    VueCookies.set('userType', userType);
                                    if (VueCookies.get('slideNum') == null) {
                                        VueCookies.set('slideNum', 1);
                                    }

                                    // 메인 페이지로 이동
                                    router.push({ name: 'brand' });
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        } else {
                            //이메일 인증 미완료
                            this.modalStatus = true;
                        }
                    });
            }
        },

        /**
         * * 슬라이드
         */
        showSlide() {
            const dots = document.getElementsByClassName('music_list');
            this.slideIndex++;
            if (this.slideIndex > dots.length) {
                this.slideIndex = 1;
            }
            for (let i = 0; i < dots.length; i++) {
                dots[i].className = dots[i].className.replace(' active', '');
            }
            dots[this.slideIndex - 1].className += ' active';
        },
    },
};
</script>
<style scoped>
.circle {
    border-radius: 50%;
}
</style>
<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/animate.css"></style>
<style src="@/assets/css/style.css"></style>
<style src="@/assets/css/reset.css"></style>
<style src="@/assets/css/jquery-ui.min.css"></style>
