<template>
  <div>
    <ResendEmail v-if="modalStatus" @closeResendModal="modalStatus=false" />

    <section class="login_main">
      <div class="login_main_box join_box">
        <img src="/media/img/join_1_icon.png" alt="">
        <h1>가입완료</h1>
        <h3>회원가입을 축하드립니다!</h3>
        <p>메일로 발송된 인증 메일을 확인해서 계정을 활성화해주세요.</p>
        <div class="email_check">
          <h3>{{ toMail }}</h3>
        </div>
        <p id="email_retry" @click="resendBtn">인증메일 재발송</p>
        <router-link to="/brand/login"><button>로그인</button></router-link>
      </div>
    </section>
  </div>
</template>

<script>
import ResendEmail from '@/components/modal/signUp/ResendEmail.vue';
import axios from "axios";

export default {
  props: {
    toMail: String
  },

  components: {
    ResendEmail
  },

  data(){
    return{
      modalStatus: false,
    }
  },

  methods: {
    /**
     * * 인증번호 재발송
     */
    resendBtn () {
      const toMail = this.toMail;

      axios
        .post('/api/corp/getAuthEmail', { toMail })
        .then(res => {
          if (res.status === 200) {
            this.modalStatus = true;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
</script>
