<template>
  <section class="main">
    <InsertBrand v-if="popupStatus" @closeInsertModal="popupStatus = false" @getList="getList"/>

    <div class="main_content">
      <div>
        <div class="my_brand">
          <div class="my_brand_title">
            <h2>나의 브랜드</h2>
            <!-- <router-link to="/brand/brandManagement">
              <p>브랜드관리</p>
            </router-link> -->
            <div @click="popupStatus = true">
              <img src="/media/img/space_upload.png" alt="">
              <p>브랜드 등록</p>
            </div>
          </div>
          <div class="slide_area">
            <div class="mySwiper">
              <div class="swiper-wrapper">
                <swiper class="swiper" :options="swiperOption" ref="mySwiper">
                  <swiper-slide class="swiper-slide" v-for="(item, index) in brandList" :key="index">
                    <img :src="item.brandImg" alt="" >
                    <div class="slide_area_content">
                      <p>브랜드명<br><span class="brand_name" v-html="item.brandNm" /></p>
                      <p>승인여부
                        <br>
                        <span v-if="item.brandAcceptAt === 'Y' && item.brandRequestAt === 'N'">승인 완료</span>
                        <span v-else-if="item.brandRequestAt === 'N' && item.brandAcceptAt === 'N'">승인 거절</span>
                        <span v-else >승인 대기</span>
                      </p>
                    </div>
                    <input type="hidden" :value="item.brandId">
                  </swiper-slide>
                </swiper>
                <div class="swiper" v-if="brandList.length === 0">
                  <div class="swiper-slide">
                    <img src="/media/img/thumnail_empty_200x200.png" alt="" style="width: 198px; height: 198px;">
                    <div class="slide_area_content" style="width: 700px;">
                      <p>브랜드명<br><span class="brand_name">-</span></p>
                      <p>승인여부<br><span>-</span></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
            <div class="swiper-button-next" v-if="total !== 1" @click="moveSlide"></div>
            <div class="swiper-button-prev" v-if="total !== 1" @click="moveSlide"></div>
          </div>
        </div>
        <div class="brand_group">
          <div class="my_brand_title">
            <h2>소속 매장 현황</h2>
          </div>
          <div class="card_box">
            <div class="brand_group_card" style="cursor: pointer;" @click="shopLink">
              <p>
                <span></span>
                연결 매장
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.543701 0.506348L6.5437 6.50635L0.543701 12.5063" stroke="white"/>
                </svg>
              </p>
              <div>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="28" cy="28" r="28" fill="white" fill-opacity="0.08"/>
                  <g opacity="0.6">
                    <path d="M43 40V43H13V40H14.5V29.863C13.5766 29.2466 12.8196 28.4118 12.2962 27.4326C11.7729 26.4535 11.4994 25.3602 11.5 24.25C11.5 23.0095 11.836 21.814 12.4495 20.7955L16.5175 13.75C16.6491 13.522 16.8385 13.3326 17.0665 13.201C17.2945 13.0693 17.5532 13 17.8165 13H38.185C38.4483 13 38.707 13.0693 38.935 13.201C39.163 13.3326 39.3524 13.522 39.484 13.75L43.537 20.773C44.4318 22.2593 44.7195 24.0333 44.3401 25.7262C43.9607 27.4191 42.9436 28.9007 41.5 29.863V40H43ZM18.6835 16L15.034 22.3195C14.6794 23.1957 14.6687 24.1735 15.0042 25.0573C15.3397 25.941 15.9965 26.6654 16.8433 27.0856C17.69 27.5057 18.6642 27.5906 19.5709 27.3232C20.4775 27.0558 21.2498 26.4559 21.733 25.6435C22.2355 24.388 24.013 24.388 24.517 25.6435C24.7949 26.3401 25.2754 26.9374 25.8963 27.3582C26.5172 27.7789 27.25 28.0038 28 28.0038C28.75 28.0038 29.4828 27.7789 30.1037 27.3582C30.7246 26.9374 31.2051 26.3401 31.483 25.6435C31.9855 24.388 33.763 24.388 34.267 25.6435C34.4617 26.1227 34.7533 26.5564 35.1237 26.9174C35.4941 27.2784 35.9351 27.5589 36.4191 27.7413C36.9031 27.9236 37.4196 28.0039 37.9361 27.977C38.4526 27.9502 38.958 27.8167 39.4204 27.5851C39.8829 27.3535 40.2924 27.0288 40.6233 26.6313C40.9542 26.2338 41.1993 25.7721 41.3432 25.2754C41.4871 24.7786 41.5267 24.2574 41.4594 23.7446C41.3922 23.2318 41.2196 22.7384 40.9525 22.2955L37.315 16H18.685H18.6835Z" fill="white"/>
                  </g>
                </svg>
                <p>
                  <span>{{ brandInfo.enterCount }}
                    <!-- <span class="new"></span> -->
                  </span>
                  <span v-if="brandList.length === 0">0 </span>
                  개
                </p>
              </div>
            </div>
            <div class="brand_group_card" style="cursor: pointer;" @click="requestLink">
              <p>
                <span></span>
                연결 요청 매장
                <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.543701 0.506348L6.5437 6.50635L0.543701 12.5063" stroke="white"/>
                </svg>
              </p>
              <div>
                <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="0.2" cx="28" cy="28" r="28" fill="#F4F4F4"/>
                  <g opacity="0.3">
                    <path d="M9.919 25.9725C9.136 25.7115 9.1285 25.29 9.934 25.0215L38.5645 15.4785C39.358 15.2145 39.8125 15.6585 39.5905 16.4355L31.4095 45.0645C31.1845 45.858 30.727 45.885 30.391 45.132L25 33L34 21L22 30L9.919 25.9725Z" fill="white"/>
                  </g>
                </svg>
                <p>
                  <span>{{ brandInfo.enterRequestCount }}
                    <!-- <span class="new"></span> -->
                  </span>
                  <span v-if="brandList.length === 0">0 </span>
                  개
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="brand_music">
        <div class="my_brand_title music_list_title">
          <h2>브랜드 음원 리스트</h2>
        </div>
        <p style="color: white; padding: 0 0 24px 32px; font-size: 16px;">배포중 음원</p>
        <div class="brand_list">
          <ul>
            <li v-for="item, i in brandInfo.brandMusicList" :key="i">
              <!-- <img src="/media/img/thumnail_empty_200x200.png" style="width: 20px; height: 20px; border-radius: 50%; margin-right: 10px;" /> -->
              <p style="font-size: 14px" v-html="item.brandMusicNm" />
            </li>
          </ul>
        </div>
        <div class="brand_music_bottom" @click="musicLink">
          <p>브랜드 음원 관리</p>
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.66667 2V3.33333H1.33333V10.6667H8.66667V7.33333H10V11.3333C10 11.5101 9.92976 11.6797 9.80474 11.8047C9.67971 11.9298 9.51014 12 9.33333 12H0.666667C0.489856 12 0.320286 11.9298 0.195262 11.8047C0.0702379 11.6797 0 11.5101 0 11.3333V2.66667C0 2.48986 0.0702379 2.32029 0.195262 2.19526C0.320286 2.07024 0.489856 2 0.666667 2H4.66667ZM12 0V5.33333H10.6667V2.27533L5.47133 7.47133L4.52867 6.52867L9.72333 1.33333H6.66667V0H12Z" fill="white"/>
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InsertBrand from "@/components/modal/brandManagement/InsertBrand.vue";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import VueCookies from "vue-cookies";
import axios from "@/service/axios";
import 'swiper/css/swiper.css';
import router from '@/router';
import $ from 'jquery';

export default {
  components: {
    Swiper,
    SwiperSlide,
    InsertBrand
  },

  data () {
    return{
      brandList:[],
      brandInfo: {},
      brandId: '',

      // * 슬라이드 세팅
      total: 0,
      swiperOption: {
        brandid: '',
        initialSlide: parseInt(VueCookies.get('slideNum')),
        slidesPerView: 1,
        spaceBetween: 30,
        allowTouchMove: false,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },

      // * 브랜드 등록 팝업
      popupStatus: false
    }
  },

  mounted () {
    this.getBrandList();
  },

  methods: {
    /**
     * * 브랜드 목록 불러오기
     * * 
     */
    getBrandList () {
      const corpId = VueCookies.get("corpId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      axios
        .post('/api/brand/getBrandList', { corpId }, { headers })
        .then(res => {
          const url = document.location.href;
          const imgPath = url.indexOf('local') !== -1 || url.indexOf('dev') !== -1 ? 'https://dev.www.aplayz.co.kr/media/upload' : 'https://www.aplayz.co.kr/media/upload';

          this.total = res.data.result.length;
          this.brandList = res.data.result;

          // * 브랜드 이미지 경로 수정
          for (let i in this.brandList) {
            if (this.brandList[i].brandImg != null) {
              this.brandList[i].brandImg = imgPath + this.brandList[i].brandImg.substring(12);
            }
          }

          // * 브랜드가 둘 이상일 경우에만 이전, 이후 버튼 노출
          if (this.total > 1) {
            $('.swiper-button-next').show();
            $('.swiper-button-prev').show();
          } else {
            $('.swiper-button-next').hide();
            $('.swiper-button-prev').hide();
          }

          // * 브랜드 아이디 세팅
          this.brandId = VueCookies.get('brandId');
          if (this.brandId == null) {
            this.brandId = this.brandList[0].brandId;
            VueCookies.set('brandId', this.brandId);
          }

          // * 브랜드가 하나라도 있을 경우에만 실행
          if (this.brandList != null && this.brandList.length > 0) this.getBrandInfoMusic();
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * *  슬라이드 이전/다음
     */
    moveSlide () {
      setTimeout(this.selectSpace, 500);
    },

    /**
     * * 브랜드 변경
     */
    selectSpace () {
      // * 현재 슬라이드의 브랜드 id 가져오기
      this.brandId = document.querySelector('.swiper-slide-active').querySelector('input').value;
      VueCookies.set('brandId', this.brandId);

      // * 현재 슬라이드의 페이지 번호 가져오기
      let slideNum = this.$refs.mySwiper.$swiper.activeIndex;
      if (this.brandList.length < slideNum) { //리스트의 길이보다 길면 숫자 1
        slideNum = 1;
      } else if (this.brandList.length === slideNum) { //리스트의 길이와 같으면 숫자 0
        slideNum = 0;
      }

      // * 슬라이드 페이지 번호 쿠키 저장
      VueCookies.set('slideNum', slideNum);

      const brandNm = document.querySelector('.swiper-slide-active').querySelector('.brand_name').textContent;
      this.$store.commit("setBrandNm", brandNm);

      // * 메인 페이지 정보 불러오기
      this.getBrandInfoMusic();
    },

    /**
     * * 브랜드 정보 불러오기
     * * 브랜드명, 연결 매장의 수, 연결 요청 매장의 수, 브랜드 음원 목록
     */
    getBrandInfoMusic () {
      const brandId = this.brandId;
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/brand/getBrandInfo', { brandId }, { headers })
        .then(res => {
          this.brandInfo = res.data.result;
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * * 브랜드 등록 후 목록 재호출
     */
    getList () {
      this.popupStatus = false;
      this.getBrandList();
    },

    /**
     * * 연결 매장 페이지로 이동
     */
    shopLink () {
      router.push({ name: 'brand/shopManagement' });
    },

    /**
     * * 연결 요청 매장 페이지로 이동
     */
    requestLink () {
      router.push({ name: 'brand/requestManagemant' });
    },

    /**
     * * 브랜드 음원 관리 페이지로 이동
     */
    musicLink () {
      router.push({ name: 'brand/musicManagement' });
    }
  }
};
</script>
<style scoped>
.swiper-pagination { top: 93%; width: 100%; }
.brand_list ul li { display: flex; align-items: center; }
.brand_list ul li img { margin-right: 5px; border-radius: 50px; }
.brand_group_card:nth-child(1):hover { background: #FD4E6D; }
.brand_group_card:nth-child(2):hover { background: #FD4E6D; }
.swiper-slide { width: 100%; }
.swiper-button-prev { width: 80px !important; height: 80px !important; top: 45% !important; left: -40px !important; background: url('/media/img/swiper_prev_btn.png') no-repeat; }
.swiper-button-next { width: 80px !important; height: 80px !important; top: 45% !important; right: -40px !important; background: url('/media/img/swiper_next_btn.png') no-repeat; }
.swiper .swiper-slide img { width: 198px; height: 198px; border-radius: 8px; }
</style>
<style src="@/assets/css/home.css"></style>
<style src="@/assets/css/content.css"></style>
<style src="@/assets/css/font.css"></style>
