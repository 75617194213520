<template>
  <div class="wrap">
    <LoginHeader />
      <div>
        <router-view></router-view>
      </div>
  </div>
</template>
<script>
import LoginHeader from '@/layout/header/LoginHeader.vue'

export default {
  components: {
    LoginHeader,
  }
}
</script>
