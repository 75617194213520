<template>
  <div class="cs_content">
    <div class="policy_title policy_title1">
      <h2>이용약관</h2>
    </div>
    <div class="cs_content_tab">
      <div class="select_box select_box2">
        <div class="select-box-area">
          <div class="label select-box" @mouseover.once="clickSelectBox">
            {{ serviceTitle }}
          </div>
          <div class="select-box-dropDown policy_li">
            <ul class="optionList">
              <li class="optionItem" @click="changeService('TermsOfService')"><p>사이트 이용약관</p><img src="/media/img/space_check_none.png"></li>
              <!-- <li class="optionItem" @click="changeService('LocationInfoService')"><p>위치정보사업 이용약관</p><img src="/media/img/space_check_none.png"></li>
              <li class="optionItem" @click="changeService('LocationBasedService')"><p>위치기반서비스 이용약관</p><img src="/media/img/space_check_none.png"></li> -->
            </ul>
          </div>
        </div>
        <div class="select-box-area2">
          <div class="label2 select-box2" @mouseover.once="clickSelectBox2">
            {{ serviceDate }}
          </div>
          <div class="select-box-dropDown2">
            <ul class="optionList2">
              <li class="optionItem2" v-for="item in stplatList" :key="item.stplatSeq" @click="getService(item)">
                <p>{{ item.term }}</p>
                <img src="/media/img/space_check_none.png">
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="cs_list_box service" style="color:white;">
      <div v-html="service"></div>
      <!-- {{ serviceList.stplatContents }} -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'service',
  data () {
    return{
      service: null,
      stplatCate: 'TermsOfService',
      selectBoxCheck: false,
      serviceTitle: '사이트 이용약관',
      serviceDate: '2022.05.16 ~ 현재',
      stplatList: []
    };
  },
  async created () {
    await this.getServiceList();
  },
  methods: {
    changeService (item) {
      this.stplatCate = item;
      this.getServiceList();
    },
    /**
     * * 폐기 후 /corp/getStplatList 와 통합 필요
     */
    getService (item) {
      var stplatCate = this.stplatCate;
      var stplatVersion = item.stplatVersion;

      axios
        .post("/api/corp/getStplat", { stplatCate, stplatVersion })
        .then((res) => {
          //console.log(res.data.result);
          var service = res.data.result.stplatContents;
          this.service = service.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getServiceList () {
      var stplatCate = this.stplatCate;

      await axios
        .post("/api/corp/getStplatList", { stplatCate })
        .then((res) => {
          console.log(res.data);
          var result = res.data.result;
          this.stplatList = result;
          this.service = result[0].stplatContents.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
          this.serviceDate = result[0].term;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickSelectBox () {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.serviceTitle = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    clickSelectBox2 () {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label2');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem2');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.serviceDate = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label2') && !e.target.classList.contains('optionItem2')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
}
</script>

<style>
.select-box-area { left: 1px; }
.policy .small_dan { margin-bottom: 3rem; }
.policy .small_dan  p { font-size: 16px; color: rgba(255, 255, 255, 0.60); padding-bottom: 0.5rem; line-height: 1.8rem; }
.policy .small_dan  p.small_h { font-size: 16px; font-weight: 500; color: rgba(255, 255, 255, 0.60); }
.policy .small_dan .tbl_type2 { margin: 20px 0;}
.policy .small_dan .tbl_type2 table tbody tr th {
  height: 40px;
  font-family: "Noto Sans KR";
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.60);
  line-height: 1.5;
  vertical-align: middle;
  padding: 12px 30px;
  background: #151515;
  border-top: 2px solid rgba(255, 255, 255, 0.10);
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  border-right: 1px solid rgba(255, 255, 255, 0.10);
  text-align: center;
}
.policy .small_dan .tbl_type2 table tbody tr th:nth-last-child(1){ border-right: 0px solid rgba(255, 255, 255, 0.10); }

.tbl_type2 table tbody tr td{
  height: 40px;
  font-family: "Noto Sans KR";
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.60);
  line-height: 1.5;
  vertical-align: middle;
  padding: 12px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  border-right: 1px solid rgba(255, 255, 255, 0.10);
  background: #151515;
}

.tbl_type2 table tbody tr td:nth-last-child(1){ border-right: 0px solid rgba(255, 255, 255, 0.10); }
.policy .small_dan ol li{ color: rgba(255, 255, 255, 0.60); font-weight: 300; font-size: 16px; padding:0 0 1rem 1rem; line-height: 1.8rem; }
.policy .cs_list_box h3 { color: #fff; font-size: 20px; font-weight: 500; margin-bottom: 0.5rem; opacity: 0.60; }
</style>