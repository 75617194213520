<template>
  <div>
    <ShopInfo v-if="storeStatus" @closeShopInfo="storeStatus=false" :spaceInfo="spaceInfo"/>
    <CarInfo v-if="carStatus" @closeCarInfo="carStatus=false" :spaceInfo="spaceInfo"/>
    <Disconnect v-if="modalStatus" @closeDisconnect="modalStatus=false" :spaceInfo="spaceInfo" @getList="getList"/>
    <section class="main">
      <div class="group_store">
        <div class="group_store_title">
          <h2>연결 매장</h2>
          <div class="select-box-area" v-if="brandList.length > 1">
            <div class="label select-box" @mouseover.once="clickSelectBox">
              {{ brandName }}
            </div>
            <div class="select-box-dropDown">
              <ul class="optionList">
                <li class="optionItem" v-for="item, i in brandList" :key="i" @click="getShopList(item.brandId)">
                  <p v-html="item.brandNm" />
                </li>
              </ul>
            </div>
          </div>
          <div class="brand_list_box" v-else>
            {{ brandName }}
          </div>
        </div>
        <div class="group_tab">
          <div>
            <span :class="{ on: classCategory1 }" @click="listFilter('All')">전체</span>
            <span :class="{ on: classCategory2 }" @click="listFilter('Y')">구독중</span>
            <span :class="{ on: classCategory3 }" @click="listFilter('N')">미구독</span>
          </div>
          <div>
            <input type="text" placeholder="매장명을 입력하세요." v-model="search" @keyup.enter="listFilter('')">
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z" fill="white"/>
            </svg>
          </div>
        </div>
        <div class="group_list" v-if="itemListData.length > 0">
          <div v-for="(item, i) in listData" :key="i">
            <div class="group_tab_setting" @click="DisconnectModal(item)">
              <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 2C4 0.9 3.1 -1.23266e-08 2 -2.73925e-08C0.899999 -4.24583e-08 -1.07923e-06 0.9 -1.23268e-06 2C-1.38614e-06 3.1 0.899999 4 2 4C3.1 4 4 3.1 4 2ZM4 16C4 14.9 3.1 14 2 14C0.899997 14 -3.03229e-06 14.9 -3.18575e-06 16C-3.3392e-06 17.1 0.899997 18 2 18C3.1 18 4 17.1 4 16ZM4 9C4 7.9 3.1 7 2 7C0.899998 7 -2.05576e-06 7.9 -2.20921e-06 9C-2.36267e-06 10.1 0.899998 11 2 11C3.1 11 4 10.1 4 9Z" fill="white" fill-opacity="0.6"/>
              </svg>
            </div>
            <div class="group_content" @click="openShopInfo(item)">
              <div class="list_left">
                <div class="shop_thumbnail">
                  <img v-bind:src="baseImgUrl + item.brandImg.substr(12)" v-if="item.brandImg != null" alt="" />
                  <img src="/media/img/car.png" v-else-if="item.brandImg == null && item.spaceType === 'Car'" alt="" />
                  <img src="/media/img/cafe.png" v-else-if="item.brandImg === null && item.sector === '카페'" alt="">
                  <img src="/media/img/food.png" v-else-if="item.brandImg === null && item.sector === '음식점'" alt="">
                  <img src="/media/img/drink.png" v-else-if="item.brandImg === null && item.sector === '주점'" alt="">
                  <img src="/media/img/hospital.png" v-else-if="item.brandImg === null && item.sector === '병원'" alt="">
                  <img src="/media/img/Facilities.png" v-else-if="item.brandImg === null && item.sector === '편의시설'" alt="">
                  <img src="/media/img/hotel.png" v-else-if="item.brandImg === null && item.sector === '숙박시설'" alt="">
                  <img src="/media/img/Group 485982.png" v-else-if="item.brandImg === null && item.sector === '기타'" alt="">
                  <img src="/media/img/Group 485982.png" v-else-if="item.brandImg === null && item.sector == null" alt="">
                  <div>
                    <h4>{{ item.spaceNm }} <span v-if="item.spacePayAt === 'Y'">구독중</span><span v-else style="background-color: #151515;">미구독</span></h4>
                    <p>{{ item.spaceAddr }}</p>
                  </div>
                </div>
                <div v-if="item.spaceType === 'Car'">
                  <span v-if="item.carPurpose != null">{{ item.carPurpose[0] }} <span v-if="item.carPurpose.length > 1">외 &nbsp;{{ item.carPurpose.length - 1 }}개</span></span>
                  <span v-if="item.carMood != null">{{ item.carMood[0] }}</span>
                  <span v-if="item.domesticRate != null">국내{{ item.domesticRate }}%</span>
                  <span v-if="item.genre != null">{{ item.genre[0] }} <span v-if="item.genre.length > 1">외 &nbsp;{{ item.genre.length - 1 }}개</span></span>
                  <span v-if="item.period != null">{{ item.period[0] }}년대 <span v-if="item.period.length > 1">외 &nbsp;{{ item.period.length - 1 }}개</span></span>
                </div>
                <div v-else>
                  <span v-if="item.mood != null">{{ item.mood }}</span>
                  <span v-if="item.target != null">{{ item.target[0] }}<span v-if="item.target.length > 1">외 &nbsp;{{ item.target.length - 1 }}개</span></span>
                  <span v-if="item.targetUnit != null">{{ item.targetUnit[0] }}<span v-if="item.targetUnit.length > 1">외 &nbsp;{{ item.targetUnit.length - 1 }}개</span></span>
                  <span v-if="item.carMood != null">{{ item.carMood }}</span>
                  <span v-if="item.carPurpose != null">{{ item.carPurpose[0] }}<span v-if="item.carPurpose.length > 1">외 &nbsp;{{ item.carPurpose.length - 1 }}개</span></span>
                  <span v-if="item.domesticRate != null">국내{{ item.domesticRate }}%</span>
                  <span v-if="item.genre != null">{{ item.genre[0] }}<span v-if="item.genre.length > 1">외 &nbsp;{{ item.genre.length - 1 }}개</span></span>
                  <span v-if="item.period != null">{{ item.period[0] }}년대 <span v-if="item.period.length > 1">외 &nbsp;{{ item.period.length - 1 }}개</span></span>
                </div>
              </div>
              <!-- <div class="list_right">
                <div v-if="item.albumImg"><img :src="imgUrl + item.albumImg" alt=""></div>
                <div class="slide_txt"><p>{{ item.artist }}<br><span>{{ item.musicNm }}</span></p></div>
              </div> -->
            </div>
          </div>
        </div>
        <div v-else>
          <div class="empty_area">
            <div>
              <img src="/media/img/empty_brand_icon.svg" alt="" />
              <p>관리중인 매장이 존재하지 않습니다.</p>
            </div>
          </div>
        </div>
        <Pagination
          :pageSetting="pageDataSetting(total, limit, block, this.page)"
          @paging="pagingMethod"
        />
      </div>
    </section>
  </div>
</template>

<script>
import Disconnect from "@/components/modal/ShopManagement/Disconnect.vue";
import ShopInfo from "@/components/modal/ShopManagement/ShopInfo.vue";
import Pagination from "@/components/modal/pagination/Pagination.vue";
import CarInfo from "@/components/modal/ShopManagement/CarInfo.vue";
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  components: {
    CarInfo,
    ShopInfo,
    Disconnect,
    Pagination
  },
  data () {
    return {
      baseImgUrl: "",
      imgUrl: "https://dev.www.aplayz.co.kr/media/album/aplayz.cover/",
      storeStatus: false,
      modalStatus: false,
      carStatus: false,
      itemList: [],
      spaceInfo: {},
      patAtStatus: '',
      search: '',
      itemListData: [],
      filterList: [],
      category: 'All',
      classCategory1: true,
      classCategory2: false,
      classCategory3: false,
      brandName: '',
      brandList: [],
      playingMusicInfoInterval: null,

      //pagination
      listData: [],
      total: 0,
      page: 1,
      limit: 10,
      block: 10
    };
  },

  async created () {
    await this.getShopList();
    this.getBrandList();
    this.getBrandName();
  },

  mounted () {
    const url = document.location.href;
    this.baseImgUrl = url.indexOf('local') !== -1 || url.indexOf('dev') !== -1 ? 'https://dev.www.aplayz.co.kr/media/upload' : 'https://www.aplayz.co.kr/media/upload';
    this.imgUrl = url.indexOf('local') !== -1 || url.indexOf('dev') !== -1 ? 'https://dev.www.aplayz.co.kr/media/album/aplayz.cover/' : 'https://www.aplayz.co.kr/media/album/aplayz.cover/';
    // this.playingMusicInfoInterval = setInterval(() => {
    //   this.setMusicInfoList();
    // }, 1000 * 10);
  },

  beforeDestroy () {
    clearInterval(this.playingMusicInfoInterval);
  },

  methods: {
    /**
     * * 연결 스페이스 목록 불러오기
     */
    async getShopList (id) {
      if (id != null) {
        // * 브랜드를 변경할 경우
        VueCookies.set('brandId', id);
        // * 슬라이드 번호 넣기
        const index = this.brandList.findIndex((el) => el.brandId === id);
        let slideNum = 0;
        index === this.brandList.length - 1 ? slideNum = 0 : slideNum = index + 1;
        VueCookies.set('slideNum', slideNum);
      }

      const brandId = VueCookies.get('brandId');
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      await axios
        .post('/api/space/selectSpaceList', { brandId }, { headers })
        .then(res => {
          this.itemList = res.data.result;
          this.itemListData = res.data.result;
          this.total = res.data.result.length;

          this.classCategory1 = true;
          this.classCategory2 = false;
          this.classCategory3 = false;
          this.search = '';

          this.pagingMethod(this.page);
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * * 스페이스 음원 정보 메소드 호출
     * * 스페이스의 수만큼 반복
     */
    setMusicInfoList () { //스페이스 리스트만큼 음원정보 가져오는 메소드 반복
      for (var i in this.itemListData) {
        if (this.itemListData[i].spacePayAt === 'Y') {
          this.getPlayingMusicInfo(this.itemListData[i].spaceId, i);
        }
      }
    },

    /**
     * * 스페이스 음원 정보 불러오기
     */
    getPlayingMusicInfo (spaceId, i) { //스페이스의 재생중인 음원정보 가져오기
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/space/selectSpaceCurrentPlayMusic", { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (result) {
              this.itemListData[i].artist = result.artist;
              this.itemListData[i].musicNm = result.musicNm;
              this.itemListData[i].albumImg = result.albumImg;
              this.itemListData.push();
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * * 스페이스 검색
     */
    listFilter (category) { //검색
      this.filterList = this.itemListData.filter((space) => {
        return (space.spaceNm.includes(this.search));
      });
      if (category !== '') {
        this.category = category;
      }
      this.classAdd(this.category);
    },

    /**
     * * 카테고리 변경
     */
    classAdd (category) {
      if (category === 'All') {
        this.classCategory1 = true;
        this.classCategory2 = false;
        this.classCategory3 = false;
      } else if (category === 'Y') {
        this.classCategory1 = false;
        this.classCategory2 = true;
        this.classCategory3 = false;
      } else {
        this.classCategory1 = false;
        this.classCategory2 = false;
        this.classCategory3 = true;
      }
      this.categoryFilter(category);
    },

    /**
     * * 스페이스 목록 필터링
     */
    categoryFilter (category) { //카테고리 분류
      if ( category === 'Y') {
        this.itemList = this.filterList.filter((space) => {
        return (space.spacePayAt != null && space.spacePayAt.includes(category))
      });
      } else if ( category === 'All') {
        this.itemList = this.filterList;
      } else {
        this.itemList = this.filterList.filter((space) => {
        return (space.spacePayAt == null || space.spacePayAt.includes(category))
      });
    }
      this.pagingMethod(this.page);
    },

    /**
     * * 브랜드 리스트 불러오기
     */
    getBrandList () {
      const corpId = VueCookies.get("corpId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/brand/getBrandList', { corpId }, { headers })
        .then(res => {
          //console.log(res.data.result);
          this.brandList = res.data.result;
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * * 현재 브랜드명 불러오기
     */
    getBrandName () {
      const brandId = VueCookies.get('brandId');
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      //매장 카운트 / 음악 리스트 selelct
      axios
        .post('/api/brand/getBrandInfo', { brandId }, { headers })
        .then(res => {
          this.brandName = res.data.result.brandNm;
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * * 스페이스 상세 팝업 열기
     */
    openShopInfo(item){
      this.spaceInfo = item;
      if (this.spaceInfo.spaceType === 'Car') {
        this.carStatus = true;
      } else {
        this.storeStatus = true;
      }
    },

    /**
     * * 연결 해제 팝업 열기
     */
    DisconnectModal(item){
      this.spaceInfo = item;
      this.modalStatus = true;
    },

    /**
     * * 스페이스 연결 해재 후 목록 재호출
     */
    getList () {
      this.getShopList();
    },

    /**
     * * 페이징
     */
    pagingMethod (page) {
      this.listData = this.itemList.slice(
        (page - 1) * this.limit,
        page * this.limit
      );
      this.page = page;
      this.pageDataSetting(this.total, this.limit, this.block, page);
    },

    pageDataSetting (total, limit, block, page) {
      var totalPage = Math.ceil(total / limit);
      var currentPage = page;
      var first =
        currentPage > 1 ? parseInt(currentPage, 10) - parseInt(1, 10) : null;
      var end =
        totalPage !== currentPage
        ? parseInt(currentPage, 10) + parseInt(1, 10)
        : null;
      var startIndex = (Math.ceil(currentPage / block) - 1) * block + 1;
      var endIndex =
        startIndex + block > totalPage ? totalPage : startIndex + block - 1;
      var list = [];

      for (var index = startIndex; index <= endIndex; index++) {
        list.push(index);
      }

      return { first, end, list, currentPage };
    },

    /**
     * * 셀렉트 박스 클릭 이벤트
     */
    clickSelectBox () {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.brandName = item.textContent;
        this.$store.commit("setBrandNm", this.brandName);
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
};
</script>


<style scoped>
.shop_thumbnail img { width: 60px; height: 60px; border-radius: 8px; }
.list_right span span { background: rgba(255, 255, 255, 0); padding: 0; }
.label.select-box { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
.empty_area { display: flex; flex-direction: column; align-items: center; float: inherit !important; padding: 120px 0; border-bottom: none !important; }
.empty_area p { font-size: 16px; margin-top: 16px; }
</style>
<style src="@/assets/css/shopManagement.css"></style>
<style src="@/assets/css/content.css"></style>
<style src="@/assets/css/font.css"></style>

