<template>
    <div>
        <header class="header">
            <div class="header_box">
                <div class="logo">
                    <router-link to="/brand/home">
                        <img src="/media/img/logo.svg" alt="">
                    </router-link>
                </div>
            </div>
        </header>
    </div>
</template>
<script>

export default {
    name: 'loginHeader',
    data(){
        return{
        }
    },
    components: {

    },
    methods: {

    }
}
</script>

<style src="@/assets/css/content.css"></style>
<style src="@/assets/css/font.css"></style>
