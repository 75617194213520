import { render, staticRenderFns } from "./PartnershipInquiry.vue?vue&type=template&id=0aafff41&scoped=true&"
import script from "./PartnershipInquiry.vue?vue&type=script&lang=js&"
export * from "./PartnershipInquiry.vue?vue&type=script&lang=js&"
import style0 from "./PartnershipInquiry.vue?vue&type=style&index=0&id=0aafff41&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0aafff41",
  null
  
)

export default component.exports