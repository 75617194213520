<template>
  <div class="request_none modal">
    <div class="request_none_box modal_box">
      <div class="request_none_title modal_title">
        <h2>입점신청 거절</h2>
        <div class="modal_close_btn" @click="$emit('closeRefuseModal')">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 7.55012L16.0003 0.549805L18 2.54949L10.9997 9.5498L18 16.5501L16.0003 18.5498L9 11.5495L1.99969 18.5498L0 16.5501L7.00031 9.5498L0 2.54949L1.99969 0.549805L9 7.55012Z" fill="white"/>
          </svg>
        </div>
      </div>
      <p>{{ spaceInfo.spaceNm }}의 입점 신청 거절 사유를 선택해주세요.</p>
      <div class="radio_box">
        <input type="radio" name="target" id="check1" @click="check1">저희 브랜드 지점이 아닌거 같아요.
        <label for="check1"></label>
      </div>
      <div class="radio_box">
        <input type="radio" name="target" id="check2" @click="check2">지점명을 올바르게 수정 후 신청해주세요.
        <label for="check2"></label>
      </div>
      <div class="radio_box">
        <input type="radio" name="target" id="check3" @click="check3">해당 지점은 이미 가입되어 있습니다.
        <label for="check3"></label>
      </div>
      <div class="radio_box">
        <input type="radio" name="target" id="check4" @click="check4">기타
        <label for="check4"></label>
      </div>
      <textarea class="refuse-etc" placeholder="거절 사유를 입력해주세요." v-model="text" disabled></textarea>
      <div>
        <button @click="refuseRequest">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "@/service/axios";
import $ from 'jquery';

export default {
  name: 'refuseRequest',
  props: [
    'spaceInfo'
  ],
  data () {
    return {
      content: '',
      text: '',
      checkStatus: 'N'
    };
  },
  methods: {
    check1 () {
      $('.textzone').attr('disabled', true);
      this.checkStatus = 'N';
      this.content = '저희 브랜드 지점이 아닌거 같아요.';
    },
    check2 () {
      $('.textzone').attr('disabled', true);
      this.checkStatus = 'N';
      this.content = '지점명을 올바르게 수정 후 신청해주세요.';
    },
    check3 () {
      $('.textzone').attr('disabled', true);
      this.checkStatus = 'N';
      this.content = '해당 지점은 이미 가입되어 있습니다.';
    },
    check4 () {
      $('.refuse-etc').attr('disabled', false);
      this.checkStatus = 'Y';
    },
    insertText () {
      this.content = this.text;
    },
    refuseRequest () {
      if(this.checkStatus === 'Y'){
          this.insertText();
      }

      var spaceId = this.spaceInfo.spaceId;
      var refuseContent = this.content;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      if(this.content.length > 0){
        axios
          .post('/api/space/refuseSpaceRequest', { spaceId, refuseContent }, { headers })
          .then(res => {
            if(res.data.result === 1){
              this.$emit('closeRefuseModal');
              this.$emit('getList');
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        alert('거절 사유를 선택해 주세요!');
      }
    }
  }
}
</script>

<style scoped>
    .request_none_box button{
        margin: 0 auto;
        margin-top: 1.5em;
    }
</style>