<template>
  <div class="ad_music_alert alert">
    <div class="ad_music_alert_box alert_box">
      <h3>해당 브랜드 음원을 삭제하시겠습니까?</h3>
      <div class="ad_music_alert_btn">
        <p @click="$emit('closeDeleteModal')">아니오</p>
        <button class="delete-music" @click="deleteMusic">네</button>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from 'axios'

export default {
  name: 'deleteMusic',

  props: {
    brandMusicId: String,
    allUseAt: Boolean
  },

  methods: {
    /**
     * * 브랜드 음원 삭제
     */
    deleteMusic () {
      const formData = new FormData();
      const brandMusicId = this.brandMusicId;
      const allUseAt = this.allUseAt;
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken, 'Content-Type':'multipart/form-data' };

      formData.append('brandMusicId', brandMusicId);
      formData.append('allUseAt', allUseAt);

      axios
        .post('/api/brand/deleteBrandMusic', formData, { headers })
        .then(res => {
          if (res.data.result) {
            this.$emit('deleteMusicModal');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.delete-music {
  width: 50%;
  color: #fff;
  font-size: 18px;
  line-height: 60px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  background: transparent;
  color: red;
}
</style>
<style src="@/assets/css/musicManagement.css"></style>
