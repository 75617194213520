<template>
  <div id="email_none" class="email_modal modal">
    <div class="email_modal_box modal_box">
      <div class="modal_close_btn" @click="$emit('closeModal')">
        <img src="/media/img/close_btn.png" alt="">
      </div>
      <h3>미인증 계정</h3>
      <p>가입하신 메일주소의 메일함에서 인증하기 버튼을 클릭 후
        재 로그인 해주세요.
      </p>
      <button @click="resnedMail">인증 메일 재발송</button>
    </div>
  </div>
</template>

<script>
import axios from "@/service/axios";

export default {
  name: 'resendEmail2',
  props: [
    'toMail'
  ],
  methods: {
    resnedMail(){
      var toMail = this.toMail;
      axios
        .post('/api/corp/getAuthEmail', { toMail })
        .then(() => {
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
</script>

<style scoped>
    .email_modal_box button{
        width: 100%;
    }
</style>
