<template>
  <section class="main notifiacation">
    <div class="main_box">
      <h1>알림함</h1>
      <div class="notifiacation_content" style="padding-bottom: 100px;">
        <ul v-for="(item, i) in topList" :key="'c' + i" @click="movePageDetail(item.commonNoticeId, '')">
          <li>
            <p class="default_txt">공통</p>
            <div>
              <h3>{{ item.commonNoticeTitle }}</h3>
              <p class="notifiacation_content_content" v-html="item.commonNoticeContents"></p>
            </div>
          </li>
          <li><p>{{ dateTime(item.registDate) }}</p></li>
        </ul>
        <template v-for="item, index in noticeList">
          <ul v-if="item.noticeId" :key="index" @click="movePageDetail(item.noticeId, item.noticeCategory)">
            <li>
              <p class="attr_txt">My</p>
              <div>
                <h3>{{ item.noticeTitle }}</h3>
                <p class="notifiacation_content_content" v-html="item.noticeContent"></p>
              </div>
            </li>
            <li><p>{{ dateTime(item.registDate) }}</p></li>
          </ul>
          <ul v-else :key="index" @click="movePageDetail(item.commonNoticeId, '')">
            <li>
              <p class="default_txt">공통</p>
              <div>
                <h3>{{ item.commonNoticeTitle }}</h3>
                <p class="notifiacation_content_content" v-html="item.commonNoticeContents"></p>
              </div>
            </li>
            <li><p>{{ dateTime(item.registDate) }}</p></li>
          </ul>
        </template>
        <infinite-loading v-if="status" @infinite="infiniteHandler" spinner="waveDots">
          <div slot="no-more"></div>
          <div slot="no-results"></div>
          <div slot="error"></div>
        </infinite-loading>
      </div>
    </div>
  </section>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import VueCookies from "vue-cookies";
import axios from "@/service/axios";
import dayjs from "dayjs";

export default {
  name: 'notice',

  components: {
    InfiniteLoading
  },

  data(){
    return{
      topList: [],
      itemList: [],
      noticeList: [],

      //무한 스크롤
      limit: 0,
      offset: 10,
      status: false
    }
  },

  mounted () {
    this.getCommonNoticeList();
    this.getNoticeList();
  },

  methods: {
    /**
     * * 상단 고정 공지사항 목록 불러오기
     */
    getCommonNoticeList () {
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/menu/getTopCommonNoticeList', { headers })
        .then(res => {
          this.topList = res.data.result;

          for (let i in this.topList) {
            this.topList[i].commonNoticeContents = this.topList[i].commonNoticeContents.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * * 공지/알림 목록 불러오기
     * * 상단 고정 공지 제외
     */
    getNoticeList () {
      const noticeTypeDetail = VueCookies.get('corpId');
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/menu/getNoticeList', { noticeTypeDetail }, { headers })
        .then(res => {
          this.itemList = res.data.result;

          for (let i in this.itemList) {
            if (this.itemList[i].noticeId) {
              this.itemList[i].noticeContent = this.itemList[i].noticeContent.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
            } else {
              this.itemList[i].commonNoticeContents = this.itemList[i].commonNoticeContents.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
            }
          }

          this.status = true;
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * * 무한 스크롤 이벤트
     */
    infiniteHandler ($state) {
      if (this.limit >= this.itemList.length) {
        $state.complete();
      } else {
        setTimeout(() => {
          this.noticeList = this.noticeList.concat(this.itemList.slice(this.limit, (this.limit + 10)));
          this.limit += 10;

          if (this.limit >= this.itemList.length) {
            $state.complete();
          } else {
            $state.loaded();
          };
        }, 1000);
      }
    },

    /**
     * * 상세 페이지 이동
     * * 알림의 카테고리가 Question이면 1:1문의 페이지로 이동
     * * 그 외에는 알림 상세 페이지로 이동
     */
    movePageDetail (id, ctg) {
      if (ctg === 'Question') {
        this.$router.push({ name: 'brand/Question' });
      } else {
        this.$router.push({ name: 'brand/notice/message', params: { noticeId: id } });
      }
    },

    /**
     * * 날짜 포맷
     */
    dateTime (value) {
      return dayjs(value).format("YYYY.MM.DD HH:mm:ss");
    }
  }
}
</script>
<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/content.css"></style>
