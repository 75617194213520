<template>
  <div class="common_popup_area">
    <div class="common_popup_box">
      <div class="popup_box_top">
        <h3>탈퇴 하시겠습니까?</h3>
      </div>
      <div class="popup_box_bot">
        <p @click="$emit('closeWithdrawalModal')">아니오</p>
        <p class="confirm_btn" @click="withdrawalBtn">네</p>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  name: 'withdrawalCheck',
  methods: {
    withdrawalBtn () {
      var corpId = VueCookies.get("corpId");
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/corp/withdrawalAt', { corpId }, { headers })
        .then(res => {
          if (res.status === 200) {
            this.$router.push('/brand/login');
          }
        })
        .catch(err => {
          console.log(err);
        });

    }
  }
};
</script>

<style scoped>
.common_popup_area { z-index: 201; }
</style>
<style src="@/assets/css/content.css"></style>
