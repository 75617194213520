<template>
  <div>
    <FindPassModal v-if="modalStatus" @closeFindPassModal="modalStatus=false"/>
    <section class="login_main find_pw">
      <div class="login_main_box">
        <h1>비밀번호 찾기</h1>
        <div class="email_input">
          <div class="email_input_box">
            <input type="text" placeholder="이메일 ID" v-model="email1"> @ <input type="text" v-model="email2" ref="email2">
          </div>
          <div class="select-box-area">
            <div class="label select-box" @mouseover.once="clickSelectBox">
              {{ emailOption }}
            </div>
            <div class="select-box-dropDown">
              <ul>
                <li class="optionItem"><p value="">직접입력</p></li>
                <li class="optionItem"><p value="naver.com">naver.com</p></li>
                <li class="optionItem"><p value="gmail.com">gmail.com</p></li>
                <li class="optionItem"><p value="hanmail.com">hanmail.com</p></li>
                <li class="optionItem"><p value="daum.net">daum.net</p></li>
                <li class="optionItem"><p value="kakao.com">kakao.com</p></li>
                <li class="optionItem"><p value="nate.com">nate.com</p></li>
              <li class="optionItem"><p value="yahoo.com">yahoo.com</p></li>
            </ul>
          </div>
        </div>
        <button class="email_input_btn" @click="checkEmail">인증요청</button>
      </div>
      <p id="fail_id" v-show="emailCheckAlert">존재하지 않는 아이디입니다.</p>
        <div class="certificate_number">
          <input type="text" placeholder="인증번호 4자리 입력" v-model="authCode">
          <p class="authCode_timer">{{ TimerStr }}</p>
        </div>
        <button @click="checkAuthCode">다음</button>
      </div>
    </section>
  </div>
</template>

<script>
import FindPassModal from '@/components/modal/find/FindPassModal.vue';
import axios from "axios";
import $ from 'jquery';

export default {
  components: {
    FindPassModal,
  },

  data () {
    return {
      modalStatus: false,
      email1: '',
      email2: '',
      emailCheckAlert: false,
      authCode: '',
      emailOption: '직접입력',

      /* 타이머 */
      Timer: null,
      TimeCounter: 300,
      TimerStr: "05:00"
    };
  },

  methods: {
    /**
     * * 이메일 인증
     * * 이메일 확인 후 메일 발송
     */
    checkEmail () {
      const toMail = this.email1 + '@' + this.email2;

      if (this.email1.length > 0 && this.email2.length > 0) {

        axios
          .post("/api/corp/getEmailCheck", { toMail })
          .then(res => {
            if(res.data){
              this.modalStatus = true;
              this.emailCheckAlert = false;
              this.startCheckEmail();
            } else {
              this.emailCheckAlert = true;
            }

            $(document).on('click', '.email_input_btn', function(){
              $(this).text('재전송');
            });
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        alert('이메일을 바르게 입력해 주세요!');
        this.email1 = "";
        this.email2 = "";
        document.getElementById("email1").focus();
      }
    },

    /**
     * * 타이머
     * * 이메일 인증이 실행된 후 실행하는 이벤트
     * * 기존에 실행하던 타이머가 있다면 정지 후 5분 타이머 시작
     */
    startCheckEmail () {
      if (this.Timer != null) {
        this.timerStop(this.Timer);
        this.Timer = null;
      }
      this.Timer = this.timerStart();
    },

    /**
     * * 타이머 시작
     * * 1초에 한번씩 인터벌 실행
     */
    timerStart () {
      this.TimeCounter = 300;
      const interval = setInterval( () => {
        this.TimeCounter--;
        this.TimerStr = this.prettyTime();

        if (this.TimeCounter <= 0) {
          this.timerStop(interval);
        }
      }, 1000);
      return interval;
    },

    /**
     * * 시간 변경 이벤트
     */
    prettyTime () {
      const time = this.TimeCounter / 60;
      const minutes = parseInt(time);
      const secondes = Math.round((time - minutes) * 60);

      return (
        minutes.toString().padStart(2, "0") + ":" + secondes.toString().padStart(2, "0")
      );
    },

    /**
     * * 타이머 종료
     * * 인터벌 제거
     */
    timerStop (Timer) {
      clearInterval(Timer);
      this.TimeCounter = 0;
    },

    /**
     * * 인증번호 확인
     * * 인증번화 확인 완료 시 비밀번호 변경 페이지로 이동
     */
    checkAuthCode () {
      const authCode = this.authCode;
      const toMail = this.email1 + "@" + this.email2;

      if (this.email1.length > 0 && this.email2.length > 0){
        if (authCode.length > 0) {
          axios
            .post("/api/corp/checkPassEmail", { toMail, authCode })
            .then(res => {
              if(res.data.result){
                  this.$router.push({name: 'brand/changePassword', params: {email : toMail}});
              } else {
                alert('인증번호가 틀렸습니다!')
              }
            })
            .catch(err => {
              console.log(err);
            });
            } else {
              alert('인증번호를 입력해주세요!');
            }
        } else {
          alert('이메일을 바르게 입력해 주세요!');
        }
    },

    /**
     * * 셀렉트 박스 이벤트
     */
    clickSelectBox () {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        if (item.textContent != '직접입력') {
            this.email2 = item.textContent;
            this.emailOption = item.textContent;
        } else {
            this.email2 = '';
            this.emailOption = item.textContent;
        }

        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
}
</script>

<style scoped>
.certificate_number .authCode_timer { color: red; position: absolute; top: 25%; right: 3%; }
</style>