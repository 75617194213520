<template>
  <div class="alert_area">
    <div class="alert_item">
      <div class="box_top">
        <h3>{{ alertPopupMsg }}</h3>
      </div>
      <div class="box_bot">
        <span class="confirm" @click="$emit('closePopup')">확인</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    alertPopupMsg: String
  }
}
</script>

<style>
.alert_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.alert_area .alert_item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 450px;
  background: linear-gradient(0deg,hsla(0,0%,100%,.08),hsla(0,0%,100%,.08)),#151515;
  border-radius: 16px;
}

.alert_area .alert_item .box_top {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 32px 0 26px;
  border-bottom: 1px solid hsla(0,0%,100%,.08);
}

.alert_area .alert_item .box_top h3 {
  color: hsla(0,0%,100%,.87);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.6;
}

.alert_area .alert_item .box_bot {
  width: 100%;
  height: 60px;
}

.alert_area .alert_item .box_bot span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  line-height: 60px;
  font-weight: 400;
  cursor: pointer;
}

.alert_area .alert_item .box_bot .confirm {
  color: #ff2d55;
}
</style>
<style src="@/assets/css/musicManagement.css"></style>