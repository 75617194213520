<template>
  <section class="music_area" id="idd" @click="closeBox">
    <div class="group_store">
      <div class="group_store_title">
        <h2>브랜드 음원</h2>
        <div class="d-flex">
          <div class="select-box-area" v-if="brandList.length > 1">
            <!-- <span></span> -->
            <div class="label select-box" @mouseover="clickSelectBox">
              {{ brandName }}
            </div>
            <div class="select-box-dropDown">
              <ul class="optionList">
                <li class="optionItem" v-for="(item, i) in brandList" :key="i" @click="getBrandMusic(item.brandId)">
                  <p v-html="item.brandNm" />
                </li>
              </ul>
            </div>
          </div>
          <div class="brand_list_box" v-else>
            {{ brandName }}
          </div>

          <div class="add_music" @click="inserStatus = true">
            <img src="/media/img/space_upload.png" alt="" />
            <p>음원 추가</p>
          </div>
        </div>
      </div>
      <div class="group_tab">
        <div>
          <p>
            총 <span class="style-pointer-none">{{ MusicList.length }}</span
            >개
          </p>
        </div>
        <div>
          <input type="text" placeholder="검색어를 입력해주세요." v-model="search" @keyup.enter="searchMusic()" />
          <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" @click="searchMusic()">
            <path
              d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
              fill="white" />
          </svg>
        </div>
      </div>
      <div class="brand_music_box">
        <div class="table_top">
          <table class="brand_table" style="margin-bottom: 0px">
            <colgroup>
              <col width="35%" />
              <col width="9%" />
              <col width="15%" />
              <col width="25%" />
              <col width="10%" />
              <col width="6%" />
            </colgroup>
            <thead>
              <tr>
                <th>
                  <p>브랜드 음원명</p>
                </th>
                <th>
                  <p>듣기</p>
                </th>
                <th>
                  <div class="info_header">
                    일괄 적용
                    <img src="/media/img/info_FILL1_wght400_GRAD0_opsz48 1.svg" alt="" @mouseover="infoStatus = true" @mouseleave="infoStatus = false" />
                    <div class="info_point" v-if="infoStatus">
                      <div><!-- 스타일 적용중 --></div>
                    </div>
                    <div class="info_box" v-if="infoStatus">
                      <p>'일괄 적용' 시 관리자가 설정하신 시간에 맞춰</p>
                      <p>브랜드와 연결된 모든 스페이스에서 음원이 재생됩니다.</p>
                    </div>
                  </div>
                </th>
                <th>
                  <p>시간 설정</p>
                </th>
                <th>
                  <p>배포 여부</p>
                </th>
                <th></th>
              </tr>
            </thead>
          </table>
        </div>
        <div class="scroll_body">
          <table class="brand_table">
            <tbody v-if="MusicList.length > 0">
              <tr v-for="(item, index) in MusicList" :key="index">
                <td @click="updateBtn(item)" style="cursor: pointer">
                  <p v-html="item.brandMusicNm" />
                </td>
                <td>
                  <div>
                    <img v-bind:id="'img' + index" src="/media/img/Btn_Play.svg" style="width: 16px; height: 20px" alt="" @click="onPlay(item.brandMusicId, $event)" />
                    <div class="sound_updown" style="display: none">
                      <span></span>
                      <span v-bind:style="{ width: $store.state.playNav + '%' }"></span>
                      <input v-bind:id="'seekslider' + index" type="range" min="0" max="100" step="1" value="0" />
                      <video v-bind:id="'video' + index" width="100%" height="640" hidden controls></video>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="all_check_btn">
                    <label for="chk" style="height: 24px">
                      <input id="chk" type="checkbox" :value="item.allUseAt" />
                      <span class="check" :class="{ on: item.allUseAt }" @click="changeStatus(item)"></span>
                    </label>
                  </div>
                </td>
                <td :class="item.playType != null ? 'on_edit_' + index : ''" @mousemove="showEdit(item.playType, index)" @mouseleave="hideEdit(item.playType, index)">
                  <div class="time_setting_btn" v-if="item.playType == null">
                    <div @click="openPopup(item)" v-if="item.allUseAt">
                      <p>설정하기</p>
                    </div>
                  </div>
                  <div class="time_area" v-else @click="openPopup(item)">
                    <div class="select" v-if="item.playType === 'S'">
                      <p>지정</p>
                    </div>
                    <div class="repeat" v-else>
                      <p>반복</p>
                    </div>
                    <div v-if="item.playType != null">
                      <p v-if="item.playType === 'S'">{{ item.selectTime }}</p>
                      <p v-else>{{ item.startTime }}부터~{{ item.endTime }}까지</p>
                    </div>
                    <div v-if="item.playType === 'R'">
                      <p>{{ item.repeatTime }}시간 마다</p>
                    </div>
                    <div class="edit_img" :class="item.playType != null ? 'edit_img_' + index : ''">
                      <img src="/media/img/icon_update.svg" alt="" style="background: rgba(255, 255, 255, 0.04)" />
                    </div>
                  </div>
                </td>
                <td>
                  <div class="alarm_box">
                    <span :class="{ active1: item.useAt === 'Y' }" @click="updateBrandMusic(item)"></span>
                    <span class="move" :class="{ active1_1: item.useAt === 'Y' }" @click="updateBrandMusic(item)"></span>
                  </div>
                </td>
                <td>
                  <div :class="MusicList.length === 1 ? 'single_line' : ''">
                    <img src="/media/img/setting_icon2.svg" @click="openBox(index)" />
                    <div class="delete_box" :class="'box_' + index" style="display: none" @click="deleteMusic(item)">
                      <img src="/media/img/delet_btn.png" alt="" />
                      <p>삭제</p>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td calspan="6" class="empty_area_2">
                  <div>
                    <img src="/media/img/music_empty.svg" />
                    <p>등록된 음원이 없습니다.</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <InsertMusic v-if="inserStatus" :brandList="brandList" :name="brandName" @closeAddModal="inserStatus = false" @getList="getList" />
    <UpdateMusic v-if="updateStatus" :musicInfo="musicInfo" :brandList="brandList" :name="brandName" @closeUpdateModal="updateStatus = false" @getList="getList" />
    <DeleteMusic v-if="deleteStatus" :brandMusicId="brandMusicId" :allUseAt="allUseAt" @closeDeleteModal="deleteStatus = false" @deleteMusicModal="getList" />

    <Integrate v-if="popupStatus" :timeInfo="timeInfo" @closePopup="popupStatus = false" @getList="getList" />
    <!-- <div id="alert_area" /> -->

    <div class="alert_message" v-show="alertMessageStatus">
      <p>{{ alertMessage }}</p>
    </div>

    <Loading v-if="loadingStatus" />
    <Alert v-if="popupStatus3" :alertPopupMsg="alertPopupMsg" @closePopup="popupStatus3 = false"></Alert>
  </section>
</template>

<script>
import InsertMusic from '@/components/modal/MusicManagement/InsertMusic.vue';
import UpdateMusic from '@/components/modal/MusicManagement/UpdateMusic.vue';
import DeleteMusic from '@/components/modal/MusicManagement/DeleteMusic.vue';
import Integrate from '@/components/modal/time/Integrate.vue';
import VueCookies from 'vue-cookies';
import axios from '@/service/axios';
import Hls from 'hls.js';
import $ from 'jquery';

import Loading from '@/components/modal/common/Loading.vue';
import Alert from '@/components/modal/MusicManagement/AlertMsg.vue';

export default {
  components: {
    InsertMusic,
    UpdateMusic,
    DeleteMusic,
    Integrate,
    Loading,
    Alert,
  },

  data() {
    return {
      MusicList: [],
      //초기화용
      musicListData: [],

      //검색어
      search: '',

      //체크박스
      checkboxStatus: false,

      checked: false,
      labelVal: '',
      brandList: [],
      brandName: '',

      //일괄 적용 설명
      infoStatus: false,

      //팝업 모달
      inserStatus: false,
      updateStatus: false,
      deleteStatus: false,
      musicInfo: {},

      //시간 선택 팝업
      popupStatus: false,
      popupStatus2: false,
      selectTime: null,
      timeInterval: null,

      setTime: '09:00',
      setPeriod: '1시간마다',
      valueType: null,

      //삭제
      brandMusicId: '',
      allUseAt: null,

      //미리듣기
      playUrl: null,

      //일괄 적용 음원 수
      musicCnt: 0,

      //배포 얼럿 카운트
      alertNum: 0,

      //삭제 박스
      beforeIdx: null,

      timeInfo: {},

      alertMessageStatus: false,
      timeOutStatus: null,
      alertMessage: '',

      //로딩
      loadingStatus: false,

      //alert
      popupStatus3: false,
      alertPopupMsg: '',
    };
  },

  mounted() {
    this.getBrandMusic();
    this.getBrandList();
  },

  methods: {
    /**
     * * 브랜드 음원 목록 불러오기
     */
    getBrandMusic(id) {
      if (id != null) {
        // * 선택 브랜드를 변경할 경우
        VueCookies.set('brandId', id);

        // * 슬라이드 번호 넣기
        const index = this.brandList.findIndex(el => el.brandId === id);
        let slideNum = 0;
        index === this.brandList.length - 1 ? (slideNum = 0) : (slideNum = index + 1);
        VueCookies.set('slideNum', slideNum);
      }

      const corpId = VueCookies.get('corpId');
      const brandId = VueCookies.get('brandId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/brand/getBrandMusicList', { corpId, brandId }, { headers })
        .then(res => {
          this.musicListData = res.data.result;
          this.musicCnt = 0;
          for (var i in this.musicListData) {
            if (this.musicListData[i].brandMusicId != null) {
              this.musicListData[i].brandMusicId = '/stream/getMusic?' + this.musicListData[i].brandMusicId;

              if (this.musicListData[i].useAt === 'Y') {
                this.musicCnt++;
              }
            }
          }

          this.MusicList = this.musicListData;
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * * 브랜드 목록 불러오기
     */
    getBrandList() {
      const corpId = VueCookies.get('corpId');
      const brandId = VueCookies.get('brandId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/brand/getBrandList', { corpId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.brandList = res.data.result;

            if (this.brandList != null) {
              for (let i in this.brandList) {
                if (this.brandList[i].brandId === brandId) {
                  this.brandName = this.brandList[i].brandNm;
                }
              }
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * * 시간 설정 마우스오버 이벤트
     * * 시간 설정을 한 브랜드 음원에만 적용
     */
    showEdit(type, idx) {
      if (type == null) return false;
      document.getElementsByClassName('on_edit_' + idx)[0].style.background = 'rgba(255, 255, 255, 0.04)';
      document.getElementsByClassName('edit_img_' + idx)[0].style.display = 'block';
    },

    /**
     * * 시간 설정 마우스리브 이벤트
     * * 시간 설정을 한 브랜드 음원에만 적용
     */
    hideEdit(type, idx) {
      if (type == null) return false;
      document.getElementsByClassName('on_edit_' + idx)[0].style.background = '#272727';
      document.getElementsByClassName('edit_img_' + idx)[0].style.display = 'none';
    },

    /**
     * * 일괄 적용 선택/취소
     */
    changeStatus(item) {
      if (!item.allUseAt && item.useAt === 'Y') {
        this.updateBrandMusic(item);
      }
      this.loadingStatus = true;
      const brandMusicId = item.brandMusicId;
      const brandMusicNm = item.brandMusicNm;
      const allUseAt = item.allUseAt;
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/brand/changeBrandMusic', { brandMusicId, brandMusicNm, allUseAt }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.getBrandMusic();
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          setTimeout(() => {
            this.loadingStatus = false;
          }, 1000);
        });
    },

    /**
     * * 브랜드 음원 배포 여부 변경
     */
    updateBrandMusic(item) {
      if (item.useAt === 'N' && this.musicCnt === 15) {
        this.popupStatus3 = true;
        this.alertPopupMsg = '배포 가능한 음원의 수는 최대 15개입니다.';
        return false;
      }

      if (item.allUseAt && item.playType == null) {
        this.popupStatus3 = true;
        this.alertPopupMsg = '일괄 적용하여 배포를 원하는 경우, 시간을 설정해주세요.';
        return false;
      }

      item.useAt === 'Y' ? (item.useAt = 'N') : (item.useAt = 'Y');

      var brandMusicId = item.brandMusicId;
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/brand/updateUseBrandMusic', { brandMusicId }, { headers })
        .then(() => {
          item.useAt === 'Y' ? this.musicCnt++ : this.musicCnt--;

          // * 메세지 지정
          this.alertMessage = item.useAt === 'Y' ? '"' + item.brandMusicNm + '" 음원이 배포되었습니다.' : '"' + item.brandMusicNm + '" 음원의 배포가 중단되었습니다.';

          // * Alert 열기
          this.showAlert();
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * * 브랜드 음원 배포 완료/취소 얼럿
     */
    showAlert() {
      const msg = document.getElementsByClassName('alert_message')[0].classList;

      // * Alert 제거
      if (this.alertMessageStatus) {
        clearTimeout(this.timeOutStatus);
        msg.remove('active');
      }

      // * 3초뒤 사라지는 Alert 생성
      setTimeout(() => {
        this.alertMessageStatus = true;
        msg.add('active');

        this.timeOutStatus = setTimeout(() => {
          this.alertMessageStatus = false;
          msg.remove('active');
        }, 3000);
      }, 10);

      //alert 생성
      // const num = this.alertNum;
      // this.alertNum++;

      //div태그 생성 후 클래스 등록
      // let newDiv = document.createElement('div');

      //div 태그 스타일 적용
      // newDiv.classList.add('alert_' + num);
      // newDiv.style.boxSizing = 'border-box';
      // newDiv.style.inlineSize = 'max-content';
      // newDiv.style.height = '42px';
      // newDiv.style.padding = '0 16px';
      // newDiv.style.display = 'flex';
      // newDiv.style.alignItems = 'center';
      // newDiv.style.justifyContent = 'center';
      // newDiv.style.background = '#EEEEEE';
      // newDiv.style.border = '1px solid #EEEEEE';
      // newDiv.style.borderRadius = '100px';
      // newDiv.style.marginTop = '16px';
      // newDiv.style.animation = 'fadeout 5s';

      //p태그 생성
      // let newP = document.createElement('span');

      //p태그 스타일 적용
      // newP.style.fontStyle = 'normal';
      // newP.style.fontWeight = '500';
      // newP.style.fontSize = '16px';
      // newP.style.lineHeight = '23px';
      // newP.style.etterSpacing = '-0.5px';
      // newP.style.color = '#000000';
      // newP.style.opacity = '0.8';

      //p태그에 내용 넣기
      // let txt =  item.useAt === 'Y' ? '"' + item.brandMusicNm + '" 음원이 배포되었습니다.' : '"' + item.brandMusicNm + '" 음원의 배포가 중단되었습니다.';
      // let newContent = document.createTextNode(txt);
      // newP.appendChild(newContent);

      //div태그에 p태그 넣기
      // newDiv.appendChild(newP);

      //화면에 노출
      // document.getElementById('alert_area').appendChild(newDiv);

      //3초 후 div태그 삭제
      // setTimeout(() => {
      //   document.getElementsByClassName('alert_' + num)[0].remove();
      // }, 3000);
    },

    /**
     * * 브랜드 음원 검색(브랜드 음원명)
     */
    searchMusic() {
      this.MusicList = this.musicListData.filter(music => {
        return music.brandMusicNm.includes(this.search);
      });
    },

    /**
     * * 브랜드 음원 수정 팝업 열기
     */
    updateBtn(item) {
      this.musicInfo = item;
      this.updateStatus = true;
    },

    /**
     * * 시간 설정 팝업 열기
     */
    openPopup(item) {
      this.timeInfo = item;
      this.popupStatus = true;
    },

    /**
     * * 팝업 박스 열기
     * * 열려있던 팝업 박스가 있다면 닫은 후 열기
     */
    openBox(idx) {
      if (this.beforeIdx === idx) return false;
      setTimeout(() => {
        if (this.beforeIdx != null) {
          if (this.beforeIdx !== idx) {
            document.getElementsByClassName('box_' + this.beforeIdx)[0].style.display = 'none';
            document.getElementsByClassName('box_' + idx)[0].style.display = 'flex';
            this.beforeIdx = idx;
          }
        } else {
          document.getElementsByClassName('box_' + idx)[0].style.display = 'flex';
          this.beforeIdx = idx;
        }
      }, 200);
    },

    /**
     * * 팝업 박스 닫기
     */
    closeBox() {
      setTimeout(() => {
        if (this.beforeIdx != null) {
          document.getElementsByClassName('box_' + this.beforeIdx)[0].style.display = 'none';
          this.beforeIdx = null;
        }
      }, 100);
    },

    /**
     * * 브랜드 음원 삭제
     */
    deleteMusic(item) {
      this.brandMusicId = item.brandMusicId;
      this.allUseAt = item.allUseAt;
      this.deleteStatus = true;
    },

    /**
     * * 브랜드 음원 등록, 수정, 삭제, 설정 완료 후 목록 재호출
     */
    getList() {
      this.inserStatus = false;
      this.updateStatus = false;
      this.deleteStatus = false;
      this.popupStatus = false;
      this.getBrandMusic(null);
    },

    /**
     * * 셀렉트 박스 클릭 이벤트
     */
    clickSelectBox() {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach(opt => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach(opt => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.brandName = item.textContent;
        this.$store.commit('setBrandNm', item.textContent);
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },

    /**
     * * 음원 미리듣기
     */
    onPlay(url, e) {
      var videoId = $(e.currentTarget.parentElement).find('video')[0].id;
      var video = document.getElementById(videoId);
      var seek = $(e.currentTarget.parentElement).find('input')[0].id;
      var imgId = e.currentTarget.id;

      if (this.playUrl == null || this.playUrl !== url) {
        this.playerListener(url, seek, videoId, imgId);
      }

      $(e.currentTarget).attr('src', (index, attr) => {
        if (attr.match('_Play')) {
          video.play();
          return attr.replace('_Play.svg', '_Pause.svg');
        } else {
          video.pause();
          return attr.replace('_Pause.svg', '_Play.svg');
        }
      });
    },

    playerListener(url, seek, videoId, imgId) {
      this.playUrl = url;
      //const _store = this.$store;
      const config = {
        //debug: true,
        //startPosition: 150,
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90,
      };

      let hls = null;
      let seekslider = document.getElementById(seek);
      let video = document.getElementById(videoId);

      seekslider.addEventListener('click', vidSeek, false);
      video.addEventListener('timeupdate', seektimeupdate, false);
      video.addEventListener('ended', () => {
        $('#' + imgId).attr('src', (index, attr) => {
          video.pause();
          return attr.replace('_Pause.svg', '_Play.svg');
        });
      });

      function vidSeek() {
        var seekto = video.duration * (seekslider.value / 100);
        video.currentTime = seekto;
      }

      function seektimeupdate() {
        var newtime = video.currentTime * (100 / video.duration);
        seekslider.value = newtime;
      }

      // 최초 음원 목록 재생
      playMusic(url);

      function playMusic(url) {
        const tg = window.location.host.indexOf('localhost') !== -1 ? 'http://13.209.152.125:10084' + url : url;
        // const tg = "http://13.209.152.125:10084" + url;

        if (Hls.isSupported()) {
          if (hls != null) hls.destroy(); // removed all listeners
          hls = new Hls(config);
          hls.loadSource(tg);
          hls.attachMedia(video);
          //addListener(); // add player listener
        } else if (video != null && video.canPlayType('application/vnd.apple.mpegurl')) {
          // iOS인 경우로 따로 대응해주어야 한다.
          let v = document.getElementById(videoId);
          const pa = video.parentElement;

          // programmatic 하게 플레이어를 생성해야 한다.
          pa.removeChild(v);
          v = document.createElement(videoId);
          v.id = 'video';
          v.playsInline = true;

          const s = document.createElement('source');
          s.type = 'application/vnd.apple.mpegurl';

          v.appendChild(s);
          pa.appendChild(v);
          video = v;
          //addListener(); // add video player listener
          v.querySelector('source').src = tg;
          v.play(); // autoplay for next song
        } else {
          //todo - temporary error message -> 나중에 에러를 위한 처리(서버 로깅 등)를 해야 한다.
          alert('Sorry! Music player has an error.');
        }
      }
    },
  },
};
</script>

<style scoped>
.label.select-box {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.empty_area {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: inherit !important;
  padding: 120px 0;
  border-bottom: none !important;
}
.empty_area p {
  font-size: 16px;
  margin-top: 16px;
}
.style-pointer-none {
  pointer-events: none;
}

input[type='checkbox'] {
  display: none;
}
span.check {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('/media/img/login_check_off.png') no-repeat;
  background-size: 100%;
  cursor: pointer;
}
span.check.on {
  background: url('/media/img/login_check_active.png') no-repeat;
  background-size: 100%;
}
</style>
<style src="@/assets/css/musicManagement.css"></style>
<style src="@/assets/css/content.css"></style>
<style src="@/assets/css/font.css"></style>
