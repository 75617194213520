<template>
  <div class="request_m_alert alert">
    <div class="request_m_box alert_box">
      <h3>{{ spaceInfo.spaceNm }}의 입점 신청을 수락하시겠습니까?</h3>
      <div class="request_m_btn">
        <p @click="$emit('closeAcceptModal')">아니오</p>
        <p id="color_txt" @click="acceptRequest">네</p>
      </div>
    </div>
  </div>
</template>
<script>
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  name: 'acceptRequest',
  props: [
    'spaceInfo'
  ],
  methods: {
    acceptRequest () { // 입점요청 수락
      var spaceId = this.spaceInfo.spaceId;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
          .post('/api/space/acceptSpaceRequest', { spaceId }, { headers })
          .then(res => {
            if(res.data.result === 1){
              this.$emit('closeAcceptModal');
              this.$emit('getList');
            }
          })
          .catch(err => {
            console.log(err);
          });
    }
  }
};
</script>