<template>
  <div class="space_information2 modal">
    <div class="space_information_box2 modal_box">
      <div class="space_information_title2 modal_title">
        <h2>스페이스 정보</h2>
        <div class="modal_close_btn" @click="$emit('clostInfoModal')">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 7.55012L16.0003 0.549805L18 2.54949L10.9997 9.5498L18 16.5501L16.0003 18.5498L9 11.5495L1.99969 18.5498L0 16.5501L7.00031 9.5498L0 2.54949L1.99969 0.549805L9 7.55012Z" fill="white"/>
          </svg>
        </div>
      </div>
      <div>
        <label>매장명</label>
        <input type="text" v-model="selectSpaceInfo.spaceNm">
      </div>
      <div>
        <label>매장 주소</label>
        <input type="text" v-model="selectSpaceInfo.spaceAddr">
        <input type="text" v-model="selectSpaceInfo.spaceAddrDetail">
      </div>
      <div>
        <label>연락처</label>
        <input type="text" v-model="selectSpaceInfo.spaceTel">
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  name: 'requestInfo',
  props: [
    'spaceInfo'
  ],
  data () {
    return {
      selectSpaceInfo: {}
    };
  },
  created () {
    var spaceId = this.spaceInfo.spaceId;
    var aToken = VueCookies.get("aToken");
    var headers = { "X-AUTH-TOKEN": aToken };

    axios
      .post("/api/space/selectSpaceInfo", { spaceId }, { headers })
      .then(res => {
        this.selectSpaceInfo = res.data.result[0];
      })
      .catch(err => {
        console.log(err);
      });
  }
}
</script>