<template>
  <div class="common_popup_area">
    <div class="common_popup_box">
      <div class="popup_box_top">
        <h3>휴면 해제 완료</h3>
        <div class="popup_box_content">
          <p>회원님의 계정이 휴면 해제되었습니다.</p>
          <p>어플레이즈의 모든 서비스를 정상적으로 이용할 수 있습니다.</p>
          <p>[확인] 버튼을 클릭하시면 로그인 화면으로 이동합니다.</p>
        </div>
      </div>
      <div class="popup_box_bot">
        <p class="confirm_btn" @click="$emit('closePopup')">확인</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * * 휴면 해제 완료 팝업
 */

export default {
  name: 'conversionComplete'
}
</script>

<style scoped>
.common_popup_area .common_popup_box .popup_box_top .popup_box_content p:first-child { margin-bottom: 8px; }
</style>
<style src="@/assets/css/content.css"></style>
