<template>
  <div class="time_select_area">
    <div class="time_select_box">
      <div class="box_header">
        <h2>시간 설정</h2>
        <div @click="closePopup">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 7.55012L16.0003 0.549805L18 2.54949L10.9997 9.5498L18 16.5501L16.0003 18.5498L9 11.5495L1.99969 18.5498L0 16.5501L7.00031 9.5498L0 2.54949L1.99969 0.549805L9 7.55012Z"
              fill="white" />
          </svg>
        </div>
      </div>
      <div class="box_content">
        <div class="content_item">
          <div class="content_item_title" @click="selectPlayType('S')">
            <input type="radio" name="type" :checked="playType === 'S'" />
            <label>지정 시간</label>
          </div>
          <div>
            <div class="time_box" :class="playType == null || playType === 'S' ? '' : 'input_off'">
              <TimePicker2 :selectTime="this.selectTime" @setTime="setTime" />
            </div>
          </div>
        </div>

        <div class="line_style"></div>

        <div class="content_item">
          <div class="content_item_title" @click="selectPlayType('R')">
            <input type="radio" name="type" :checked="playType === 'R'" />
            <label>반복 주기</label>
          </div>
          <div class="content_sub_item first">
            <label class="content_sub_item_label">운영 시간</label>
          </div>
          <ul class="d-flex time_box d-flex-space-between" :class="playType == null || playType === 'R' ? '' : 'input_off'">
            <li class="time_box_item">
              <TimePicker :startTime="startTime" @setTime="setTime" :isfullround="true" />
            </li>
            <li class="time_box_item">
              <TimePicker :startTime="endTime" @setTime="setEndTime" :isfullround="true" :timetext="'까지'" />
            </li>
          </ul>
          <div class="content_sub_item">
            <label class="content_sub_item_label">주기 </label>
          </div>
          <ul class="d-flex time_box" :class="playType == null || playType === 'R' ? '' : 'input_off'">
            <TimeRepeat :repeatTime="repeatTime" @setTime="setRepeat" :isfullbox="true" />
          </ul>
        </div>
      </div>

      <div class="box_footer">
        <div class="close_btn" @click="closePopup">
          <p>취소</p>
        </div>
        <div class="confirm_btn" @click="updateSettingTime">
          <p>확인</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TimeRepeat from '@/components/modal/common/SelectBox.vue';
import TimePicker from '@/components/modal/time/TimePicker.vue';
import TimePicker2 from '@/components/modal/time/TimePicker2.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default {
  name: 'integrate',

  props: {
    timeInfo: Object,
  },

  components: {
    TimeRepeat,
    TimePicker,
    TimePicker2,
  },

  data() {
    return {
      popupStatus: false,
      popupStatus2: false,

      playType: '',
      selectTime: '09:00',
      startTime: '23:55',
      endTime: '',
      repeatTime: '1',
    };
  },

  created() {
    this.playType = this.timeInfo.playType == null ? this.playType : this.timeInfo.playType;
    this.selectTime = this.timeInfo.selectTime == null ? this.selectTime : this.timeInfo.selectTime;
    this.startTime = this.timeInfo.startTime == null ? this.startTime : this.timeInfo.startTime;
    this.repeatTime = this.timeInfo.repeatTime == null ? this.repeatTime : this.timeInfo.repeatTime;
    this.endTime = this.timeInfo.endTime == null ? this.endTime : this.timeInfo.endTime;
  },

  methods: {
    selectPlayType(val) {
      this.playType = val;
    },

    openTimeSetting() {
      if (this.playType == null) return false;

      this.beforeTime = this.playType === 'S' ? this.selectTime : this.startTime;
      this.popupStatus = true;
    },

    openTimeRepeat() {
      if (this.playType == null) return false;

      this.popupStatus2 = true;
    },

    setTime(val) {
      this.playType === 'S' ? (this.selectTime = val) : (this.startTime = val);
      this.closeModal();
    },
    setEndTime(val) {
      this.endTime = val;
    },

    setRepeat(val) {
      this.repeatTime = val;
      this.closeModal();
    },

    closeModal() {
      this.popupStatus = false;
      this.popupStatus2 = false;
    },

    updateSettingTime() {
      const brandMusicId = this.timeInfo.brandMusicId;
      const playType = this.playType;
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      if (playType === 'S') var selectTime = this.selectTime;
      if (playType === 'R') var startTime = this.startTime;
      if (playType === 'R') var endTime = this.endTime;
      if (playType === 'R') var repeatTime = this.repeatTime;

      axios
        .post('/api/brand/updateCommonBrandMusic', { brandMusicId, playType, selectTime, startTime, endTime, repeatTime }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.$emit('getList');
          }
        })
        .catch(e => {
          console.log(e);
        });
    },

    closePopup() {
      this.$emit('closePopup');
    },
  },
};
</script>

<style scoped>
.time_select_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 55;
}

.time_select_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 540px;
  padding: 24px 24px 16px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #151515;
  border-radius: 20px;
}
.box_header {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 24px;
}
.box_header div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.box_header div:hover {
  background: rgba(255, 255, 255, 0.08);
}
.box_header h2 {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: -1px;
  color: #ffffff;
}
.content_item {
  margin-bottom: 12px;
}
.content_item .content_item_title {
  display: flex;
  align-content: center;
  justify-content: flex-start;
  margin-bottom: 12px;
}
.content_item .content_item_title label {
  cursor: pointer;
}
.content_item .time_box.input_off {
  opacity: 0.3;
  pointer-events: none;
  background: rgba(225, 225, 225, 0.04);
  border: 1px solid rgba(225, 225, 225, 0.04);
}

.time_box_item {
  width: 49.8%;
}

.line_style {
  width: 100%;
  height: 1px;
  margin-bottom: 12px;
  background: hsla(0, 0%, 100%, 0.08);
}

.box_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 16px;
}
.box_footer .close_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 46px;
  margin-right: 4px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s;
}
.box_footer .close_btn:hover {
  background: hsla(0, 0%, 100%, 0.1);
}
.box_footer .close_btn p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: right;
  letter-spacing: -1px;
  color: #ffffff;
}
.box_footer .confirm_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 46px;
  background: #ff2d55;
  border-radius: 8px;
  cursor: pointer;
}
.box_footer .confirm_btn p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: right;
  letter-spacing: -1px;
  color: #ffffff;
}
label {
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
}
input[type='radio'] {
  appearance: none;
  background: rgba(225, 225, 225, 0.16);
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
}
input[type='radio']:hover {
  background: hsla(0, 0%, 100%, 0.2);
}
input[type='radio']:checked {
  background: #ff2d55;
  border: #fff 5px solid;
}

/* select_box custom */
.time_setting {
  border-radius: 0 8px 8px 0;
}

.content_sub_item {
  margin-top: 12px;
  margin-bottom: 12px;
}

.content_sub_item.first {
  margin-top: 0;
}

.content_sub_item_label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
}
/* .time_setting >>> .time_setting_box { border-radius: 0 8px 8px 0; }
.time_setting >>> .setting_box_header { border-radius: 0 8px 8px 0 !important; }
.time_setting >>> .setting_box_drop_down { border-radius: 0 8px 8px 0 !important; } */
</style>
