<template>
  <div id="email_ok"  class="email_modal modal">
    <div class="email_modal_box modal_box">
      <div class="modal_close_btn" @click="$emit('closeResendModal')">
        <img src="/media/img/close_btn.png" alt="">
      </div>
      <h3>인증메일 전송</h3>
      <p>인증 이메일을 전송했습니다.<br>
        이메일에서 인증하기 버튼을 클릭해주세요.
      </p>
      <!-- <button @click="resendMail">인증 메일 재발송</button> -->
    </div>
  </div>
</template>

<script>
/**
 * * 인증 메일 전송 완료 팝업
 */

export default {
  name: 'resendEmail'
}
</script>

<style scoped>
.email_modal_box button{ width: 100%; }
</style>
