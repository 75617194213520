<template>
  <div class="common_popup_area">
    <div class="common_popup_box">
      <div class="popup_box_top">
        <div class="popup_box_content">
          <p>전송자 : 어플레이즈</p>
          <p>이메일 수신동의 일시 : {{ year }}년 {{ month }}월 {{ day }}일 {{ hour }}시</p>
          <p>처리내용 : 수신동의 처리 완료</p>
          <p>(설정 > 알림설정에서 변경 가능)</p>
        </div>
      </div>
      <div class="popup_box_bot">
        <p class="confirm_btn" @click="$emit('closePopup')">확인</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pushOn',

  data () {
    return {
      year: '',
      month: '',
      day: '',
      hour: ''
    }
  },

  mounted () {
    var today = new Date();
    this.year = today.getFullYear();
    this.month = ('0' + (today.getMonth() + 1)).slice(-2);
    this.day = ('0' + today.getDate()).slice(-2);
    this.hour = ('0' + today.getHours()).slice(-2);
  }
}
</script>
<style src="@/assets/css/content.css"></style>
