<template>
  <section class="main notifiacation">
    <div class="main_box">
      <h1>알림함</h1>
      <div class="notifiacation_content hidden_menu">
        <ul v-if="noticeInfo.noticeId">
          <li>
            <p class="color_red">My</p>
            <h3>{{ noticeInfo.noticeTitle }}</h3>
          </li>
          <li><p>{{ dateTime(noticeInfo.registDate) }}</p></li>
        </ul>
        <ul v-else>
          <li>
            <p>공통</p>
            <h3>{{ noticeInfo.commonNoticeTitle }}</h3>
          </li>
          <li><p>{{ dateTime(noticeInfo.registDate) }}</p></li>
        </ul>
        <div class="return_txt" v-if="noticeInfo.noticeId">
          <p v-html="noticeInfo.noticeContent"></p>
        </div>
        <div class="return_txt" v-else>
          <p v-html="noticeInfo.commonNoticeContents"></p>
        </div>
        <div class="hidden_btn">
          <router-link to="/brand/notice"><button>목록</button></router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "@/service/axios";
import dayjs from "dayjs";

export default {
  name: 'message',

  props: {
    noticeId: String
  },

  data () {
    return {
      noticeInfo: {}
    };
  },

  mounted () {
    this.getMessage();
  },

  methods: {
    /**
     * * 공지/알림 상세 불러오기
     */
    getMessage () {
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      const noticeId = this.noticeId;

      if (this.noticeId[0] === 'n') {
        // 알림 상세 불러오기
        // noticeId의 시작하는 첫글자로 공지/알림 구분
        axios
          .post('/api/menu/getNotice', { noticeId }, { headers })
          .then(res => {
            this.noticeInfo = res.data.result;
            this.noticeInfo.noticeContent = this.noticeInfo.noticeContent.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/").replace(/(?:\r\n|\r|\n)/g, "</br>");
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        // 공지 상세 불러오기
        const commonNoticeId = noticeId;

        axios
          .post('/api/menu/getCommonNotice', { commonNoticeId }, { headers })
          .then(res => {
            this.noticeInfo = res.data.result;
            this.noticeInfo.commonNoticeContents = this.noticeInfo.commonNoticeContents.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/").replace(/(?:\r\n|\r|\n)/g, "</br>");
          })
          .catch(err => {
            console.log(err);
          })
      }
    },

    /**
     * * 날짜 포맷
     */
    dateTime (value) {
      return dayjs(value).format("YYYY.MM.DD HH:mm:ss");
    },
  }
};
</script>

<style scoped>
.return_txt p { width: 100%; white-space: normal; }
.hidden_menu ul li:nth-child(1) > p.color_red { background: #FF2D55; font-weight: 400; }
</style>
<style src="@/assets/css/content.css"></style>
<style src="@/assets/css/font.css"></style>
