<template>
    <div class="modal" v-if="noticeList.length > 0">
        <div v-for="(item, index) in noticeList" :key="item.noticeId">
            <div class="notice_modal_wrap" v-if="activeIndex === index">
                <div class="tag">
                    <span class="tagging">공지</span>
                </div>
                <h1 class="notice_modal_title">
                    {{ item.commonNoticeTitle }}
                </h1>
                <div class="notice_modal_contents">
                    <div v-html="item.commonNoticeSummary">
                    </div>
                </div>
                <button class="notice_detail_btn" v-if="item.commonNoticeAction === 'Y'" @click="goDetail(item, index)">자세히
                    보기</button>
                <div class="notice_modal_control" :class="item.commonNoticeAction === 'Y' ? 'has_detail' : ''">
                    <div class="notice_modal_control_left">
                        <img v-if="item.isVisible === 'N'" @click="item.isVisible = 'Y'" id="off_img" class="close_img"
                            src="/media/img/check_off_gray.png" alt="다시보진않기">
                        <img v-else-if="item.isVisible === 'Y'" @click="item.isVisible = 'N'" id="on_img" class="close_img"
                            src="/media/img/check_on.png" alt="다시보진않기">
                        <span class="close_text"
                            @click="item.isVisible === 'Y' ? item.isVisible = 'N' : item.isVisible = 'Y'">다시 보지
                            않기</span>
                    </div>
                    <div class="notice_modal_control_right">
                        <a @click="closeNoticeModal(item, index)">닫기</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: 'NoticeModalComponent',

    data() {
        return {
            noticeList: [],
            activeIndex: -1,
        };
    },

    async created() {
        await this.getNoticePopUpList();
    },

    mounted() {

    },

    methods: {
        goDetail(item, index) {
            const { meta } = this.$route
            if (Object.keys(meta).length > 0) {
                const { isLogin } = meta;
                if (isLogin) {
                    this.$router.push({ name: 'brand/notice/message', params: { noticeId: item.commonNoticeId } });
                }
            }
            else {
                this.$router.push({ path: `/brand/noticedetail/${item.commonNoticeId}` })
            }

            this.closeNoticeModal(item, index);
        },
        closeNoticeModal(item, index) {
            //다시 보지 않기
            this.$store.commit("addNoticeList", item);
            if (item.isVisible === 'Y') {
                this.$cookies.set(item.commonNoticeId, 'Y');
            }
            this.noticeList.splice(index, 1);
        },
        getNoticePopUpList() {
            const { name } = this.$route;
            let sendObject = {
                commonNoticePopup: 'Y'
            }
            if (name === "brand/home") {
                sendObject = {
                    nonmemberShowing: 'Y', commonNoticePopup: 'Y'
                }
            }

            axios
                .post('/api/menu/getCommonNoticePopupList', sendObject)
                .then(res => {
                    const { result } = res.data;
                    const curDate = new Date().getTime();
                    //commonNoticePopup === 'Y'
                    const filterOne = result.filter((item) => item.commonNoticePopup === 'Y' && (item.startDate <= curDate && item.endDate >= curDate));

                    //store에 noticeId가 존재하는지
                    const filterTwo = filterOne.filter((item) => {
                        const hasStoreNoticeId = this.$store.getters.gettersNoticeList.find(noticeItem => noticeItem.noticeId === item.commonNoticeId);
                        if (!hasStoreNoticeId) {
                            return true;
                        }
                        return false;
                    });

                    //commonNoticeId이 쿠키에 값이 존재하는지
                    const filterFinal = filterTwo.filter(item => {
                        const hasCookiesNoticeId = this.$cookies.get(item.commonNoticeId);
                        if (!hasCookiesNoticeId) {
                            return true;
                        }
                        return false;
                    });

                    //replaceAll 추가
                    const fianlList = filterFinal.map(item => {
                        const copy = Object.assign({}, item);
                        copy.commonNoticeSummary = copy.commonNoticeSummary.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/").replace(/(?:\r\n|\r|\n)/g, "</br>");
                        copy.isVisible = 'N';
                        return copy;
                    });
                    this.activeIndex = 0;
                    this.noticeList = fianlList;
                })
                .catch(err => {
                    console.log(`/api/menu/getCommonNoticePopupList error : `, err);
                    this.activeIndex = -1;
                });
        }
    },
};
</script>
<style scoped>
.notice_modal_wrap {
    position: absolute;
    top: 25%;
    left: 50%;
    width: 429px;
    transform: translateX(-50%);
    padding: 32px;
    background-color: var(--white);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.tagging {
    background-color: var(--secondary);
    color: var(--white);
    font-size: 18px;
    line-height: 26px;
    border-radius: 40px;
    padding: 3px 16px;
    font-weight: 500;
}

.notice_modal_title {
    color: var(--black000);
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-top: 8px;
    word-break: keep-all;
}

.notice_modal_contents {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
    word-break: keep-all;
}

.notice_detail_btn {
    margin-top: 20px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.40);
    padding: 4px 8px;
    color: rgba(0, 0, 0, 0.80);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    background: transparent;
}

.notice_modal_control {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
}

.notice_modal_control.has_detail {
    margin-top: 32px;
}

.notice_modal_control_left {
    display: flex;
}

.close_img {
    margin-right: 8px;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.close_text {
    color: var(--black000);
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
}

.notice_modal_control_right a {
    color: var(--black000);
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 500px) {
    .notice_modal_wrap {
        width: 90%;
        padding: 24px;
    }

    .tagging {
        font-size: 16px;
        padding: 3px 16px;
    }

    .notice_modal_title {
        font-size: 18px;
    }

    .notice_modal_contents {
        font-size: 14px;
    }

    .notice_detail_btn {
        margin-top: 12px;
        font-size: 16px;
    }
}
</style>