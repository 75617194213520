<template>
    <div class="brand_upload upload modal">
        <SearchBrand v-if="searchStatus" @closeSearchModal="closeSearchModal()" :brandNm="brandNm" @dragenter="onDragenter"
            @insertSearchBrand="insertBrandNm" />
        <div class="brand_upload_box modal_box setting_box">
            <div class="brand_upload_title modal_title">
                <h2>브랜드 등록</h2>
                <div class="modal_close_btn" @click="$emit('closeInsertModal')">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9 7.55012L16.0003 0.549805L18 2.54949L10.9997 9.5498L18 16.5501L16.0003 18.5498L9 11.5495L1.99969 18.5498L0 16.5501L7.00031 9.5498L0 2.54949L1.99969 0.549805L9 7.55012Z"
                            fill="white" />
                    </svg>
                </div>
            </div>
            <div>
                <label>브랜드명</label>
                <input type="text" class="input_text" placeholder="브랜드명으로 검색해주세요." v-model="brandNm"
                    @keyup.enter="searchAddr('map')" v-if="inputStatus === 'map'" :disabled="isDisabled" />
                <input type="text" class="input_text" placeholder="20자 이내로 입력해주세요." maxlength="20" v-model="brandNm2"
                    v-else />
                <div class="input_btn">
                    <p id="store_search" style="margin-bottom: 0px" @click="searchAddr('map')" :class="{ on: classMap }">
                        검색
                    </p>
                    <p :class="{ unclickable: classSearch }" style="margin-bottom: 0px" @click="searchAddr('search')">
                        직접입력
                    </p>
                </div>
            </div>
            <p class="alert_p" v-show="brandCheckAlert">이미 등록된 브랜드명입니다.</p>
            <div>
                <p>브랜드 로고</p>
                <div v-show="imgNm === ''" class="upload_img" @click="uploadImg" :class="isDragged ? 'dragged' : ''"
                    @dragover="onDragover" @dragleave="onDragleave" @drop="onDrop">
                    <img src="/media/img/Icon_List_52_52.png" alt="">
                    <input type="file" id="file" name="file" class="upload-img" style="display: none;" @change="setFile"
                        accept=".jpg, .jpeg, .png">
                    <p>클릭하거나 드래그로 이미지를 등록하세요.</p>
                </div>
                <div v-show="imgNm !== ''" @mouseover="showHoverDelete" @mouseleave="closeHoverDelete" style="padding: 0;">
                    <img :src="imgPath + brandImg" alt="" style="max-width: 300px; max-height: 300px;"
                        v-if="brandImg !== ''">
                    <img src="" id="preview-img" alt="" style="max-width: 300px; max-height: 300px;" v-else>
                    <div class="hover_delet" v-if="hoverDelete" @click="deleteImg">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M5 2V0H15V2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5ZM4 4V18H16V4H4ZM7 7H9V15H7V7ZM11 7H13V15H11V7Z"
                                fill="white" />
                        </svg>
                    </div>
                </div>
                <p class="alert_p" v-show="brandImgAlert">브랜드 로고 등록 가이드를 확인해주세요.</p>
            </div>
            <ul>
                <li><span></span>500 x 500px (1:1 비율)로 로고 이미지를 등록해주세요.</li>
                <li><span></span>JPEG, JPG, PNG 확장자의 파일만 등록 가능합니다.</li>
                <li><span></span>상업적인 이용 및 허가에 대한 부분을 확인하시어 등록해주시기 바랍니다.</li>
                <li><span></span>무단 · 부정한 이용으로 제3자의 권리를 침해하는 브랜드 이미지는 등록할 수 없습니다.</li>
            </ul>
            <div>
                <button class="insert_btn" :disabled="btnDisabled" @click="insertBrand">등록</button>
            </div>
        </div>

        <Loading v-if="loadingStatus"></Loading>
    </div>
</template>
<script>
import SearchBrand from '@/components/modal/brandManagement/SearchBrand.vue';
import VueCookies from "vue-cookies";
import axios from "@/service/axios";
import $ from 'jquery';
import Loading from '@/components/modal/common/Loading.vue';

export default {
    name: 'insertBrand',
    data() {
        return {
            brandNm: '',
            brandNm2: '',
            brandInfo: {},
            imgPath: '',
            brandImg: '',
            imgNm: '',
            inputStatus: 'map',
            brandNameAlert: false,
            brandImgAlert: false,
            classMap: true,
            classSearch: false,
            brandCheckAlert: false,
            searchStatus: false,
            hoverDelete: false,
            searchClickCheck: false,
            isDisabled: false,
            brandImgFile: null,
            /*드래그*/
            isDragged: false,

            //로딩
            loadingStatus: false
        }
    },
    components: {
        SearchBrand,
        Loading
    },

    methods: {
        deleteImg() { //로고 제거
            this.imgNm = '';
            this.brandImg = '';
            this.brandImgFile = null;
            document.getElementById('file').value = '';
        },

        /**
         * * 브랜드 검색창 닫기
         * * 브랜드명 초기화 / 브랜드명 입력 가능 전환
         */
        closeSearchModal() {
            this.searchStatus = false;
            this.brandNm = '';
            this.isDisabled = false;
        },

        //드래그 & 드랍
        onDragenter() {
            // class 넣기
            this.isDragged = true
        },
        onDragleave() {
            // class 삭제
            this.isDragged = false
        },
        onDragover(event) {
            // 드롭을 허용하도록 prevetDefault() 호출
            event.preventDefault()
        },
        onDrop(event) {
            event.preventDefault() // 기본 액션을 막음 (링크 열기같은 것들)
            this.isDragged = false;
            const file = event.dataTransfer.files[0];
            this.brandImgFile = file;
            this.checkFile(file)
        },

        //클릭 이벤트
        uploadImg() {
            $('.upload-img').click();
        },

        //이미지 선택 시
        setFile(e) {
            var file = e.target.files[0];
            this.brandImgFile = file;
            this.checkFile(file)
        },

        //이미지 확장자 체크
        checkFile(file) {
            const fileDir = file.name.substring(file.name.lastIndexOf(".") + 1, file.name.length);

            if (fileDir.toLowerCase().indexOf('jpg') !== -1 || fileDir.toLowerCase().indexOf('jpeg') !== -1 || fileDir.toLowerCase().indexOf('png') !== -1) {
                this.imgNm = file.name;
                $("#preview-img").attr("src", URL.createObjectURL(file)); //미리보기
                this.brandImgAlert = false;
            } else {
                this.brandImgAlert = true;
            }
        },

        insertBrandNm(search) { //검색 결과 가져오기
            this.brandInfo = search;
            if (this.brandInfo.brandImg != null) {
                this.brandImg = this.brandInfo.brandImg.substr(12);
                this.imgNm = this.brandInfo.brandImg;
            }
            this.isDisabled = true;
            this.brandNm = this.brandInfo.brandNm;
            this.searchStatus = false;
            document.getElementById('file').value = '';
            this.brandImgFile = null;
            this.searchClickCheck = true;
        },
        searchAddr(select) { //클릭한 버튼 색상 변경
            this.inputStatus = select;
            if (this.inputStatus === 'map') {
                this.classMap = true;
                this.classSearch = false;
                this.searchClickCheck = false;
                this.brandNm2 = '';
                this.imgNm = '';
                document.getElementById('file').value = "";
            } else {
                this.classMap = false;
                this.classSearch = true;
                this.brandNm = '';
                this.searchClickCheck = false;
                this.imgNm = '';
                document.getElementById('file').value = "";
            }

            if (select === 'map') {
                this.showMap();
            }
        },
        showMap() { //매장검색 모달 열기
            this.searchStatus = true;
        },
        insertBrand() {
            this.loadingStatus = true;
            const formData = new FormData();

            const corpId = VueCookies.get("corpId");
            const aToken = VueCookies.get("aToken");
            const brandImg = document.getElementById('file').files[0];
            if (this.classMap) {
                var brandNm = this.brandNm;
            } else {
                brandNm = this.brandNm2;
            }

            if (this.classSearch) {
                if (brandNm.length > 0) { //브랜드 명을 입력했을때
                    if (this.brandImgFile != null && this.brandImgFile != undefined) { //브랜드 로고 등록했을때
                        var headers = { "X-AUTH-TOKEN": aToken, 'Content-Type': 'multipart/form-data' };

                        formData.append('corpId', corpId);
                        formData.append('brandNm', brandNm);
                        if (this.brandImgFile != null && this.brandImgFile != undefined) {
                            formData.append('multipartFile', this.brandImgFile);
                        }

                        axios
                            .post('/api/brand/createBrand', formData, { headers })
                            .then(res => {
                                if (res.data.result) {
                                    alert('등록되었습니다.');
                                    this.$emit('getList');
                                } else {
                                    this.brandCheckAlert = true;
                                }
                            })
                            .catch(err => {
                                console.log(err);
                                this.brandImgAlert = true;
                            })
                            .finally(() => {
                                setTimeout(() => {
                                    this.loadingStatus = false;
                                }, 500)
                            });
                    } else {
                        this.loadingStatus = false;
                        alert("브랜드 로고를 등록해주세요.");
                    }
                } else {
                    this.loadingStatus = false;
                    alert("브랜드 명을 입력해주세요.");
                }
            } else {
                headers = { "X-AUTH-TOKEN": aToken, 'Content-Type': 'multipart/form-data' };
                var brandId = this.brandInfo.brandId;

                formData.append('corpId', corpId);
                formData.append('brandId', brandId);
                formData.append('brandNm', brandNm);
                if (brandImg != null && brandImg != undefined) {
                    formData.append('multipartFile', brandImg);
                }
                if (this.imgNm !== '') {
                    axios
                        .post('/api/brand/updateBrand', formData, { headers })
                        .then(res => {
                            if (res.status === 200) {
                                alert('등록되었습니다.');
                                this.$emit('getList');
                            }
                        })
                        .catch(err => {
                            if (err.response.data.resultMsg === '확장자가 올바르지 않습니다.') {
                                this.brandImgAlert = true;
                            }
                            console.log(err);
                        })
                        .finally(() => {
                            setTimeout(() => {
                                this.loadingStatus = false;
                            }, 500)
                        })
                } else {
                    this.loadingStatus = false;
                    alert('브랜드 로고를 등록해주세요.');
                }
            }
        },
        showHoverDelete() {
            this.hoverDelete = true;
        },
        closeHoverDelete() {
            this.hoverDelete = false;
        }
    },

    computed: {
        btnDisabled() {
            if (this.inputStatus === 'map') {
                return this.isDisabled && this.brandImgFile !== null && this.brandNm.length > 0 ? false : true;
            } else {
                return this.brandImgFile !== null && this.brandNm2.length > 0 ? false : true;
            }
        }
    }
};
</script>

<style scoped>
.insert_btn:disabled {
    opacity: .7;
    cursor: initial;
}

.insert_btn:disabled:hover {
    opacity: .7;
}

.modal_box button:hover {
    background: #ff2d55;
}

.modal_box button:disabled:hover {
    background: #ff2d55;
    opacity: .7 !important;
}

.brand_upload_box>div {
    position: relative;
}

.brand_upload_box>div label {
    display: block;
    color: #fff;
    font-size: 16px;
    margin-bottom: 8px;
}

.brand_upload_box>div input {
    display: block;
    width: 100%;
}

.brand_upload_box>div:nth-child(2) {
    margin-bottom: 16px;
}

.brand_upload_box>div:nth-child(2) p:nth-of-type(1) span {
    color: #fff;
    font-weight: 400;
}

.brand_upload_box>div p.alert_p {
    color: #E85454;
    font-size: 14px;
    margin-top: 6px;
}

.brand_upload_box>div:nth-child(3)>p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 8px;
}

.brand_upload_box>div:nth-child(3) div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 0;
    border: 1px dashed rgba(255, 255, 255, 0.3);
    border-radius: 8px;
}

.brand_upload_box>div:nth-child(3) div p {
    color: rgba(255, 255, 255, 0.44);
    font-size: 16px;
}

.brand_upload_box>div:nth-child(3)>p.alert_p {
    color: #E85454;
    font-size: 14px;
    margin: 6px 0 16px;
}

.delet_btn2 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 0 !important;
}

.delet_btn2 div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 92px;
    height: 34px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
}

.delet_btn2 div:hover {
    background: rgba(255, 255, 255, 0.1);
}

.delet_btn2 div p {
    position: static !important;
    top: auto !important;
    right: auto !important;
    transform: translate(0) !important;
    color: rgba(255, 255, 255, 0.87);
    font-size: 14px;
    font-weight: 400;
    margin-left: 4px;
}


.setting_box>div:nth-child(3) {
    margin-bottom: 16px;
}

.setting_box>div:nth-child(3) p:nth-of-type(1) {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-42%);
    color: rgba(255, 255, 255, 0.84);
    font-size: 14px;
    font-weight: 300;
}

.setting_box>div:nth-child(3) p:nth-of-type(1) span {
    color: #fff;
    font-weight: 400;
}

.setting_box>div:nth-child(4)>p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 8px;
}

.setting_box>div p.alert_p {
    color: #E85454;
    font-size: 14px;
    margin-top: 6px;
}

.setting_box>div:nth-child(4) div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
}

/* .setting_box > div:last-child { justify-content:space-between !important;align-items:center;} */
.setting_box>div:last-child p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
}

.setting_box>div:nth-child(4) .upload_img {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 0;
    border: 1px dashed rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    background: rgba(255, 255, 255, 0);
}

.brand_upload_box>div:nth-child(4) div .hover_delet {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    flex-direction: row;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.brand_upload_box ul {
    margin-top: 16px;
}

.brand_upload_box ul li {
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    margin-bottom: 4px;
}

.brand_upload_box ul li span {
    display: inline-block;
    width: 4px;
    height: 4px;
    background: #C4C4C4;
    ;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
    margin-top: -5px;
}

.brand_upload_box>div:last-child {
    display: flex;
    justify-content: flex-end;
    margin-top: 26px;
}

.brand_upload_box>div:nth-child(3) div {
    cursor: pointer;
}

label {
    width: 100%;
    color: #fff;
    font-size: 16px;
}

label span {
    color: #FF2D55;
}

label>div {
    position: relative;
    width: 100%;
    margin: 8px 0;
}

input {
    width: 100%;
    margin-bottom: 8px;
}

.input_btn {
    position: absolute;
    top: 70%;
    right: 8px;
    transform: translateY(-60%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.input_btn .on {
    background-color: #fff;
    color: #000;
}

.input_btn .unclickable {
    background-color: #fff;
    color: #000;
    pointer-events: none;
}

.input_btn p {
    padding: 8px 16px;
    background: transparent;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
}

.input_btn p:hover {
    opacity: 0.8;
    background: hsla(0, 0%, 100%, .1);
}

.input_btn p.on:hover {
    opacity: 0.8;
    background: #fff;
}

.input_text {
    padding-right: 165px;
}
</style>