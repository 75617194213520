<template>
  <div class="delet_finish_alert alert">
    <div class="delet_finish_box alert_box">
      <h3>PC에서 이용 가능합니다.</h3>
      <div class="delet_finish_btn">
        <p id="color_txt" @click="$emit('closeMobileCheck')">확인</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * * 모바일 얼럿 팝업
 * * 법인 페이지는 랜딩 페이지를 제외하면 PC에서만 이용이 가능
 */
export default {
  name: 'mobileCheck'
}
</script>

<style scoped>
  h3 { width: 100%; font-size: 18px; padding: 0 0 24px; text-align: center; }
  p { padding:0; text-align: center; height: 60px; line-height: 60px; color: #FF2D55; border-top: 1px solid rgba(255, 255, 255, 0.08); cursor: pointer; }

  @media all and (max-width: 500px) {
    .delet_finish_box.alert_box { width: 90%; padding-top: 0;}
    h3 { font-size: 16px; padding-top: 32px; text-align: center; }
    p { font-size: 14px; }
    #color-text { font-size: 14px; }
  }
</style>
