<template>
  <div class="store_search_modal modal">
    <BrandCheck v-if="brandCheckStatus" @closeBrandCheck="brandCheckStatus=false" />
    <div class="store_search_box modal_box">
      <div class="st_search_title modal_title">
        <h2>브랜드명으로 검색</h2>
        <div class="modal_close_btn" @click="$emit('closeSearchModal')">
          <img src="/media/img/close_btn.png" alt="">
        </div>
      </div>
      <div class="search_input">
        <input type="text" placeholder="브랜드명으로 검색해주세요" @keyup.enter="searchBrandList()" v-model="search" />
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z" fill="white"/>
        </svg>
      </div>
      <div class="store_txt">
        <p @click="checkBrand(item)" style="color:white" v-for="(item, i) in brandList" :key="i" v-html="item.brandNm" />
      </div>
    </div>
  </div>
</template>

<script>
import BrandCheck from "@/components/modal/brandManagement/BrandCheck.vue";
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  name: 'searchBrand',

  props: {
    brandNm: String
  },

  components: {
    BrandCheck
  },

  data () {
    return {
      brandList: [],
      brandListData: [],
      search: '',
      brandCheckStatus: false
    };
  },

  mounted () {
    if (this.brandNm !== '') this.search = this.brandNm;
    this.getAllBrandList();
  },

  methods: {
    /**
     * * 브랜드 목록 불러오기
     */
    getAllBrandList () {
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/brand/getAllBrandList', { headers })
        .then((res) => {
          this.brandListData = res.data.result;

          if (this.search !== '') {
            this.searchBrandList();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /**
     * * 브랜드 사용 유무 체크
     */
    checkBrand (item) {
      const brandId = item.brandId;
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/brand/checkConnectCorp', { brandId }, { headers })
        .then(res => {
          if (res.data.result) {
            this.brandCheckStatus = true;
          } else {
            this.$emit('insertSearchBrand', item);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * * 브랜드 검색
     */
    searchBrandList () {
      this.brandList = this.brandListData.filter((brand) => {
        return (brand.brandNm.includes(this.search));
      })
    }
  }
};
</script>
