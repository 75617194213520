<template>
  <div>
    <div class="group_store_alert alert">
      <div class="group_store_box alert_box">
        <h3>로그아웃 하시겠습니까?</h3>
        <div class="group_store_btn">
          <p @click="$emit('closeLogoutModal')">아니오</p>
          <p id="color_txt" @click="checkLogout">네</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  name: 'logout',
  methods: {
    checkLogout () {
      var accessToken = VueCookies.get('aToken');
      var refreshToken = VueCookies.get('rToken');
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/auth/logout', { accessToken, refreshToken }, { headers })
        .then(res => {
          if (res.status === 200) {
            VueCookies.keys().forEach(cookie => this.$cookies.remove(cookie));
            //this.$emit('closeLogoutModal');
            this.$router.push('/brand/login');
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  }
}
</script>