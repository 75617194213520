<template>
  <div>
    <FindIdModal v-if="modalStatus" @closeFindIdModal="modalStatus=false" />
    <section class="login_main find_pw">
      <div class="login_main_box">
        <h1>아이디 찾기</h1>
        <input type="text" placeholder="이름을 입력해주세요." v-model="name" ref="search1" @keyup.enter="findId">
        <input type="text" placeholder="휴대폰 번호를 입력해주세요" ref="search2" v-model="corpTel" @keyup.enter="findId" @input="numCheck()">
        <button @click="findId">다음</button>
      </div>
    </section>
  </div>
</template>

<script>
import FindIdModal from '@/components/modal/find/FindIdModal.vue';
import axios from "axios";

export default {
  components:{
    FindIdModal,
  },

  data(){
    return{
      modalStatus: false,
      name: '',
      corpTel: '',
      idList: []
    }
  },

  methods:{
    /**
     * * 정규식
     * * 휴대폰 번호가 숫자로만 입력되는지 체크
     */
    numCheck () {
      this.corpTel = this.corpTel.replace(/[^0-9]/g, '');
    },

    /**
     * * 아이디 찾기
     */
    findId () {
      const name = this.name;
      const corpTel = this.corpTel;

      if (this.name !== '') {
        if (this.corpTel !== '') {
          axios
            .post("/api/corp/getCorpEmail", { name, corpTel })
            .then(res => {
              if (res.data.resultCd === '0000') {
                this.idList = res.data.result;
                this.$router.push({ name: 'brand/findIdFinished', params: { idList : this.idList } });
              }
            })
            .catch(e => {
              console.log(e);
              if (e.response.data.resultMsg === '입력하신 정보로 가입된 정보가 없습니다.') {
                this.modalStatus = true;
              }
            });
        } else {
          alert('휴대폰 번호를 입력해주세요');
          this.$refs.search2.focus();
        }
      }else{
        alert('이름을 입력해주세요');
        this.$refs.search1.focus();
      }
    }
  }
}
</script>