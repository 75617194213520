<template>
  <div class="notice_area">
    <div class="notice_header">
      <h3>마케팅 수신 동의</h3>
    </div>

    <div class="notice_setting">
      <p class="notice_setting_title">메일</p>
      <div class="toggle_box" @click="changeMailStatus">
        <span :class="mailStatus ? 'active_1' : ''"></span>
        <span class="move" :class="mailStatus ? 'active_2' : ''"></span>
      </div>
    </div>

    <div class="notice_setting">
      <p class="notice_setting_title">SMS</p>
      <div class="toggle_box" @click="changeSmsStatus">
        <span :class="smsStatus ? 'active_1' : ''"></span>
        <span class="move" :class="smsStatus ? 'active_2' : ''"></span>
      </div>
    </div>

    <div class="notice_setting">
      <p class="notice_setting_title">사이트 알림함</p>
      <div class="toggle_box" @click="changeSiteStatus">
        <span :class="siteStatus ? 'active_1' : ''"></span>
        <span class="move" :class="siteStatus ? 'active_2' : ''"></span>
      </div>
    </div>

    <PushOn v-if="popupStatus_1" @closePopup="popupStatus_1=false" />
    <PushOff v-if="popupStatus_2" @closePopup="popupStatus_2=false" />
  </div>
</template>

<script>
import PushOn from './append/PushOn.vue';
import PushOff from './append/PushOff.vue';
import VueCookies from "vue-cookies";
import axios from 'axios';

export default {
  name: 'settingNotice',

  components: {
    PushOn,
    PushOff
  },

  data () {
    return {
      mailStatus: false,
      smsStatus: false,
      siteStatus: false,

      popupStatus_1: false,
      popupStatus_2: false
    };
  },

  mounted () {
    this.getSettingNotice();
  },

  methods: {
    getSettingNotice () {
      const corpId = VueCookies.get("corpId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/corp/getNotiSet', { corpId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.mailStatus = result.mailNoti === 'Y' ? true : false;
            this.smsStatus = result.snsNoti === 'Y' ? true : false;
            this.siteStatus = result.notiBox === 'Y' ? true : false;
          }
        })
        .catch(e => { console.log(e); });
    },

    changeMailStatus () {
      this.pushOn();

      const corpId = VueCookies.get("corpId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/corp/getMailNoti', { corpId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.mailStatus = !this.mailStatus;

            this.pushAllOff();
          }
        })
        .catch(e => { console.log(e); });
    },

    changeSmsStatus () {
      this.pushOn();

      const corpId = VueCookies.get("corpId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/corp/getSnsNoti', { corpId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.smsStatus = !this.smsStatus;

            this.pushAllOff();
          }
        })
        .catch(e => { console.log(e); });
    },

    changeSiteStatus () {
      this.pushOn();

      const corpId = VueCookies.get("corpId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/corp/getNotiBox', { corpId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.siteStatus = !this.siteStatus;

            this.pushAllOff();
          }
        })
        .catch(e => { console.log(e); });
    },

    pushOn () {
      if (!this.mailStatus && !this.smsStatus && !this.siteStatus) {
        this.popupStatus_1 = true;
      }
    },

    pushAllOff () {
      if (!this.mailStatus && !this.smsStatus && !this.siteStatus) {
        this.popupStatus_2 = true;
      }
    }
  }
}
</script>

<style scoped>
.notice_area .notice_header {
  width: 100%;
  margin-bottom: 24px;
}
.notice_area .notice_header h3 {
  color: hsla(0,0%,100%,.87);
  font-size: 18px;
  font-weight: 400;
}

.notice_area .notice_setting {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.notice_area .notice_setting .notice_setting_title {
  color: hsla(0,0%,100%,.6);
  font-size: 16px;
  font-weight: 300;
  white-space: nowrap;
}

.notice_area .notice_setting .toggle_box { position: absolute; right: 10%; display: flex; align-items: center; cursor: pointer; }
.notice_area .notice_setting .toggle_box span {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-50%);
  width: 40px;
  height: 12px;
  border-radius: 30px;
  background: #3b3b3b;
}
.notice_area .notice_setting .toggle_box span.move {
  left: -12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #444444;
  box-shadow: -2px 0px 6px rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
}
.notice_area .notice_setting .toggle_box span.active_1 { background: rgba(255, 45, 85, 0.6); }
.notice_area .notice_setting .toggle_box span.move.active_2 { transform: translate(40px, -50%); background: #ff2d55; transition: all 0.3s; }
</style>
