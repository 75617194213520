<template>
  <div>
    <div class="cs_content">
      <div class="policy_title pc_title">
        <h2>개인정보 처리방침</h2>
      </div>
      <div class="cs_content_tab">
        <div class="select-box-area">
          <div class="label select-box" @mouseover.once="clickSelectBox()">{{ privacyDate }}</div>
          <div class="select-box-dropDown">
            <ul>
              <li class="optionItem" v-for="item in privacyList" :key="item.stplatSeq" @click="getPrivacy(item)">
                <p>{{ item.term }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-html="privacy"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'personalInfo',
  data(){
    return{
      stplatCate: 'PrivacyServiceCorp',
      privacy: null,
      privacyDate: '2022.05.16 ~ 현재',
      privacyList: []
    }
  },
  created(){
    this.getPrivacyList();
  },
  methods: {
    /**
     * * 폐기 후 /corp/getStplatList 와 통합 필요
     */
    getPrivacy (item) {
      var stplatCate = this.stplatCate;
      var stplatVersion = item.stplatVersion;

      axios
        .post("/api/corp/getStplat", { stplatCate, stplatVersion })
        .then((res) => {
          console.log(res.data.result);
          var privacyText = res.data.result.stplatContents;
          this.privacy = privacyText.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getPrivacyList () {
      var stplatCate = this.stplatCate;

      await axios
        .post("/api/corp/getStplatList", { stplatCate })
        .then((res) => {
          //console.log(res.data.result);
          var result = res.data.result;
          this.privacyList = result;
          this.privacy = result[0].stplatContents.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
          this.privacyDate = result[0].term;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickSelectBox () {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
}
</script>

<style>
.select-box-area { left: 1px; }
.policy .small_dan {margin-bottom:3rem; }
.policy .small_dan  p {font-size: 16px;color: rgba(255, 255, 255, 0.60); padding-bottom:0.5rem; line-height: 1.8rem;}
.policy .small_dan  p.small_h {font-size: 16px; font-weight:500;color: rgba(255, 255, 255, 0.60);}
.policy .small_dan .tbl_type2{margin:20px 0;}
.policy .small_dan .tbl_type2 table tbody tr th{
  height: 40px;
  font-family: "Noto Sans KR";
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.60);
  line-height: 1.5;
  vertical-align: middle;
  padding: 12px 30px;
  background: #151515;
  border-top: 2px solid rgba(255, 255, 255, 0.10);
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  border-right: 1px solid rgba(255, 255, 255, 0.10);
  text-align: center;
}
.policy .small_dan .tbl_type2 table tbody tr th:nth-last-child(1){
  border-right: 0px solid rgba(255, 255, 255, 0.10);
}

.tbl_type2 table tbody tr td{
  height: 40px;
  font-family: "Noto Sans KR";
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.60);
  line-height: 1.5;
  vertical-align: middle;
  padding: 12px 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  border-right: 1px solid rgba(255, 255, 255, 0.10);
  background: #151515;
}

.tbl_type2 table tbody tr td:nth-last-child(1){
  border-right: 0px solid rgba(255, 255, 255, 0.10);
}

.policy .small_dan ol li{
  color: rgba(255, 255, 255, 0.60);
  font-weight: 300;
  font-size: 16px;
  padding:0 0 1rem 1rem;
  line-height: 1.8rem;
}
.policy .cs_list_box h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0.5rem;
  opacity: 0.60;
}
</style>