<template>
  <div class="inquiry_popup modal1">
    <!-- <Privacy v-if="isPrivacy" @closePrivacy="isPrivacy = false" @ShowPrivacy="isPrivacy = true"/> -->
    <div class="popup_box modal_box">
      <div class="popup_title modal_title">
        <h4 style="margin-bottom:0px;">제휴문의</h4>
        <div class="modal_close_btn" @click="$emit('closeInquiry')">
          <img src="/media/img/close_btn.png" alt="" />
        </div>
      </div>

      <div class="input_title">
        <label style="width:100%;"
          >회사명
          <input
            type="text"
            maxlength="20"
            placeholder="회사명을 입력해주세요."
            v-model="partnerCompany"
          />
        </label>
      </div>
      <div class="input_title">
        <label style="width:100%;"
          >담당자명
          <input
            type="text"
            maxlength="20"
            placeholder="담당자명을 입력해주세요."
            v-model="partnerNm"
          />
        </label>
      </div>
      <div class="input_title">
        <label style="width:100%;"
          >담당자 이메일 주소
          <input
            type="text"
            maxlength="40"
            placeholder="담당자 이메일 주소를 입력해주세요."
            v-model="partnerEmail"
          />
        </label>
      </div>
      <div class="input_title">
        <label style="width:100%;"
          >담당자 전화번호
          <input
            type="text"
            maxlength="11"
            placeholder="담당자 전화번호를 입력해주세요."
            v-model="partnerPhone"
            @input="numCheck()"
          />
        </label>
      </div>
      <div class="input_content">
        <label style="width:100%;"
          >문의내용
          <textarea
            type="text"
            maxlength="200"
            placeholder="문의 내용을 입력해주세요."
            v-model="partnerContent"
          ></textarea>
        </label>
        <p>{{ partnerContent.length }}<span>/200</span></p>
      </div>
      <div class="popup_bottom">
        <button :class="{ disabled: disabled === true }" @click="insertInquiry()">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
  name: 'PartnershipInquiry',
  data () {
    return {
      partnerCompany: "",
      partnerNm: "",
      partnerEmail: "",
      partnerContent: "",
      partnerPhone: "",
      disabled: false
    };
  },

  methods: {
    /**
     * * 숫자만 입력 가능하게 지정
     */
    numCheck () {
      this.partnerPhone = this.partnerPhone.replace(/[^0-9]/g, '');
    },

    /**
     * * 제휴 문의 등록
     */
    insertInquiry () {
      this.disabled = true;
      const partnerCompany = this.partnerCompany;
      const partnerNm = this.partnerNm;
      const partnerEmail = this.partnerEmail;
      const partnerContent = this.partnerContent;
      const partnerPhone = this.partnerPhone;
      
      axios
        .post("/api/corp/createPartnership", { partnerCompany, partnerNm, partnerEmail, partnerContent, partnerPhone })
        .then(res => {
          if (res.status === 200) {
            this.disabled = false;
            this.$emit('closeInquiry');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
});
</script>
<style scoped>
  .disabled { pointer-events: none; }
</style>
