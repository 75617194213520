<template>
  <div>
    <section class="login_main">
      <div class="login_main_box join_box">
        <img src="/media/img/join_1_icon.png" alt="">
        <h1>비밀번호 변경 완료</h1>
        <p>비밀번호가 변경되었습니다. 로그인해주세요.</p>
        <router-link to="/brand/login"><button>로그인</button></router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {

}
</script>
