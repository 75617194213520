<template>
  <div>
    <ResendEmail2 v-if="modalStatus" @closeModal="modalStatus = false" :toMail="email" />
    <section class="login_main">
      <div class="login_main_box">
        <h1>로그인</h1>
        <input type="text" placeholder="이메일 아이디" v-model="email" @keyup.enter="signIn" @keypress="passwordNotExist = false" />
        <input type="password" placeholder="비밀번호" v-model="password" @keyup.enter="signIn" @keypress="passwordNotExist = false" />
        <div class="in_pw" v-if="passwordNotExist === true">
          <p>아이디가 존재하지 않거나 비밀번호가 일치하지 않습니다.</p>
        </div>
        <div class="auto_login">
          <div id="login_check">
            <checkboxAutoLogin v-model="autoLogin" />
            <p>자동 로그인</p>
          </div>
          <div id="login_check1">
            <checkboxSaveId v-model="saveId" />
            <p>아이디 저장</p>
          </div>
        </div>
        <button @click="signIn">로그인</button>
        <div style="display: flex; justify-content: space-around">
          <p><router-link to="/brand/findId">아이디 찾기</router-link></p>
          <p><router-link to="/brand/findPass">비밀번호 찾기</router-link></p>
        </div>
      </div>
    </section>
    <Complete v-if="dormantStatus_2" @closePopup="closePopup" />
  </div>
</template>

<script>
import ResendEmail2 from '@/components/modal/signUp/ResendEmail2';
import checkboxSaveId from '../components/checkbox/checkboxSaveId.vue';
import checkboxAutoLogin from '../components/checkbox/checkboxAutoLogin.vue';
import VueCookies from 'vue-cookies';
import router from '@/router';
import axios from 'axios';
import Complete from '@/components/modal/dormant/ConversionComplete.vue';
import dayjs from 'dayjs';

export default {
  components: {
    ResendEmail2,
    checkboxSaveId,
    checkboxAutoLogin,
    Complete
  },

  data() {
    return {
      modalStatus: false,
      saveId: false,
      autoLogin: false,
      email: '',
      password: '',
      passwordNotExist: false,
      blank: false,

      //휴면 해제 팝업
      dormantInfo: {},
      dormantStatus_1: false,
      dormantStatus_2: false
    };
  },

  mounted() {
    if (this.email === '' || this.email === undefined) {
      if (VueCookies.get('saveId') === 'true') {
        this.saveId = true;
        this.email = VueCookies.get('corpEmail');
      } else {
        this.saveId = false;
      }
    }
  },

  methods: {
    /**
     * * 로그인
     * * 토큰 발급 or 휴면 계정 확인 / 이메일 인증 여부 확인 > 계정 정보 불러오기 > 자동 로그인, 아이디 저장 여부 쿠키에 저장 후 메인 페이지로 이동
     */
    signIn() {
      const email = this.email;
      const password = btoa(this.password);

      if (email.length > 0 && password.length > 0) {
        axios
          .post('/api/auth/login', { email, password })
          .then(res => {
            // alert 제거
            this.passwordNotExist = false;

            // 휴면 계정 여부 확인
            if (res.data.result.conversionDate == null) {
              const aToken = res.data.result.accessToken;
              const rToken = res.data.result.refreshToken;

              // a토큰, r토큰 쿠키에 세팅
              VueCookies.set('aToken', aToken);
              VueCookies.set('rToken', rToken);
              const headers = { 'X-AUTH-TOKEN': aToken };

              axios
                .post('/api/corp/mailAcceptCheck', { email }, { headers }) //이메일 인증
                .then(res3 => {
                  if (res3.data.result) {
                    //이메일 인증 완료
                    axios
                      .post('/api/corp/getLoginCorp', { email }, { headers })
                      .then(response => {
                        const corpId = response.data.result.corpId;
                        const corpEmail = response.data.result.email;
                        const userType = response.data.result.userType;

                        // 법인 아이디, 이메일, 타입 쿠키에 세팅
                        VueCookies.set('corpId', corpId);
                        VueCookies.set('corpEmail', corpEmail);
                        VueCookies.set('userType', userType);

                        if (VueCookies.get('slideNum') == null) {
                          VueCookies.set('slideNum', 1);
                        }

                        // 자동 로그인, 아이디 저장 여부 쿠키에 세팅
                        VueCookies.set('autoLogin', this.autoLogin);
                        VueCookies.set('saveId', this.saveId);
                        router.push({ name: 'brand' });
                      })
                      .catch(error => {
                        console.log(error);
                      });
                  } else {
                    //이메일 인증 미완료
                    this.modalStatus = true;
                  }
                });
            } else {
              // 휴면 계정일 경우 휴면 해제 팝업 열기
              this.dormantInfo.conversionDate = dayjs(res.data.result.conversionDate).format('YYYY-MM-DD');
              this.dormantInfo.email = email;
              this.dormantStatus_1 = true;
            }
          })
          .catch(err => {
            var msg = err.response.data.resultMsg;
            if (msg === '아이디가 존재하지 않거나 비밀번호가 일치하지 않습니다') {
              this.passwordNotExist = true;
              this.password = '';
            }
          });
      } else {
        // 이메일 또는 패스워드 빈칸
        alert('이메일 또는 패스워드를 입력해 주세요!');
        document.getElementById('email').focus();
      }
    },

    /**
     * * 휴면 해제 완료 이벤트
     * * 입력했던 이메일, 비밀번호를 초기화 해주고 휴면 해제 완료 팝업을 닫음
     */
    closePopup() {
      this.email = '';
      this.password = '';
      this.dormantStatus_2 = false;
    }
  }
};
</script>

<style scoped>
img {
  width: 24px;
  height: 24px;
}
.login_main_box div:nth-child(6) {
  display: flex;
  justify-content: space-around;
}
.login_main_box a {
  text-decoration: none;
  color: #fff;
}
.in_pw {
  position: relative;
  width: 100%;
}
.in_pw input {
  width: 100%;
  margin-bottom: 8px;
}
.in_pw p {
  color: #ff4949 !important;
  font-size: 14px;
  margin: 0;
}
</style>
<style src="@/assets/css/content.css"></style>
<style src="@/assets/css/font.css"></style>
