<template>
  <div class="space_information modal">
    <div class="space_information_box modal_box">
      <div class="space_information_title modal_title">
        <h2>스페이스 정보 </h2>
        <div class="modal_close_btn" @click="$emit('closeShopInfo')">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 7.55012L16.0003 0.549805L18 2.54949L10.9997 9.5498L18 16.5501L16.0003 18.5498L9 11.5495L1.99969 18.5498L0 16.5501L7.00031 9.5498L0 2.54949L1.99969 0.549805L9 7.55012Z" fill="white"/>
          </svg>
        </div>
      </div>
      <section class="info_scroll_body">
        <div>
          <div>
            <label>매장명</label>
            <input type="text" v-model="curationInfo.spaceNm" disabled>
          </div>
          <div>
            <label>매장 주소</label>
            <input type="text" v-model="curationInfo.spaceAddr" disabled>
            <input type="text" v-model="curationInfo.spaceAddrDetail" disabled>
          </div>
          <div>
            <label>연락처</label>
            <input type="text" v-model="curationInfo.spaceTel" disabled>
          </div>
        </div>
        <div class="three_type">
          <div class="type_title">
            <div class="radio_box">
              <p class="category">공간분위기</p>
            </div>
            <div class="type_span">
              <p>{{ curationInfo.mood }}</p>
            </div>
          </div>
          <div class="type_title">
            <div class="radio_box">
              <p class="category">주요 고객층</p>
            </div>
            <div class="type_span">
              <p v-for="target, i in curationInfo.target" :key="i">{{ target }}</p>
            </div>
          </div>
          <div class="type_title">
            <div class="radio_box">
              <p class="category">고객 단위</p>
            </div>
            <div class="type_span">
              <p v-for="targetUnit, i in curationInfo.targetUnit" :key="i">{{ targetUnit }}</p>
            </div>
          </div>
          <div class="type_title">
            <div class="radio_box">
              <p class="category">선호 국가</p>
            </div>
            <div class="type_span">
              <p>국내 {{ curationInfo.domesticRate }}%</p>
            </div>
          </div>
          <div class="type_title">
            <div class="radio_box">
              <p class="category">선호 장르</p>
            </div>
            <div class="type_span">
              <p v-for="genre, i in curationInfo.genre" :key="i" style="margin-bottom: 8px;">{{ genre }}</p>
            </div>
          </div>
          <div class="type_title">
            <div class="radio_box">
              <p class="category">선호 시대</p>
            </div>
            <div class="type_span">
              <p v-for="period, i in curationInfo.period" :key="i">{{ period }}년대</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from 'axios'

export default {
  name: 'shopInfo',
  data () {
    return{
      curationInfo: {}
    }
  },
  props: [
    'spaceInfo'
  ],
  created () {
    var aToken = VueCookies.get("aToken");
    var headers = { "X-AUTH-TOKEN": aToken };
    var spaceId = this.spaceInfo.spaceId;

    axios
      .post("/api/space/selectSpaceInfo", { spaceId }, { headers })
      .then(res => {
        if (res.data.resultCd === '0000') {
          this.curationInfo = res.data.result[0];
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
}
</script>

<style scoped>
.info_scroll_body::-webkit-scrollbar {display: block; width: 4px;}
.info_scroll_body::-webkit-scrollbar-thumb { background: #ccc;}
.info_scroll_body::-webkit-scrollbar-track { background: #555;}
.three_type {width:100%;}
.three_type > div ul {width:100%;display:flex; flex-wrap:wrap;justify-content:flex-start; align-items:flex-start; }
.three_type > div ul li {position:relative; width:160px; height:160px; margin-right:34px; margin-bottom:70px;border-radius:50%;cursor:pointer;}
.three_type > div ul li:nth-child(5) {margin-right:0;}
.type_title {width:100%;display:flex; justify-content:space-between; align-items:center; margin-bottom:16px; cursor: auto;}
.type_title > div {display:flex; justify-content:flex-start; align-items:center;}
.type_title > div span {width:32px; height:32px; color:#151515; border-radius: 50%; background: #FF2D55; text-align:center; line-height:32px; font-weight:500; font-size:20px}
.type_title > div p {margin-left:10px; color:#fff; font-size:16px; font-weight:400; }
.type_title .type_span > p {padding:7px 12px;background: rgba(255, 255, 255, 0.04); border: 1px solid rgba(255, 255, 255, 0.2);  border-radius: 8px;color:#fff; font-size:16px; font-weight:400; display:flex; justify-content:flex-end;}

.type_title .type_span {width:100%;display:flex; flex-wrap:wrap;justify-content:flex-end;align-items:center;}
.type_title .type_span > p {display:flex; padding:7px 12px;background: rgba(255, 255, 255, 0.04); border: 1px solid rgba(255, 255, 255, 0.2);  border-radius: 8px; margin-left:8px;color:#fff; font-size:16px; font-weight:400; }
.type_title .radio_box .category {
  width: 200px;
}

.three_type > div ul li div{opacity:0; width:100%; height:100%; border-radius:50%;display:flex; justify-content:center; align-items:center; background: linear-gradient(180deg, rgba(255, 17, 80, 0.536) 0%, rgba(255, 17, 80, 0.696) 100%), linear-gradient(180deg, rgba(0, 0, 0, 0) 54.55%, rgba(0, 0, 0, 0.8) 100%); box-shadow:0px 30px 20px rgba(0, 0, 0, 0.8);transition:all 0.3s;}
.three_type > div ul li:hover div {opacity:1;}
.three_type > div ul li div.on {opacity:1;box-shadow:none;}
.three_type > div ul p {position:absolute; bottom:-40px; left:50%;z-index:5;transform:translateX(-50%);color:#fff; font-size:16px; font-weight:500; white-space:nowrap;}
.three_type .none_opacity {opacity:0.6;}

.types_bottom {position:absolute; bottom:86px;width:100%; padding:0 5%; display:flex; justify-content:space-between; align-items:center; margin-top:30px;}
.types_bottom > svg {min-width:14px; margin-right:24px;}
.types_bottom > div {display:flex;align-items:center;overflow-x:auto; }
.types_bottom > div p {padding:10px 12px;background: rgba(255, 255, 255, 0.04); border: 1px solid rgba(255, 255, 255, 0.2); border-radius:8px; color:#fff;font-size:14px; font-weight:500; margin:0 0 8px 8px;white-space:nowrap;}
</style>