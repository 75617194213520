<template>
  <div class="time_setting" :class="{ full: isfullbox }">
    <div class="time_setting_box">
      <div class="label setting_box_header" @mouseover.once="setRepeat">{{ hour }}시간마다</div>
      <div class="setting_box_drop_down">
        <ul class="optionList">
          <li class="optionItem"><p>1시간</p></li>
          <li class="optionItem"><p>2시간</p></li>
          <li class="optionItem"><p>3시간</p></li>
          <li class="optionItem"><p>6시간</p></li>
          <li class="optionItem"><p>12시간</p></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'repeatPc',

  props: {
    repeatTime: String,
    isfullbox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      hour: null,
    };
  },

  created() {
    this.hour = this.repeatTime;
  },

  methods: {
    selectTime() {
      this.$emit('setRepeat', this.hour);
    },

    setRepeat() {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach(opt => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach(opt => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.hour = item.textContent.substring(0, 2) === '12' ? item.textContent.substring(0, 2) : item.textContent.substring(0, 1);
        this.$emit('setTime', this.hour);

        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
            window.removeEventListener('click', () => handleClose());
          });
        }
      };
      window.addEventListener('click', e => {
        handleClose(e);
      });
    },
  },
};
</script>

<style scoped>
.time_setting.full {
  border-radius: 8px;
}
/* select_box */
.time_setting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #383838;
  border-radius: 8px;
}
.time_setting .time_setting_box {
  position: relative;
  width: 100%;
  border-radius: 8px;
}
.time_setting .time_setting_box .setting_box_header {
  position: relative;
  width: 100%;
  border-radius: 8px;
  padding: 18px 16px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
}
.time_setting .time_setting_box .setting_box_header::after {
  content: '';
  width: 12px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  background: url(/media/img/drop_down.svg) no-repeat center 0;
  transition: all 0.3s;
}
.time_setting .time_setting_box .setting_box_drop_down {
  position: absolute;
  width: 100%;
  height: 190px;
  max-height: 0;
  top: 110%;
  padding: 0 4px;
  background: #3b3b3b;
  border-radius: 8px;
  overflow: auto;
  transition: all 0.3s;
  z-index: 10;
}

.time_setting .time_setting_box .setting_box_drop_down .optionList {
  width: 100%;
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: auto;
  transition: all 0.3s;
}
.time_setting .time_setting_box .setting_box_drop_down .optionList .optionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 16px;
  cursor: pointer;
}
.time_setting .time_setting_box .setting_box_drop_down .optionList .optionItem:hover {
  background-color: hsla(0, 0%, 100%, 0.06);
  background-image: url('/media/img/space_check_active.png');
  background-repeat: no-repeat;
  background-position: 95% 50%;
}
.time_setting .time_setting_box .setting_box_drop_down .optionList .optionItem p {
  color: hsla(0, 0%, 100%, 0.87);
  font-size: 16px;
  margin: 0;
}

/* active */
.time_setting .time_setting_box.active .setting_box_header {
  box-shadow: 0 0 0 1px #ff2d55;
}
.time_setting .time_setting_box.active .setting_box_header::after {
  transform: rotate(180deg) translateY(3px);
}

.time_setting .time_setting_box.active .setting_box_drop_down {
  box-shadow: 0 0 0 1px #ff2d55;
  max-height: 190px;
}
.time_setting .time_setting_box.active .setting_box_drop_down .optionList {
  max-height: 190px;
}
</style>
