<template>
  <div class="agree_modal agree1 modal policy">
    <div class="agree_modal_box modal_box">
      <div class="agree_titile modal_title">
        <h2>개인정보 처리방침</h2>
        <div class="modal_close_btn" @click="$emit('closePrivacyModal')">
          <img src="/media/img/close_btn.png" alt="">
        </div>
      </div>
      <!-- <div class="select_box">
        <div class="select-box-area">
          <div class="label select-box" @mouseover.once="clickSelectBox">
            {{ privacy }}
          </div>
          <div class="select-box-dropDown policy_li">
            <ul>
              <li class="optionItem"><p>약관버전정보</p><img src="/media/img/space_check_none.png"></li>
            </ul>
          </div>
        </div>
      </div> -->
      <div class="agree_text_box" style="color: white">
        <div v-html="privacyContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/service/axios";

export default {
  name: 'personalModal',

  data(){
    return{
      privacy: '약관버전정보',
      stplatCateData: {},
      privacyContent: null
    }
  },

  mounted () {
    this.getPrivacy();
  },

  methods: {
    /**
     * * 개인정보 처리방침 목록 불러오기
     */
    getPrivacy () {
      const stplatCate = 'PrivacyServiceCorp';

      axios
        .post('/api/corp/getStplatList', { stplatCate })
        .then(res => {
          const list = res.data.result[0].stplatContents;
          this.privacyContent = list.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
        })
        .catch(err => {
          console.log(err);
        })
    },

    /**
     * * 셀렉트 박스 클릭 이벤트
     */
    clickSelectBox () {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        label.innerHTML = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
};
</script>

<style scoped>
  .agree_modal {
    background: rgba(0, 0, 0, 0.7);
  }
  .agree_modal_box {
    background: #151515;
  }
  .agree_modal_box .select_box {
    background: transparent;
    padding-right: 27px;
    padding-bottom: 24px;
  }
  .agree_text_box {
    overflow: auto;
    max-height: 410px;
    padding-right: 27px;
  }
  .agree_modal_box .select-box-area {
    width: 49.5%;
  }
  .agree_modal_box .select-box {
    background: #383838;
    height: 48px;
    line-height: 48px;
    padding: 0 16px !important;
}
.agree_modal_box .select-box-dropDown {
    background: #383838;
}
</style>
