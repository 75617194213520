<template>
  <div>
    <section class="login_main find_pw">
      <div class="login_main_box">
        <h1>비밀번호 변경</h1>
        <input type="password" placeholder="새로운 비밀번호  (영문, 숫자, 특수문자 포함 8~20자리)" maxlength="20" minlength="6" v-model="password1" @keyup="passwordCheck" />
        <input type="password" placeholder="비밀번호 재입력" v-model="password2" @keyup="passEqualCheck" />
        <p class="fail_id" v-if="!checkPassword">비밀번호 규칙에 맞게 입력해주세요.</p>
        <p class="fail_id" v-if="!checkPassEqual">비밀번호를 동일하게 입력해주세요.</p>
        <button @click="finishLink">확인</button>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: [
    'email'
  ],

  data () {
    return {
      password1: '',
      password2: '',
      checkPassword: true,
      checkPassEqual: true
    };
  },

  methods: {
    finishLink () {
      if(this.password1 === this.password2){
        var email = this.email;
        var password = btoa(this.password1);
        if (this.password1.length > 0) {
          if (this.password2.length > 0) {
            axios
              .post('/api/corp/updatePass', { email, password })
              .then(res => {
                if (res.status === 200) {
                  this.$router.push('/brand/changePassFinished');
                }
              })
              .catch(err => {
                console.log(err);
              })
            } else {
              alert('비밀번호를 입력해 주세요!');
              document.getElementById("password2").focus();
            }
          } else {
            alert('비밀번호를 입력해 주세요!');
            document.getElementById("password1").focus();
          }
        } else {
          alert('비밀번호를 동일하게 입력해주세요!');
          this.password1 = "";
          this.password2 = "";
          document.getElementById("password1").focus();
        }
    },

    passwordCheck () { //비밀번호 유효성
      /^.*(?=.{8,20})(?=.*[!@#$%^*+=-])(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(this.password1) ? this.checkPassword = true : this.checkPassword = false;
    },

    passEqualCheck () { //비밀번호 2개 일치여부 확인
      this.password1 === this.password2 ? this.checkPassEqual = true : this.checkPassEqual = false;
    }
  }
};
</script>

<style scoped>
.find_pw .fail_id { color: #E85454; font-size:14px; text-align:left; text-decoration:none; }

@media all and (max-width:500px) {
  .find_pw .fail_id { font-size:12px; }
}
</style>
