<template>
  <div>
    <div class="inquiry_popup modal">
      <div class="popup_box modal_box">
        <div class="popup_title modal_title">
          <h4>문의하기</h4>
          <div class="modal_close_btn" @click="$emit('closeModal')">
            <img src="/media/img/close_btn.png" alt="">
          </div>
        </div>
        <div class="select-box-area">
          <div class="label select-box" @mouseover.once="clickSelectBox">
            {{ questionCategory }}
          </div>
          <div class="select-box-dropDown">
            <ul>
              <li class="optionItem"><p>일반문의</p></li>
              <li class="optionItem"><p>불편문의</p></li>
              <li class="optionItem"><p>기타사항</p></li>
              <li class="optionItem"><p>제휴문의</p></li>
            </ul>
          </div>
        </div>
        <div class="input_title">
          <label>문의제목
            <input type="text" maxlength="20" placeholder="문의 제목을 입력해주세요." v-model="questionTitle">
            <p>{{ questionTitle.length }}<span>/20</span></p>
          </label>
        </div>
        <div class="input_content">
          <label>문의내용
            <textarea type="text" maxlength="200" placeholder="문의 내용을 입력해주세요." v-model="questionContent"></textarea>
          </label>
          <p>{{ questionContent.length }}<span>/200</span></p>
        </div>
        <div class="popup_bottom modal_bottom_btn">
          <!--<p class="popup_out">취소</p>-->
          <button @click="insertQuestion()">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  name: 'questionModal',
  data () {
    return {
      questionCategory: '일반문의',
      questionTitle: '',
      questionContent: ''
    }
  },
  methods: {
    insertQuestion(){
      var questionWriter = VueCookies.get("corpId");
      var questionCategory = this.questionCategory;
      var questionTitle = this.questionTitle;
      var questionContent = this.questionContent;
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/menu/createQuestion', { questionWriter, questionCategory, questionTitle, questionContent }, { headers })
        .then(res => {
          if (res.status === 200) {
            this.$emit('getList');
            this.$emit('closeModal');
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    clickSelectBox () {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.questionCategory = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
}
</script>

<style scoped>
    .input_content textarea {
        padding-right: 80px;
    }
</style>
