<template>
  <div class="cs_content">
    <div class="two_depth">
      <!-- <router-link to="#" class="two_depth_btn" @click.native="addClass1()">전체</router-link> -->
      <router-link to="#" class="two_depth_btn on" @click.native="addClass('Howtouse')">이용방법</router-link>
      <!-- <router-link to="#" class="two_depth_btn"  @click.native="addClass3('Payment')">구독 및 결제</router-link>
      <router-link to="#" class="two_depth_btn" @click.native="addClass4('Unsubscribe')">구독 해지</router-link>
      <router-link to="#" class="two_depth_btn" @click.native="addClass5('Coupon')">쿠폰</router-link> -->
      <router-link to="#" class="two_depth_btn" @click.native="addClass('Profile')">프로필관리</router-link>
      <router-link to="#" class="two_depth_btn" @click.native="addClass('Etc')">기타</router-link>
    </div>
    <div class="cs_list_box">
      <ul class="cs_list" v-for="(item, index) in faqList" :key="index" @click.once="toggleOnOff">
        <li>
          <p>{{ item.faqTitle }}</p>
          <img src="/media/img/open_btn.png" alt="">
          <input type="hidden" />
        </li>
        <li class="sub_cs_list" @click="toggleOnOff">
          <span>A.</span>
          <p v-html="replaceEnter(item.faqContent)"></p>
        </li>
      </ul>
    </div>
    <infinite-loading v-if="status" @infinite="infiniteHandler" ref="infiniteLoading" spinner="waveDots">
      <div slot="no-more"></div>
      <div slot="no-results"></div>
      <div slot="error"></div>
    </infinite-loading>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import VueCookies from "vue-cookies";
import axios from "@/service/axios";
import $ from "jquery"

export default {
  name: 'faq',

  components: {
    InfiniteLoading
  },

  data () {
    return{
      itemList: [],
      faqList: [],

      //무한 스크롤
      limit: 0,
      status: false
    }
  },

  mounted () {
    this.getFaqList('Howtouse');
  },

  updated () {
    this.set();
  },

  methods: {
    /**
     * * FAQ 목록 불러오기
     */
    getFaqList (category) {
      const faqCategory = category;
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/menu/getFaqList', { faqCategory }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.itemList = res.data.result;
            this.status = true;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },

    /**
     * * FAQ 닫기
     */
    set () {
      $(".sub_cs_list").hide();
    },

    /**
     * * 특수문자 줄바꿈 변환
     */
    replaceEnter (index) {
      return String(index).replace(/(?:\r\n|\r|\n)/g, "</br>");
    },    

    /**
     * * FAQ 카테고리 변경
     */
    addClass (category) {
      $(document).on('click', '.two_depth a', function () {
        $(".two_depth").find('a').removeClass('on');
        $(this).addClass('on');
      });

      this.status = false;
      this.limit = 0;
      this.faqList = [];
      this.getFaqList(category);
      this.$refs.infiniteLoading.stateChanger.reset();
    },

    /**
     * * FAQ 내용 보기/닫기
     */
    toggleOnOff () {
      $(document).off("click").on("click", ".cs_list > li:first-child", function () {
        if ($(this).hasClass('status') === true) {
          $('.status').removeClass('status');
          $(this).siblings().slideToggle(300);
          $(this).children("img").toggleClass("rotate");
        } else {
          $('.status').siblings().slideUp(300);
          $('.status').removeClass('status');
          $(this).addClass('status');
          $(this).siblings().slideToggle(300);
          $(this).children("img").toggleClass("rotate");
        }
      });
    },

    /**
     * * 무한 스크롤 이벤트
     */
    infiniteHandler ($state) {
      if (this.limit >= this.itemList.length) {
        $state.complete();
      } else {
        setTimeout(() => {
          this.faqList = this.faqList.concat(this.itemList.slice(this.limit, (this.limit + 10)));
          this.limit += 10;

          if (this.limit >= this.itemList.length) {
            $state.complete();
          } else {
            $state.loaded();
          }
        }, 1000);
      }
    }
  }
};
</script>

<style scoped>
.two_depth span { cursor: pointer; }
.cs .cs_list > li { justify-content: flex-start; }
</style>
<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/content.css"></style>
