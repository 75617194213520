<template>
  <div class="space_information modal">
    <div class="space_information_box modal_box">
      <div class="space_information_title modal_title">
        <h2>스페이스 정보 </h2>
        <div class="modal_close_btn" @click="$emit('closeCarInfo')">
          <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 7.55012L16.0003 0.549805L18 2.54949L10.9997 9.5498L18 16.5501L16.0003 18.5498L9 11.5495L1.99969 18.5498L0 16.5501L7.00031 9.5498L0 2.54949L1.99969 0.549805L9 7.55012Z" fill="white"/>
          </svg>
        </div>
      </div>
      <section class="info_scroll_body">
        <div>
          <label>차종</label>
          <div style="display: flex; justify-content: space-between;">
            <input type="text" v-model="curationInfo.carBrand" disabled>
            <input type="text" v-model="curationInfo.carKind" disabled style="margin-top: 0;">
          </div>
        </div>
        <div>
          <label>차량번호</label>
          <input type="text" v-model="curationInfo.spaceNm" disabled>
        </div>
        <div>
          <p>이용 목적</p>
          <span v-for="purpose, i in curationInfo.carPurpose" :key="i">{{ purpose }}</span>
        </div>
        <div>
          <p>선호 분위기</p>
          <span>{{ curationInfo.carMood }}</span>
        </div>
        <div>
          <p>선호국가</p>
          <span>국내 {{ curationInfo.domesticRate }}%</span>
        </div>
        <div>
          <p>비선호 장르</p>
          <span v-for="genre, i in curationInfo.genre" :key="i" style="margin-bottom: 8px;">{{ genre }}</span>
        </div>
        <div>
          <p>시대</p>
          <span v-for="period, i in curationInfo.period" :key="i">{{ period }}</span>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from 'axios'

export default {
  name: 'carInfo',
  data () {
    return{
      curationInfo: {}
    }
  },
  props: [
    'spaceInfo'
  ],
  created () {
    var aToken = VueCookies.get("aToken");
    var headers = { "X-AUTH-TOKEN": aToken };
    var spaceId = this.spaceInfo.spaceId;

    axios
      .post("/api/space/selectSpaceInfo", { spaceId }, { headers })
      .then(res => {
        if (res.data.resultCd === '0000') {
          this.curationInfo = res.data.result[0];
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
}
</script>

<style scoped>
    .info_scroll_body::-webkit-scrollbar {display: block; width: 4px;}
    .info_scroll_body::-webkit-scrollbar-thumb { background: #ccc;}
    .info_scroll_body::-webkit-scrollbar-track { background: #555;}
</style>
