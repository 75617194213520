<template>
  <div class="agree_modal agree1 modal policy">
    <div class="agree_modal_box modal_box">
      <div class="agree_titile modal_title">
        <h2>서비스 이용약관</h2>
        <div class="modal_close_btn" @click="$emit('closeServiceModal')">
          <img src="/media/img/close_btn.png" alt="">
        </div>
      </div>
      <!-- <div class="select_box">
        <div class="select-box-area">
          <div class="label select-box" @mouseover.once="clickSelectBox">
            {{ serviceTitle }}
          </div>
          <div class="select-box-dropDown policy_li">
            <ul>
              <li id="service" class="optionItem" @click="changeService('TermsOfService')"><p>사이트 이용약관</p><img src="/media/img/space_check_none.png"></li>
              <li class="optionItem" id="spot" @click="changeService('LocationInfoService')"><p>위치정보사업 이용약관</p><img src="/media/img/space_check_none.png"></li>
              <li class="optionItem" id="spot" @click="changeService('LocationBasedService')"><p>위치기반서비스 이용약관</p><img src="/media/img/space_check_none.png"></li>
            </ul>
          </div>
        </div>
        <div class="select-box-area2">
          <div class="label2 select-box2" @mouseover.once="clickSelectBox2">
            {{ serviceDate }}
          </div>
          <div class="select-box-dropDown2">
            <ul>
              <li class="optionItem2"><p>2022.05.16 ~ 현재</p><img src="/media/img/space_check_none.png"></li>
            </ul>
          </div>
        </div>
      </div> -->
      <div class="agree_text_box service" style="color: white">
        <div v-html="serviceContents"></div>
        <!-- {{ serviceList.stplatContents }} -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/service/axios";

export default {
  name: 'serviceModal',
  data(){
    return{
      serviceContents: null,
      stplatCate: 'TermsOfService',
      serviceTitle: '서비스 이용약관',
      serviceDate: '2022.05.16 ~ 현재'
    }
  },
  created(){
    this.getServiceList();
  },

  methods: {
    getServiceList () {
      var stplatCate = this.stplatCate;

      axios
        .post('/api/corp/getStplatList', { stplatCate })
        .then(res => {
          var list = res.data.result[0].stplatContents;
          this.serviceContents = list.replaceAll('&lt;', '<').replaceAll('&gt;', '>').replaceAll("&amp;", "&").replaceAll("&#x2F;", "/");
        })
        .catch(err => {
          console.log(err);
        })
    },
    clickSelectBox () {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.serviceTitle = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label') && !e.target.classList.contains('optionItem')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    },
    clickSelectBox2 () {
      /*select_box_custom*/
      var label = document.querySelectorAll('.label2');
      label.forEach(function (lb) {
        lb.addEventListener('click', () => {
          var optionList = lb.nextElementSibling;
          var optionItems = optionList.querySelectorAll('.optionItem2');
          clickLabel(lb, optionItems);
        });
      });
      var clickLabel = (lb, optionItems) => {
        //****************** 추가된 부분 START
        //내가 아닌 다른 select 닫기
        label.forEach(function (itemLb) {
          if (lb !== itemLb) {
            itemLb.parentNode.classList.remove('active');
          }
        });
        //****************** 추가된 부분 END
        if (lb.parentNode.classList.contains('active')) {
          lb.parentNode.classList.remove('active');
          optionItems.forEach((opt) => {
            opt.removeEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        } else {
          lb.parentNode.classList.add('active');
          optionItems.forEach((opt) => {
            opt.addEventListener('click', () => {
              handleSelect(lb, opt);
            });
          });
        }
      };
      var handleSelect = (label, item) => {
        this.serviceDate = item.textContent;
        label.parentNode.classList.remove('active');
      };
      var handleClose = e => {
        //select 가 아닌경우 닫기
        if (!e.target.classList.contains('label2') && !e.target.classList.contains('optionItem2')) {
          label.forEach(function (lb) {
            lb.parentNode.classList.remove('active');
          });
        }
      };
      window.addEventListener('click', e => handleClose(e));
    }
  }
}
</script>

<style scoped>
    .agree_text_box{
        width: 100%;
    }
    .agree_modal_box .select_box {
      background: transparent;
      padding-right: 27px;
      padding-bottom: 24px;
    }
    .agree_modal_box .select-box-area {
      width: 49.5%;
    }
    .agree_modal_box .select-box-area2 {
      width: 49.5%;
    }
    .agree_modal_box .select-box {
      background: #383838;
      height: 48px;
      line-height: 48px;
      padding: 0 16px !important;
    }
    .agree_modal_box .select-box2 {
      background: #383838;
      height: 48px;
      line-height: 48px;
      padding: 0 16px !important;
    }
    .agree_modal_box {
      background: #151515;
    }
    .agree_modal {
      background: rgba(0, 0, 0, 0.7);
    }
</style>
