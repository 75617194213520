<template>
  <div class="delet_finish_alert alert">
    <div class="delet_finish_box alert_box">
      <h3>이미 등록된 브랜드입니다.</h3>
      <div class="delet_finish_btn">
        <p id="color_txt" @click="$emit('closeBrandCheck')">확인</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'brandCheck'
}
</script>
<style scoped>
  h3 {width:100%;font-size:18px;padding:0 0 24px;text-align:center;}
  p {padding:0; text-align:center;height: 60px; line-height: 60px; color: #FF2D55; border-top: 1px solid rgba(255, 255, 255, 0.08); cursor: pointer;}
</style>
