<template>
    <div>
        <div id="header">
            <div class="hd_inr cf">
                <div class="logo">
                    <router-link :to="{ name: 'brand/home' }">
                        <img class="pc_logo" src="/media/img/logo_white.svg" alt="로고" />
                        <img class="mobile_logo" src="/media/img/logo_m.png" alt="모바일로고" />
                    </router-link>
                </div>
                <div id="nav">
                    <div class="gnb">
                        <ul class="cf">
                            <li class="gnb_login" @click="login"><span>로그인</span></li>
                            <li class="gnb_join" @click="signUp"><span>회원가입</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <section class="notice_container">
            <h1 class="noticelist_title">공지사항</h1>
            <div class="noticelist_list">
                <notice-list />
            </div>
        </section>
        <check-mobile v-if="isCheckMo" @closeMobileCheck="isCheckMo = false" />
    </div>
</template>
<script>
import NoticeListComponent from '@/components/notice/NoticeListComponent.vue';
import CheckMobile from '@/components/modal/main/CheckMobile.vue';
export default {
    name: 'NoticeListView',
    components: {
        'notice-list': NoticeListComponent,
        'check-mobile': CheckMobile
    },
    data() {
        return {
            checkMobile: false,
            isCheckMo: false
        };
    },

    created() {
        this.checkDevice();
    },

    mounted() {

    },

    methods: {
        /**
         * * 디바이스 체크
         * * 법인 페이지는 랜딩 페이지를 제외하면 PC에서만 이용이 가능
         */
        checkDevice() {
            var pcDevice = 'win16|win32|win64|mac|macintel';
            if (navigator.platform) {
                if (pcDevice.indexOf(navigator.platform.toLowerCase()) < 0) {
                    this.checkMobile = true;
                } else {
                    this.checkMobile = false;
                }
            }
        },
        login() {
            this.checkMobile ? (this.isCheckMo = true) : this.$router.push('/brand/login');
        },
        signUp() {
            this.checkMobile ? (this.isCheckMo = true) : this.$router.push('/brand/signUp');
        }
    }
};
</script>

<style src="@/assets/css/style.css"></style>
<style scoped>
.notice_container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding-top: 132px;
}

.noticelist_title {
    color: var(--white);
    font-size: 38px;
    font-weight: 700;
    line-height: 52px;
}

.noticelist_list {
    margin-top: 52px;
}


/*############################ 반응형 ############################*/
@media all and (max-width:1200px) {
    .notice_container {
        width: 90%;
    }
}

/*######################## 반응형 처리 ~500px ########################*/
@media all and (max-width: 500px) {
    .notice_container {
        padding-top: 15.833vw;
    }

    .noticelist_list {
        margin-top: 13px;
    }

    .noticelist_title {
        font-size: 18px;
        line-height: 1.2;
    }

    .logo_img {
        display: none !important;
    }

}
</style>