<template>
  <div class="common_popup_area">
    <div class="common_popup_box">
      <div class="popup_box_top">
        <h3>휴대폰 번호가 변경되었습니다.</h3>
      </div>
      <div class="popup_box_bot">
        <p class="confirm_btn" @click="$emit('closePopup')">확인</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'changeTelComplete'
}
</script>
<style src="@/assets/css/content.css"></style>
