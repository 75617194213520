<template>
  <div>
    <ServiceModal v-if="serviceModalOpen" @closeServiceModal="serviceModalOpen = false" />
    <PrivacyModal v-if="PrivacyModalOpen" @closePrivacyModal="PrivacyModalOpen = false" />
    <MarketingModal v-if="marketingModalOpen" @closeMarketingModal="marketingModalOpen = false" />
    <section class="login_main">
      <div class="login_main_box">
        <h1>회원가입</h1>
        <div class="search-box">
          <input type="text" placeholder="회사명을 입력해주세요" v-model="corpNm" />
        </div>
        <!-- <input type="text" placeholder="이름을 입력해주세요" v-model="name" /> -->
        <div class="email_input">
          <div class="email_input_box" style="width: 480px">
            <input type="text" placeholder="회사 이메일을 입력해주세요." v-model="email" ref="email" />
          </div>
          <button class="email_input_btn" @click="checkEmail">중복확인</button>
        </div>
        <p id="fail_id" v-show="emailCheckAlert">이미 존재하는 아이디입니다.</p>
        <div class="certificate_number">
          <input
            id="password"
            type="password"
            style="margin-bottom: 6px"
            placeholder="비밀번호 (영문, 숫자, 특수문자 포함 8~20자리)"
            maxlength="20"
            minlength="6"
            v-model="password1"
            @keyup="passwordCheck()" />
          <div class="in_pw" style="margin-bottom: 4px" v-if="!checkPassword">
            <p>비밀번호를 규칙에 맞게 입력해주세요.</p>
          </div>
          <input type="password" style="margin-top: 2px; margin-bottom: 0px" placeholder="비밀번호 재입력" maxlength="20" minlength="6" v-model="password2" @keyup="passEqualCheck()" />
          <div class="in_pw" style="margin-top: 6px" v-if="checkPassEqual === false">
            <p>비밀번호를 동일하게 입력해주세요.</p>
          </div>
        </div>
        <button id="authbtn" class="join_certification" v-if="!isCheckNice" @click="clickEvent()">본인 인증</button>
        <button id="authbtn" class="join_certification check" v-else>
          인증 완료
          <span class="done_img_wrap"><img src="/media/img/done.png" class="done_img" /></span>
        </button>

        <div class="agree_box">
          <checkboxAgreeAll v-model="agreeAll" @input="agreeAll1()" />
          <p>약관 전체 동의</p>
        </div>
        <div class="agree_txt">
          <ul>
            <li>
              <div>
                <checkboxService v-model="service" @input="agreeService()" />
                <p>[필수]서비스 이용약관</p>
              </div>
            </li>
            <li @click="serviceBtn">
              <img src="/media/img/join_agree_icon.png" alt="" />
            </li>
          </ul>
          <ul>
            <li>
              <div>
                <checkboxPrivacy v-model="privacy" @input="agreePrivacy()" />
                <p>[필수]개인정보 처리방침</p>
              </div>
            </li>
            <li @click="privacyBtn">
              <img src="/media/img/join_agree_icon.png" alt="" />
            </li>
          </ul>
          <ul>
            <li>
              <div>
                <checkboxMaketing v-model="marketing" @input="agreeMarketing()" />
                <p>[선택]마케팅 정보 수신</p>
              </div>
            </li>
          </ul>
        </div>
        <button @click="signUp">가입하기</button>
      </div>
    </section>
    <input type="hidden" id="birthDate" value="" />
    <input type="hidden" id="encryptString" value="" />
  </div>
</template>

<script>
import ServiceModal from '@/components/modal/signUp/ServiceModal.vue';
import PrivacyModal from '@/components/modal/signUp/PrivacyModal.vue';
import MarketingModal from '@/components/modal/signUp/MarketingModal';
import checkboxAgreeAll from '../../components/checkbox/checkboxAgreeAll.vue';
import checkboxService from '../../components/checkbox/checkboxService.vue';
import checkboxPrivacy from '../../components/checkbox/checkboxPrivacy.vue';
import checkboxMaketing from '../../components/checkbox/checkboxMaketing.vue';
import axios from 'axios';
import router from '@/router';
export default {
  data() {
    return {
      isCheckNice: false,
      encryptString: '',
      //모달창
      serviceModalOpen: false,
      PrivacyModalOpen: false,
      marketingModalOpen: false,
      //체크박스
      service: false,
      privacy: false,
      marketing: false,
      agreeAll: false,
      //회원가입
      email: '',
      emailOption: '직접입력',
      corpNm: '',
      //name: '',
      corpTel: '',
      password1: '',
      password2: '',
      tokenVersionId: '',
      encData: '',
      integrityValue: '',
      emailCheck: false,
      checkPassword: true,
      checkPassEqual: null,
      //alert
      authCheck: false,
      emailCheckAlert: false
    };
  },
  components: {
    ServiceModal,
    PrivacyModal,
    MarketingModal,
    checkboxAgreeAll,
    checkboxPrivacy,
    checkboxService,
    checkboxMaketing
  },
  mounted() {
    this.setInputEvent();
  },

  methods: {
    setInputEvent() {
      const input = document.querySelector('#birthDate');
      const encryptString = document.querySelector('#encryptString');
      input.addEventListener('change', $event => {
        if (encryptString.value && $event.target.value) {
          this.isCheckNice = true;
          this.encryptString = encryptString.value;
          this.authCheck = true;
          input.value = '';
          encryptString.value = '';
        }
      });
    },
    makeFormData(tokenVersionId, encData, integrityValue) {
      const form = document.createElement('form');
      form.target = 'popup';
      form.method = 'Post';
      form.setAttribute('action', process.env.VUE_APP_NICE_URL);

      const inputService = document.createElement('input');
      inputService.setAttribute('type', 'hidden');
      inputService.setAttribute('value', 'service');
      inputService.setAttribute('id', 'm');
      inputService.setAttribute('name', 'm');
      form.appendChild(inputService);

      const inputToken = document.createElement('input');
      inputToken.setAttribute('type', 'hidden');
      inputToken.setAttribute('value', tokenVersionId);
      inputToken.setAttribute('id', 'token_version_id');
      inputToken.setAttribute('name', 'token_version_id');
      form.appendChild(inputToken);

      const inputEncData = document.createElement('input');
      inputEncData.setAttribute('type', 'hidden');
      inputEncData.setAttribute('value', encData);
      inputEncData.setAttribute('id', 'enc_data');
      inputEncData.setAttribute('name', 'enc_data');
      form.appendChild(inputEncData);

      const inputIv = document.createElement('input');
      inputIv.setAttribute('type', 'hidden');
      inputIv.setAttribute('value', integrityValue);
      inputIv.setAttribute('id', 'integrity_value');
      inputIv.setAttribute('name', 'integrity_value');
      form.appendChild(inputIv);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
    /**
     * * 이메일 중복 확인
     */
    checkEmail() {
      const email = this.email;
      const regEmail = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

      if (regEmail.test(email) === true) {
        axios
          .post('/api/corp/checkEmailDup', { email })
          .then(res => {
            this.emailCheck = res.data.result;
            if (this.emailCheck) {
              alert('사용 가능한 이메일 주소입니다.');
            } else {
              alert('이미 등록된 이메일 주소입니다.');
              this.email = '';
              document.getElementById('email').focus();
            }
          })
          .catch(e => {
            console.log(e);
            if (e.response.data.resultCd === '500' && e.response.data.resultMsg === '탈퇴 후 14일이 지나지 않은 이메일입니다.') {
              alert('탈퇴 후 14일이 지나지 않아 재가입이 불가합니다.  \n다른 메일로 가입 부탁드립니다.');
              this.email = '';
              this.$refs.email.focus();
            }
          });
      } else {
        alert('이메일을 바르게 입력해 주세요!');
        this.email = '';
        document.getElementById('email').focus();
      }
    },

    /**
     * * 본인인증
     */
    async clickEvent() {
      window.open('', 'popup', 'width=545, height=875, toolbar=yes, location=no');
      axios
        .post(`/api/nice/v2/userCheck`)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const { tokenVersionId, encData, integrityValue } = result;
            this.makeFormData(tokenVersionId, encData, integrityValue);
          }
        })
        .catch(error => {
          console.error('getUserCheck error : ', error);
        });
    },

    /**
     * * 약관 전체 동의 클릭 이벤트
     */
    agreeAll1() {
      //약관 전체
      if (this.agreeAll) {
        this.service = true;
        this.privacy = true;
        this.marketing = true;
      } else {
        this.service = false;
        this.privacy = false;
        this.marketing = false;
      }
    },

    /**
     * * 서비스 이용약관 동의/비동의
     */
    agreeService() {
      this.service === true && this.privacy === true && this.marketing === true ? (this.agreeAll = true) : (this.agreeAll = false);
    },

    /**
     * * 개인정보 처리방침 동의/비동의
     */
    agreePrivacy() {
      this.service === true && this.privacy === true && this.marketing === true ? (this.agreeAll = true) : (this.agreeAll = false);
    },

    /**
     * * 마케팅 정보 수신 동의/비동의
     */
    agreeMarketing() {
      this.service === true && this.privacy === true && this.marketing === true ? (this.agreeAll = true) : (this.agreeAll = false);
    },

    /**
     * * 서비스 이용약관 팝업창 열기
     */
    serviceBtn() {
      //서비스 모달창
      this.serviceModalOpen = true;
    },

    /**
     * * 개인정보 처리방침 팝업창 열기
     */
    privacyBtn() {
      //개인정보 모달창
      this.PrivacyModalOpen = true;
    },

    /**
     * * 회원 가입
     * * 회원 정보 등록 > 인증번호 발송 > 가입 완료 페이지로 이동
     */
    signUp() {
      const email = this.email;
      const password = btoa(this.password1);
      const corpNm = this.corpNm;
      const birthDate = document.getElementById('birthDate').value;

      let marketing = '';
      this.marketing ? (marketing = 'Y') : (marketing = 'N');
      const userInfo = {
        email,
        password,
        corpNm,
        birthDate,
        marketing
      };
      if (corpNm.length > 0) {
        //회사명 입력 체크
        if (this.email.length > 0) {
          //이메일 정상 기입
          if (this.emailCheck) {
            //중복체크값 true
            if (password.length > 0 && this.checkPassword === true) {
              //비밀번호 유효성 체크
              if (this.password1 === this.password2) {
                //입력한 비밀번호 2개가 일치
                if (this.authCheck) {
                  //본인인증 완료
                  if (this.service) {
                    //서비스 이용약관에 동의
                    if (this.privacy) {
                      //개인정보 처리방침에 동의
                      axios
                        .post('/api/corp/v2/singIn', {
                          encryptString: this.encryptString,
                          email: userInfo.email,
                          password: userInfo.password,
                          marketing: userInfo.marketing,
                          corpNm: userInfo.corpNm
                        })
                        .then(res => {
                          if (res.status === 200) {
                            const toMail = userInfo.email;
                            axios
                              .post('/api/corp/getAuthEmail', { toMail })
                              .then(res => {
                                if (res.status === 200) {
                                  router.push({ name: 'brand/sineUpFinished', params: { toMail: email } });
                                }
                              })
                              .catch(err => {
                                console.log(err);
                              });
                          }
                        })
                        .catch(err => {
                          console.log(err);
                        });
                    } else {
                      //개인정보 처리방침에 비동의
                      alert('개인정보 처리방침에 동의해주세요!');
                    }
                  } else {
                    //서비스 이용약관에 비동의
                    alert('서비스 이용약관에 동의해주세요!');
                  }
                } else {
                  alert('본인인증을 진행해 주세요.');
                }
              } else {
                //입력한 비밀번호 2개가 불일치
                alert('비밀번호를 동일하게 입력해주세요!');
                this.password1 = '';
                this.password2 = '';
                document.getElementById('password').focus();
              }
            } else {
              document.getElementById('password').focus();
              this.passwordCheck();
            }
          } else {
            alert('이메일 중복확인을 진행해 주세요!');
          }
        } else {
          alert('이메일을 바르게 입력해 주세요!');
          this.emailCheck = false;
          this.email = '';
          document.getElementById('email').focus();
        }
      } else {
        alert('회사명을 입력해주세요');
      }
    },

    /**
     * * 비밀번호 유효성 검사
     */
    passwordCheck() {
      /^.*(?=.{8,20})(?=.*[!@#$%^*+=-])(?=.*[0-9])(?=.*[a-zA-Z]).*$/.test(this.password1) ? (this.checkPassword = true) : (this.checkPassword = false);
      if (this.password1 === '') {
        this.checkPassword = true;
      }
    },

    /**
     * * 비밀번호 일치 여부 확인
     */
    passEqualCheck() {
      this.password1 === this.password2 ? (this.checkPassEqual = true) : (this.checkPassEqual = false);
      if (this.password2 === '') {
        this.checkPassEqual = true;
      }
    }
  }
};
</script>

<style scoped>
.join_certification {
  border: 1px solid rgba(225, 225, 225, 0.6);
  box-sizing: border-box;
  width: 100%;
  padding: 18px 0;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  background: #151515;
  border-radius: 8px;
  text-align: center;
  margin: 32px 0;
}

.join_certification.check {
  display: flex;
  justify-content: center;
}

.login_main_box > input:nth-child(1) {
  margin-bottom: 1em;
}
.login_main_box #fail_id {
  color: #e85454;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
}
.email_input {
  margin-bottom: 0;
}
.in_pw {
  position: relative;
  width: 100%;
}
.in_pw input {
  width: 100%;
  margin-bottom: 8px;
}
.in_pw p {
  color: #ff4949 !important;
  font-size: 16px;
  margin: 0;
}
.align_center {
  display: flex;
  align-items: center;
}
.h_100per {
  height: 100%;
}

.done_img_wrap {
  height: 23px;
  display: flex;
  align-items: center;
}
.done_img {
  width: 13px;
  height: 10px;
  margin-left: 7px;
}
</style>
<style src="@/assets/css/content.css"></style>
<style src="@/assets/css/font.css"></style>
