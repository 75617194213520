<template>
  <div>
    <div v-if="pageSetting.list.length" class="payment_paging">
      <div v-if="pageSetting.first !== null" @click="pageSetting.first !== null ? sendPage(pageSetting.first) : ''">
        <img src="/media/img/pagenation_prev.png" alt="">
      </div>
      <span
        :class="{ active: page === pageSetting.currentPage }"
        v-for="page in pageSetting.list"
        :key="page"
        @click="sendPage(page)"
      >
        {{ page }}
      </span>
      <div v-if="pageSetting.end !== null" @click="pageSetting.end !== null ? sendPage(pageSetting.end) : ''">
        <img src="/media/img/pagenation_next.png" alt="">
      </div>
    </div>
  </div>
</template>
 
<script>
export default {
  name: 'pagination',
  props: ["pageSetting"],
  methods: {
    sendPage (page) {
      this.$emit("paging", page);
    }
  }
}
</script>

<style scoped>
  .active {background:#fff!important; color:#282828;}
</style>