<template>
    <div class="agree_modal agree1 modal">
        <div class="agree_modal_box modal_box">
            <div class="agree_titile modal_title">
                <h2>마케팅 정보 수신</h2>
                <div class="modal_close_btn" @click="$emit('closeMarketingModal')">
                    <img src="/media/img/close_btn.png" alt="">
                </div>
            </div>
            <div class="agree_text_box">
                <p>제 1장 총칙</p>
                <p>
                    <span>{{ stplatCateData.stplatTitle }}  </span><br>
                    {{ stplatCateData.stplatContents }}
                    <br>
                    <span>{{ stplatCateData.stplatTitle }}  </span> <br>
                    {{ stplatCateData.stplatContents }}
                    <br>
                    <span>{{ stplatCateData.stplatTitle }}  </span> <br>
                    {{ stplatCateData.stplatContents }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/service/axios";

export default {
    name: 'ReceiveMarketing',
    data(){
        return{
            stplatCateData: {}
        }
    },
    created(){
        var stplatCate = 'TermsOfService';

        axios
            .post('/api/corp/getStplat', { stplatCate })
            .then(res => {
                this.stplatCateData = res.data.result;
            })
            .catch(err => {
                console.log(err);
            })
    },
}
</script>

<style>

</style>