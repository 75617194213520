<template>
  <div class="profile_getout_modal modal">
    <div class="profile_getout_box modal_box">
      <WithdrawalCheck v-if="showWithdrawal" @closeWithdrawalModal="showWithdrawal=false" />

      <div class="main_box_title modal_title">
        <h1>회원탈퇴</h1>
        <div @click="$emit('closeWithdrawal')">
          <a href="#">
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z" fill="white"/>
            </svg>
          </a>
        </div>
      </div>
      <div class="cs_main">
        <div class="cs_content profile_content">
          <div class="policy_title">
            <h3>
              지금까지 어플레이즈를 이용해주셔서 감사합니다.<br>
              회원 탈퇴를 위해 아래 사항을 확인해주시기 바랍니다.
            </h3>
            <div class="carful_notice">
              <ul>
                <li><img src="/media/img/get_out_check.svg"></li>
                <li>회원님의 모든 정보가 삭제됩니다.</li>
              </ul>
              <ul>
                <li><img src="/media/img/get_out_check.svg"></li>
                <li>탈퇴 후 14일간 재가입이 불가능합니다.</li>
              </ul>
              <ul>
                <li><img src="/media/img/get_out_check.svg"></li>
                <li>구독중인 서비스는 모두 해지되며, 남은 기간은 유효합니다.</li>
              </ul>
            </div>
            <div class="carful_notice_bottom">
              <div class="check_box">
                <checkboxWithdrwal v-model="agreeWithdrawal"/>
                <p>위 사항을 모두 확인했고 회원 탈퇴에 동의합니다.</p>
              </div>
              <div class="btn_area">
                <button id="get_out_btn" @click="clickWithdrawal()">확인</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form_bottom">
        <button>확인</button>
      </div>
    </div>
  </div>
</template>

<script>
import checkboxWithdrwal from "@/components/checkbox/checkboxWithdrwal.vue";
import WithdrawalCheck from "./WithdrawalCheck.vue";

export default {
  name: 'withdrawal',
  data () {
    return {
      showWithdrawal: false,
      agreeWithdrawal: false
    };
  },
  components: {
    WithdrawalCheck,
    checkboxWithdrwal
  },
  methods: {
    clickWithdrawal () {
      if (this.agreeWithdrawal) {
        this.showWithdrawal = true;
      } else {
        alert("회원탈퇴에 동의해주세요.");
      }
    }
  }
};
</script>

<style scoped>
/*profile_(get_out)*/
.profile_getout_modal { background: none; z-index: 102; }
.profile_getout_box {width:800px; padding:16px 32px;height:480px;}
.profile_getout_box .main_box_title {margin-bottom:32px;}
.profile_getout_box .main_box_title div a{display:flex; align-items:center; justify-content:center;width:24px; height:24px; border-radius: 50%; cursor: pointer;}
.profile_getout_box .main_box_title h1 {color:#fff; font-size:24px;}
.profile_getout_box .policy_title {display:block;}
.profile_getout_box .policy_title h3 {color: rgba(255, 255, 255, 0.87); font-size:18px; margin-bottom:24px; font-weight: 500;}
.profile_getout_box .carful_notice ul {display:flex; justify-content:flex-start; align-items:center; border-radius:8px; background:rgba(255, 255, 255, 0.04); margin-bottom:6px;padding:16px;}
.profile_getout_box .carful_notice ul li {color: rgba(255, 255, 255, 0.87); font-size:16px;}
.profile_getout_box .carful_notice ul li:nth-child(2) {margin-left:16px;}
.profile_getout_box .carful_notice_bottom {display:flex; justify-content:space-between; align-items:center; margin-top:56px;}
.profile_getout_box .carful_notice_bottom .btn_area {width:auto !important;}
.profile_getout_box .check_box {width:100%; display:flex; justify-content:flex-start; align-items:center; cursor:pointer;}
.profile_getout_box .check_box p {color:#fff;font-weight: 400; font-size: 16px; margin-left:8px; margin-top:0!important;}
.profile_getout_box .form_bottom {display:none;}

/*profile_(get_out)_popup*/
.get_out_box {box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.8);}
.get_out_box h3 {padding-bottom:24px;color: rgba(255, 255, 255, 0.87);font-size:16px; font-weight:300;}
.get_out_btn {width:100%; height:60px; border-top:1px solid rgba(255, 255, 255, 0.08); display:flex; justify-content:center; align-items:center;}
.get_out_btn p {width:50%;color:#fff; font-size:18px; line-height:60px;font-weight:300;text-align:center;cursor:pointer;}
.get_out_btn p#color_txt {color:#FF2D55;font-weight:400;}
</style>
