<template>
  <div class="setting_box_area">
    <div class="setting_box">
      <div class="setting_box_top">
        <h1>설정</h1>
        <div class="close_btn" @click="closeSetting">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.81055 7.39387L16.8109 0.393555L18.8105 2.39324L11.8102 9.39355L18.8105 16.3939L16.8109 18.3936L9.81055 11.3932L2.81023 18.3936L0.810547 16.3939L7.81086 9.39355L0.810547 2.39324L2.81023 0.393555L9.81055 7.39387Z" fill="white"/>
          </svg>
        </div>
      </div>
      <div class="setting_box_mid">
        <div class="box_tab">
          <div @click="changeTab('pro')" :class="tabStatus === 'pro' ? 'active' : ''">
            <p>프로필</p>
          </div>
          <div @click="changeTab('noti')" :class="tabStatus === 'noti' ? 'active' : ''">
            <p>알림설정</p>
          </div>
          <div @click="changeTab('pass')" :class="tabStatus === 'pass' ? 'active' : ''">
            <p>비밀번호 변경</p>
          </div>
        </div>
        <div class="box_content">
          <Profile v-if="tabStatus === 'pro'" :corpInfo="corpInfo" @openChangeTelComplete="openChangeTelComplete" @openWithdrawal="withdrawalStatus=true" />
          <Notice v-if="tabStatus === 'noti'" />
          <ChangePassword v-if="tabStatus === 'pass'" @openChangeComplete="passPopupStatus=true" />
        </div>
      </div>
    </div>

    <!-- 팝업 영역 -->
    <ChangeTelComplete v-if="proPopupStatus" @closePopup="closePopup" />
    <withdrawal v-if="withdrawalStatus" @closeWithdrawal="withdrawalStatus=false" />
    <ChangePassComplete v-if="passPopupStatus" @closeSetting="closeSetting" />
  </div>
</template>

<script>
import Profile from './Profile.vue'
import Notice from './Notice.vue'
import ChangePassword from './ChangePassword.vue'
import VueCookies from "vue-cookies";
import axios from 'axios'

//프로필
import ChangeTelComplete from './append/ChangeTelComplete.vue'
import withdrawal from './append/Withdrawal.vue';

//비밀번호
import ChangePassComplete from './append/ChangePassComplete.vue'

export default {
  name: 'setting',

  components: {
    Profile,
    Notice,
    ChangePassword,
    ChangeTelComplete,
    ChangePassComplete,
    withdrawal
  },

  data () {
    return {
      //법인 정보
      corpInfo: {},

      //세팅 팝업 탭
      tabStatus: 'pro',

      //프로필 팝업
      proPopupStatus: false,
      withdrawalStatus: false,

      //비밀번호 팝업
      passPopupStatus: false
    }
  },

  mounted () {
    this.getCorpInfo();
  },

  methods: {
    getCorpInfo () {
      const corpId = VueCookies.get('corpId');
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/corp/getCorp', { corpId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.corpInfo = res.data.result;
          }
        })
        .catch(e => { console.log(e); });
    },

    changeTab (tab) {
      this.tabStatus = tab;
    },

    //전화번호 변경 완료 이벤트
    openChangeTelComplete () {
      //새로고침 추가 필요
      this.proPopupStatus=true;
    },

    closePopup () {
      this.proPopupStatus = false;
      this.getCorpInfo();
    },

    closeSetting () {
      this.passPopupStatus = false;

      this.$emit('closeSetting');
    }
  }
}
</script>

<style scoped>
.setting_box_area { position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.7); z-index: 101; }
.setting_box_area .setting_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 480px;
  background: linear-gradient(0deg,hsla(0,0%,100%,.08),hsla(0,0%,100%,.08)),#151515;
  border-radius: 20px;
}
.setting_box_area .setting_box .setting_box_top { display: flex; align-items: center; justify-content: space-between; width: 100%; padding: 16px 32px; border-bottom: 1px solid hsla(0,0%,88.2%,.08); }
.setting_box_area .setting_box .setting_box_top h1 { color: #fff; font-size: 24px; }
.setting_box_area .setting_box .setting_box_top .close_btn { display: flex; align-items: center; justify-content: center; width: 24px; height: 24px; border-radius: 50%; cursor: pointer; transition: all 0.3s }
.setting_box_area .setting_box .setting_box_top .close_btn:hover { background: #3b3b3b; }

.setting_box_area .setting_box .setting_box_mid { display: flex; align-items: center; width: 100%; height: calc(100% - 56px); }
.setting_box_area .setting_box .setting_box_mid .box_tab { width: 194px; height: 100%; padding: 24px 32px; border-right: 1px solid hsla(0,0%,100%,.06); }
.setting_box_area .setting_box .setting_box_mid .box_tab div { margin-bottom: 20px; cursor: pointer; }
.setting_box_area .setting_box .setting_box_mid .box_tab div p { color: hsla(0,0%,100%,.6); font-size: 16px; font-weight: 500; }
.setting_box_area .setting_box .setting_box_mid .box_tab div.active p { color: #fff }

.setting_box_area .setting_box .setting_box_mid .box_content { width: calc(100% - 194px); height: 100%; padding: 24px 32px; }
</style>
