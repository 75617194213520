import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        brandNm: null,
        playTime: "00:00",
        totDuration: "00:00",
        playNav: 0,
        noticeCloseList: []
    },
    getters: {
        gettersNoticeList(state) {
            return state.noticeCloseList;
        }
    },
    mutations: {
        setBrandNm(state, brandNm) {
            state.brandNm = brandNm;
        },

        setPlayTime(state, playTime) {
            state.playTime = playTime;
        },

        setTotDuration(state, totDuration) {
            state.totDuration = totDuration;
        },

        setPlayNav(state, playNav) {
            state.playNav = playNav;
        },
        addNoticeList(state, item) {
            const findIndex = state.noticeCloseList.findIndex(findItem => findItem.noticeId === item.commonNoticeId);

            if (findIndex < 0) {
                const addItem = {
                    noticeId: item.commonNoticeId,
                    isVisible: 'N'
                };
                state.noticeCloseList = [...state.noticeCloseList, addItem];
            } else {
                state.noticeCloseList[findIndex].isVisible = 'N';
            }
        }
    },
    actions: {},
    modules: {},
});
