<template>
  <div>
    <div class="group_store_alert alert">
      <div class="group_store_box alert_box">
        <h3><span class="space_name_box">{{ spaceInfo.spaceNm }}</span> 과(와) 연결을 해제하시겠습니까?</h3>
        <div class="group_store_btn">
          <p @click="$emit('closeDisconnect')">아니오</p>
          <p id="color_txt" @click="disconnectBtn">네</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
import axios from "@/service/axios";

export default {
  name: 'disconnect',
  props: [
    'spaceInfo'
  ],
  methods: {
    /**
     * * 스페이스 연결 해제
     */
    disconnectBtn(){
      const spaceId = this.spaceInfo.spaceId;
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/space/cancleSpaceConnect', { spaceId }, { headers })
        .then(res => {
          if(res.data.result === 1){
            this.$emit('closeDisconnect');
            this.$emit('getList');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
h3 {
  display: flex;
  line-height: 30px;
  justify-content: center;
}
h3 .space_name_box {
  max-width: 150px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  font-size: 16px;
  font-weight: 300;
}
</style>